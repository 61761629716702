/**
 * @param {string} secretKey
 */
export function getPercentage(secretKey) {
  if (secretKey.length !== 27) {
    return null;
  }
  const b64 = secretKey.replaceAll('-', '+').replace('_', '/');
  const bytes = atob(b64);
  const intVal = bytes.charCodeAt(3) + bytes.charCodeAt(2) * 256 + bytes.charCodeAt(1) * 256 * 256 + bytes.charCodeAt(0) * Math.pow(256, 3);
  console.log('getPercentage ', intVal);
  const percentage = intVal / 100;
  return `${percentage}%`;
}
import {useForm} from "react-hook-form";
import {useState} from "react";
import SingleFormField from "./SingleFormField";
import FormLabel from "./FormLabel";
import {sendRequest} from "../api/apiHelper";

function SendBuyerOnboardingInvitation() {
  const {
    register,
    formState: {errors},
    handleSubmit,
    reset,
  } = useForm({defaultValues: {clientType: "new"}});
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState();

  const sendForm = async (data) => {
    try {
      setSubmitted(true);
      await sendRequest('/internalTools/sendBuyerOnboardingInvitation', data);
      setResult('Sent at ' + new Date().toLocaleString('en-US', {timeZone: 'America/New_York'}));
      setSubmitted(false);
    } catch (e) {
      console.log('failed', e);
    }
  };

  return <>
    <form onSubmit={handleSubmit(sendForm)}>
      <SingleFormField fieldId={"orgName"} label={"Organization Name"} errors={errors} register={register} />
      <SingleFormField fieldId={"recipient"} label={"Email Address"} errors={errors} register={register} />
      <FormLabel label={"Client Type"} errors={errors} fieldId={"clientType"} />
      <select id={"clientType"} {...register("clientType")}>
        <option value={"new"}>New Client</option>
        <option value={"existing"}>Existing Client</option>
      </select>
      <div className={"button-container"}>
        <button className={"black-button"} type={"submit"} disabled={submitted}>Send</button>
        <button className={"grey-button"} type={"button"} disabled={submitted} onClick={() => {reset(); setResult();}}>Reset</button>
      </div>
    </form>
    {result && <div style={{marginTop: "1rem"}}>
        <span>{result}</span>
    </div>}
  </>;
}

export default SendBuyerOnboardingInvitation;
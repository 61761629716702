import React, {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import Form from "../components/Form";
import {handleError, sendRequest} from "../api/apiHelper";
import Success from "./Success";

export const AsyncPageType = {
  ACH_DEBIT_AUTHORIZATION: 'ach-debit-authorization',
  BANKING_UPDATE: 'banking-update',
  BUSINESS_ACH_ENROLLMENT: 'business-ach-enrollment',
  BUYER_ONBOARDING: 'buyer-onboarding',
  BUYER_ONBOARDING_EXISTING_CLIENT: 'buyer-onboarding-existing-client',
  BUYER_ONBOARDING_V2: 'buyer-onboarding-v2',
  EXPRESS: 'express',
  INDIVIDUAL_ACH_ENROLLMENT: 'individual-ach-enrollment',
  INDIVIDUAL_BANKING_UPDATE: 'individual-banking-update',
  INDIVIDUAL_PROFILE_AND_BANKING_UPDATE: 'individual-profile-and-banking-update',
  INDIVIDUAL_PROFILE_UPDATE: 'individual-profile-update',
  PROFILE_AND_BANKING_UPDATE: 'profile-and-banking-update',
  PROFILE_UPDATE: 'profile-update',
  UNIVERSITY_ACH_ENROLLMENT: 'university-ach-enrollment',
};

export function getAsyncPageProps(pageType) {
  if (pageType === AsyncPageType.UNIVERSITY_ACH_ENROLLMENT) {
    return {
      skipWarning: true,
      companyTooltipText: "This the name of your Higher Education institution",
      emailTooltipText: "This must be your Higher-Ed email ending in .edu",
      warningOverwrite: <>
        <p>In order to proceed, you'll need to have access to the following:</p>
        <ul style={{fontSize: '1rem'}}>
          <li>Your university email address</li>
          <li>Bank account and routing number</li>
        </ul>
        <p>If our Account Verification system cannot verify that you own your bank account, you'll need to prove
          ownership with one of the following:</p>
        <ul style={{fontSize: '1rem'}}>
          <li>Voided check</li>
          <li>Bank Letter signifying account ownership</li>
        </ul>
      </>,
      neededDocs: <>
      </>,
      pageTitle: "Higher Education ACH Enrollment",
      cancelRedirectPage: "university-ach-enrollment",
      includeCompanyName: true,
      companyLabel: "Institution:",
      emailLabel: "Institution Email:",
      includeAddress: false,
      includeNewAddressForm: true,
      isDocusignForm: false,
      accountTypePicker: false,
      removeSuccessCheckmark: true,
      successPageHeading: "",
      individualAccountTypePicker: true,
      showConfirmation: true,
      submitURL: '/ach/asyncFinish',
      isAvs: true,
      includeReferenceId: true,
      referenceTooltipText: 'Your reference ID from your institution, if provided',
    };
  }

  if (pageType === AsyncPageType.BUSINESS_ACH_ENROLLMENT) {
    return {
      skipWarning: true,
      warningOverwrite: <div className={'compact-card'}>
        <p><strong>Required Information:</strong></p>
        <ul>
          <li>Company Email Address: This is your email address with your company that is registered with Finexio for
            payments.
          </li>
          <li>Company Tax Identification Number (TIN): This is used to identify your company in our system.</li>
          <li>Company Bank Account and Routing Number: Have these numbers readily available to set up your payment
            options.
          </li>
        </ul>
        <p><strong>Additional Verification (if needed):</strong></p>
        <p>If our system is unable to automatically recognize your company email address and/or company TIN within our
          Finexio network, you will be required to provide one or both of the following:</p>
        <ul>
          <li>Company W-9: W-9 form for business identification</li>
          <li>Invoice proving business relationship between Finexio Customer and Supplier</li>
        </ul>
        <p>If our system is unable to automatically verify your business bank account details, you will be required to
          confirm ownership by providing one of the following:</p>
        <ul>
          <li>Voided Check: A check from your company's bank account with the word 'VOID' written across it in large
            letters.
          </li>
          <li>Bank Letter of Ownership: A formal letter from the bank that confirms that the company is the owner of the
            account in question.
          </li>
          <li>Bank Statement: A bank statement that shows the bank name, account number, and name of account owner.</li>
        </ul>
      </div>,
      pageTitle: "ACH Enrollment for Businesses",
      cancelRedirectPage: "business-ach-enrollment",
      includeCompanyName: true,
      includeTIN: true,
      isDocusignForm: false,
      includeReferenceId: true,
      accountTypePicker: false,
      individualAccountTypePicker: false,
      showConfirmation: false,
      submitURL: '/ach/asyncFinish',
      isAvs: true,
    }
  }
  if (pageType === AsyncPageType.INDIVIDUAL_ACH_ENROLLMENT) {
    return {
      skipWarning: true,
      warningOverwrite: <div className={'compact-card'}>
        <p><strong>Required Information:</strong></p>
        <ul>
          <li>Email Address: The email address you have registered with Finexio for verification and communications
            related to your payments.
          </li>
          <li>Bank Account and Routing Number: Have these numbers readily available to set up your payment options.</li>
        </ul>
        <p><strong>Additional Verification (if needed):</strong></p>
        <p>If our system is unable to automatically recognize your email address within our Finexio network, you will be
          required to provide the following:</p>
        <ul>
          <li>Documentation proving relationship between yourself and the Finexio Customer, such as an invoice, paystub,
            W2, company ID, etc.
          </li>
        </ul>
        <p>If our system is unable to automatically verify your bank account details, you will be required to confirm
          ownership by providing one of the following:</p>
        <ul>
          <li>Voided Check: A check from your bank account with the word 'VOID' written across it in large letters.</li>
          <li>Bank Letter of Ownership: A formal letter from your bank that confirms you are the owner of the account in
            question.
          </li>
          <li>Bank Statement: A bank statement that shows the bank name, account number, and name of account owner.</li>
        </ul>
      </div>,
      pageTitle: "ACH Enrollment for Individuals",
      cancelRedirectPage: "individual-ach-enrollment",
      includeAddress: false,
      includePayer: true,
      includeReferenceId: true,
      isDocusignForm: false,
      accountTypePicker: false,
      individualAccountTypePicker: true,
      submitURL: '/ach/asyncFinish',
      isAvs: true,
    };
  }
  if (pageType === AsyncPageType.BANKING_UPDATE) {
    return {
      warningOverwrite: <div className={'compact-card'}>
        <p><strong>Required Information:</strong></p>
        <ul>
          <li>Company Email Address: This is your email address with your company that is registered with Finexio for
            payments.
          </li>
          <li>Company Tax Identification Number (TIN): This is used to identify your company in our system.</li>
          <li>Company Bank Account and Routing Number: Have these numbers readily available to set up your payment
            options.
          </li>
        </ul>
        <p><strong>Additional Verification (if needed):</strong></p>
        <p>If our system is unable to automatically recognize your company email address and/or company TIN within our
          Finexio network, you will be required to provide one or both of the following:</p>
        <ul>
          <li>Company W-9: W-9 form for business identification</li>
          <li>Invoice proving business relationship between Finexio Customer and Supplier</li>
        </ul>
        <p>If our system is unable to automatically verify your business bank account details, you will be required to
          confirm ownership by providing one of the following:</p>
        <ul>
          <li>Voided Check: A check from your company's bank account with the word 'VOID' written across it in large
            letters.
          </li>
          <li>Bank Letter of Ownership: A formal letter from the bank that confirms that the company is the owner of the
            account in question.
          </li>
          <li>Bank Statement: A bank statement that shows the bank name, account number, and name of account owner.</li>
        </ul>
      </div>,
      neededDocs: <>
        <li>Driver's License or Passport for Identity Verification. Knowledge Based Authentication (KBA) may be
          available depending on your location.
        </li>
        <li>W-9 for Business Identification.</li>
        <li>Invoice for proving business relationship.</li>
        <li>Bank statement, voided check, or letter from the bank, needed for proving bank account ownership.</li>
      </>,
      pageTitle: "Banking Update",
      cancelRedirectPage: "address-banking-change-request-form-async",
      includeCompanyName: true,
      includeAddress: false,
      isDocusignForm: false,
      includeWarning: false,
      accountTypePicker: false,
      includeTIN: true,
      emailTooltipText: "Your email that is associated with your company",
      individualAccountTypePicker: false,
      emailLabel: 'Company Email:',
      showConfirmation: false,
      submitURL: '/banking/createFromPDF',
      isAvs: true,
      includeReferenceId: true,
    };
  }
  if (pageType === AsyncPageType.INDIVIDUAL_BANKING_UPDATE) {
    return {
      warningOverwrite: <div className={'compact-card'}>
        <p><strong>Required Information:</strong></p>
        <ul>
          <li>Email Address: The email address you have registered with Finexio for verification and communications
            related to your payments.
          </li>
          <li>Bank Account and Routing Number: Have these numbers readily available to set up your payment options.</li>
        </ul>
        <p><strong>Additional Verification (if needed):</strong></p>
        <p>If our system is unable to automatically recognize your email address within our Finexio network, you will be
          required to provide the following:</p>
        <ul>
          <li>Documentation proving relationship between yourself and the Finexio Customer, such as an invoice, paystub,
            W2, company ID, etc.
          </li>
        </ul>
        <p>If our system is unable to automatically verify your bank account details, you will be required to confirm
          ownership by providing one of the following:</p>
        <ul>
          <li>Voided Check: A check from your bank account with the word 'VOID' written across it in large letters.</li>
          <li>Bank Letter of Ownership: A formal letter from your bank that confirms you are the owner of the account in
            question.
          </li>
          <li>Bank Statement: A bank statement that shows the bank name, account number, and name of account owner.</li>
        </ul>
      </div>,
      pageTitle: "Individual Banking Update",
      cancelRedirectPage: "address-banking-change-request-form-async",
      emailTooltipText: "Email address you have registered with Finexio for payments",
      includeCompanyName: false,
      includeAddress: false,
      isDocusignForm: false,
      includeWarning: false,
      accountTypePicker: false,
      individualAccountTypePicker: true,
      showConfirmation: false,
      submitURL: '/banking/createFromPDF',
      isAvs: true,
      includeReferenceId: true,
    };
  }
  if (pageType === AsyncPageType.PROFILE_AND_BANKING_UPDATE) {
    return {
      warningOverwrite: <div className={'compact-card'}>
        <p><strong>Required Information:</strong></p>
        <ul>
          <li>Company Email Address: This is your email address with your company that is registered with Finexio for
            payments.
          </li>
          <li>Company Tax Identification Number (TIN): This is used to identify your company in our system.</li>
          <li>Company Bank Account and Routing Number: Have these numbers readily available to set up your payment
            options.
          </li>
        </ul>
        <p><strong>Additional Verification (if needed):</strong></p>
        <p>If our system is unable to automatically recognize your company email address and/or company TIN within our
          Finexio network, you will be required to provide one or both of the following:</p>
        <ul>
          <li>Company W-9: W-9 form for business identification</li>
          <li>Invoice proving business relationship between Finexio Customer and Supplier</li>
        </ul>
        <p>If our system is unable to automatically verify your business bank account details, you will be required to
          confirm ownership by providing one of the following:</p>
        <ul>
          <li>Voided Check: A check from your company's bank account with the word 'VOID' written across it in large
            letters.
          </li>
          <li>Bank Letter of Ownership: A formal letter from the bank that confirms that the company is the owner of the
            account in question.
          </li>
          <li>Bank Statement: A bank statement that shows the bank name, account number, and name of account owner.</li>
        </ul>
      </div>,
      pageTitle: "Profile & Banking Update",
      cancelRedirectPage: "address-banking-change-request-form-async",
      includeCompanyName: true,
      emailTooltipText: "Your email that is associated with your company",
      emailLabel: 'Company Email:',
      includeAddress: false,
      isDocusignForm: false,
      includeWarning: false,
      accountTypePicker: false,
      includeTIN: true,
      individualAccountTypePicker: false,
      showConfirmation: false,
      submitURL: '/banking/createFromPDF',
      isAvs: true,
      includeReferenceId: true,
    };
  }
  if (pageType === AsyncPageType.ACH_DEBIT_AUTHORIZATION) {
    return {
      skipWarning: true,
      neededDocs: <>
        <li>Driver's License or Passport for Identity Verification. Knowledge Based Authentication (KBA) may be
          available depending on your location.
        </li>
        <li>Bank statement, voided check, or letter from the bank, needed for proving bank account ownership.</li>
      </>,
      pageTitle: "ACH Debit Authorization",
      cancelRedirectPage: "ach-debit-authorization",
      includeCompanyName: true,
      emailTooltipText: "Your email that is associated with your company",
      emailLabel: 'Company Email:',
      includeAddress: false,
      isDocusignForm: false,
      includeWarning: false,
      accountTypePicker: false,
      includeTIN: true,
      individualAccountTypePicker: false,
      showConfirmation: false,
      submitURL: '/banking/createFromPDF',
      isAvs: true,
    };
  }
  if (pageType === AsyncPageType.INDIVIDUAL_PROFILE_AND_BANKING_UPDATE) {
    return {
      warningOverwrite: <div className={'compact-card'}>
        <p><strong>Required Information:</strong></p>
        <ul>
          <li>Email Address: The email address you have registered with Finexio for verification and communications
            related to your payments.
          </li>
          <li>Bank Account and Routing Number: Have these numbers readily available to set up your payment options.</li>
        </ul>
        <p><strong>Additional Verification (if needed):</strong></p>
        <p>If our system is unable to automatically recognize your email address within our Finexio network, you will be
          required to provide the following:</p>
        <ul>
          <li>Documentation proving relationship between yourself and the Finexio Customer, such as an invoice, paystub,
            W2, company ID, etc.
          </li>
        </ul>
        <p>If our system is unable to automatically verify your bank account details, you will be required to confirm
          ownership by providing one of the following:</p>
        <ul>
          <li>Voided Check: A check from your bank account with the word 'VOID' written across it in large letters.</li>
          <li>Bank Letter of Ownership: A formal letter from your bank that confirms you are the owner of the account in
            question.
          </li>
          <li>Bank Statement: A bank statement that shows the bank name, account number, and name of account owner.</li>
        </ul>
      </div>,
      pageTitle: "Individual Profile & Banking Update",
      cancelRedirectPage: "address-banking-change-request-form-async",
      emailTooltipText: "Email address you have registered with Finexio for payments",
      includeCompanyName: false,
      includeAddress: false,
      isDocusignForm: false,
      includeWarning: false,
      accountTypePicker: false,
      individualAccountTypePicker: true,
      showConfirmation: false,
      submitURL: '/banking/createFromPDF',
      isAvs: true,
      includeReferenceId: true,
    };
  }
  if (pageType === AsyncPageType.EXPRESS) {
    return {
      skipWarning: true,
      pageTitle: "Finexio Express",
      emailLabel: 'Company Email:',
      cancelRedirectPage: "address-banking-change-request-form-async",
      includeCompanyName: true,
      includeAddress: false,
      emailTooltipText: "Your email that is associated with your company",
      isDocusignForm: false,
      includeWarning: false,
      accountTypePicker: true,
      individualAccountTypePicker: true,
      showConfirmation: false,
      includeTIN: false,
      submitURL: '/banking/createFromPDF',
      noBankingForm: false,
      isAvs: false,
      includePercentage: true,
      removeName: true,
      includeTOSLink: true,
    };
  }
  if (pageType === AsyncPageType.PROFILE_UPDATE) {
    return {
      warningOverwrite: <div className={'compact-card'}>
        <p><strong>Required Information:</strong></p>
        <ul>
          <li>Company Email Address: This is your email address with your company that is registered with Finexio for
            payments.
          </li>
          <li>Company Tax Identification Number (TIN): This is used to identify your company in our system.</li>
        </ul>
        <p><strong>Additional Verification (if needed):</strong></p>
        <p>If our system is unable to automatically recognize your company email address and/or company TIN within our
          Finexio network, you will be required to provide one or both of the following:</p>
        <ul>
          <li>Company W-9: W-9 form for business identification</li>
          <li>Invoice proving business relationship between Finexio Customer and Supplier</li>
        </ul>
      </div>,
      pageTitle: "Profile Update",
      emailLabel: 'Company Email:',
      cancelRedirectPage: "address-banking-change-request-form-async",
      includeCompanyName: true,
      includeAddress: false,
      emailTooltipText: "Your email that is associated with your company",
      isDocusignForm: false,
      includeWarning: false,
      accountTypePicker: true,
      individualAccountTypePicker: true,
      showConfirmation: false,
      includeTIN: true,
      submitURL: '/banking/createFromPDF',
      noBankingForm: true,
      isAvs: false,
      includeReferenceId: true,
    };
  }
  if (pageType === AsyncPageType.INDIVIDUAL_PROFILE_UPDATE) {
    return {
      warningOverwrite: <div className={'compact-card'}>
        <p><strong>Required Information:</strong></p>
        <ul>
          <li>Company Email Address: This is your email address with your company that is registered with Finexio for
            payments.
          </li>
        </ul>
        <p><strong>Additional Verification (if needed):</strong></p>
        <p>If our system is unable to automatically recognize your email address within our Finexio network, you will be
          required to provide the following:</p>
        <ul>
          <li>Documentation proving relationship between yourself and the Finexio Customer, such as an invoice, paystub,
            W2, company ID, etc.
          </li>
        </ul>
      </div>,
      pageTitle: "Individual Profile Update",
      cancelRedirectPage: "address-banking-change-request-form-async",
      emailTooltipText: "Email address you have registered with Finexio for payments",
      includeCompanyName: false,
      includeAddress: false,
      isDocusignForm: false,
      includeWarning: false,
      accountTypePicker: true,
      individualAccountTypePicker: true,
      showConfirmation: false,
      submitURL: '/profile',
      noBankingForm: true,
      isAvs: false,
      includeReferenceId: true,
    };
  }
}

function AsyncPageStart({text, formText, userFlowText, type}) {
  const [agree, setAgree] = useState();
  const [authLoading, setAuthLoading] = useState();
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [requesting, setRequesting] = useState(false);
  const [success, setSuccess] = useState();
  const avatarUrl = process.env.REACT_APP_SERVER_ROOT + '/assets/img/default_avatar.png';
  const avsURL = process.env.REACT_APP_SERVER_ROOT + '/assets/img/finexio_avs.png';
  const avsImage = (
    <>
      <div style={{height: "60px"}}></div>
      <img
        alt={"JPM AVS Logo"}
        src={avsURL}
        className={"avs-stamp"}
      />
    </>
  );

  const asyncPageProps = getAsyncPageProps(type);

  let warning = (<>
    <div className={"form-holder center-form"} style={{lineHeight: "1.4", position: "relative", height: 'unset'}}>
      {asyncPageProps.warningOverwrite || <>
        <p>Before proceeding, please ensure you have the following:</p>
        <ul style={{fontSize: '1rem'}}>
          {asyncPageProps.neededDocs}
        </ul>
      </>
      }
      {asyncPageProps.includeWarning &&
        <p><span style={{fontWeight: '700', textDecoration: 'underline'}}>Warning:</span> For security and compliance
          reasons the company name provided must match the name provided by your financial institution.</p>}

      <div className={"button-container"} style={{marginTop: "1rem", justifyContent: 'center'}}>
        <button className={"black-button"} onClick={() => setAgree(true)}>
          Continue
        </button>
      </div>
      {asyncPageProps.isAvs && avsImage}
    </div>
  </>);

  function formatNewAddress(event) {
    const isInternational = event.addressType === 'International';
    const cityLabel = isInternational ? "City/Town/Village" : "City";
    const stateLabel = isInternational ? "State/Province/Region" : "State";
    const zipLabel = isInternational ? "Postal Code" : "ZIP Code";

    const lines = [
      `Type: ${event.addressType}\n`,
      `${event.newAddressLine1}\n`,
      event.newAddressLine2 ? `${event.newAddressLine2}\n` : '',
      `${cityLabel}: ${event.newAddressCity}\n`,
      `${stateLabel}: ${event.newAddressState}\n`,
      `${zipLabel}: ${event.newAddressZip}\n`,
      isInternational ? `Country: ${event.newAddressCountry}\n` : '',
      event.newAddressAdditional ? `Additional Info: ${event.newAddressAdditional}` : '',
      '\n',
    ];
    return lines.join("");
  }

  async function handleSubmit(event) {
    setRequesting(true)
    let body = {
      firstName: event.firstName,
      lastName: event.lastName,
      signerEmail: event.signerEmail,
      companyName: event.companyName,
      tin: event.tin,
      address: event.address,
      payer: event.payer,
      pageType: type,
      referenceId: event.referenceId,
      percentage: event.percentage,
      ...(asyncPageProps.includeNewAddressForm && {address: formatNewAddress(event)}),
    };
    try {
      await sendRequest('/asyncForm/start', body);

      // Received URL for embedded signing, redirect user
      setSuccess(true);
    } catch (error) {
      console.log(error);
      const errorPageText = handleError(error);
      navigate('/error', {state: errorPageText});
    }
  }

  let form = (<>
    <Form
      avatarUrl={avatarUrl}
      userRoleName={text.names.userRoleName}
      text={{...formText, buttonName: "Next"}}
      userFlowText={userFlowText}
      onSubmit={handleSubmit}
      submitted={requesting}
      includeMoreInfo={false}
      includeUserProfile={false}
      includeCompanyName={asyncPageProps.includeCompanyName}
      companyLabel={asyncPageProps.companyLabel}
      emailLabel={asyncPageProps.emailLabel}
      includeAddress={asyncPageProps.includeAddress}
      includeTIN={asyncPageProps.includeTIN}
      includePayer={asyncPageProps.includePayer}
      includeNewAddressForm={asyncPageProps.includeNewAddressForm}
      companyTooltipText={asyncPageProps.companyTooltipText}
      emailTooltipText={asyncPageProps.emailTooltipText}
      // onCancelOverride={() => window.location.href = `https://www.finexio.com/${asyncPageProps.cancelRedirectPage}`}
      onCancelOverride={() => setAgree()}
      includeReferenceId={asyncPageProps.includeReferenceId}
      referenceTooltipText={asyncPageProps.referenceTooltipText}
      includePercentage={asyncPageProps.includePercentage}
      removeName={asyncPageProps.removeName}
      secretKey={searchParams.get('key')}
      includeTOS={asyncPageProps.includeTOSLink}
    />
  </>);
  if (authLoading) {
    return null;
  }
  return success ? (<Success
    title={"Success"}
    removeCheckmark={asyncPageProps.removeSuccessCheckmark}
    removeTitle={true}
    backButton={false}
    message={asyncPageProps.successMessage || <>A verification link has been sent to your email to proceed. If you cannot locate the email or need more assistance, please reach out to our support team by using <a
      href={"https://support.finexio.com/hc/en-us/requests/new"}>this link</a></>}
  />) : (
    <section className="content-section">
      <div className="container">
        <div className="header-container">
          <h1>{asyncPageProps.pageTitle}</h1>
        </div>
        {(agree || asyncPageProps.skipWarning) ? form : warning}
      </div>
    </section>
  );
}

export default AsyncPageStart;
import {useNavigate, useParams} from "react-router-dom";
import {handleError, sendRequest} from "../api/apiHelper";
import axios from "axios";
import {useEffect, useState} from "react";
import BankingForm from "../components/BankingForm";
import FormConfirmation from "../components/FormConfirmation";
import Success from "./Success";
import {getAsyncPageProps} from "./AsyncPageStart";

export function createAsyncFinishRequest(firstPage, bankingData) {
    return {
        firstName: firstPage.firstName,
        lastName: firstPage.lastName,
        signerName: `${firstPage.firstName} ${firstPage.lastName}`,
        payer: firstPage.payer,
        company: firstPage.companyName,
        companyName: firstPage.companyName,
        email: firstPage.signerEmail,
        signerEmail: firstPage.signerEmail,
        address: firstPage.address,
        accountNumber: bankingData.accountNumber,
        routingNumber: bankingData.routingNumber,
        accountOwnerFirstName: bankingData.accountOwnerFirstName,
        accountOwnerLastName: bankingData.accountOwnerLastName,
        accountOwnerBusinessName: bankingData.accountOwnerBusinessName,
        accountType: bankingData.accountType,
        asyncFormId: firstPage._id,
        page: firstPage.page,
        checkAvs: true,
        isAsync: true,
    }
}

function ResumeForm() {
    const { id } = useParams();
    let navigate = useNavigate();
    const [firstPage, setFirstPage] = useState();
    const [bankingCheckResult, setBankingCheckResult] = useState();

    const [bankingEvent, setBankingEvent] = useState();
    const [requesting, setRequesting] = useState();
    const [finalResponse, setFinalResponse] = useState();
    const [initialLoading, setInitialLoading] = useState(true);

    useEffect(() => {
        const fetchData = async()=> {
            try {
                const response = await axios.get(`/asyncForm/resume/${id}`);
                setFirstPage(response.data);
                const asyncPageProps = getAsyncPageProps(response.data.page);
                if (asyncPageProps.noBankingForm) {
                    const request = {
                        firstName: response.data.firstName,
                        lastName: response.data.lastName,
                        signerName: `${response.data.firstName} ${response.data.lastName}`,
                        payer: response.data.payer,
                        company: response.data.companyName,
                        companyName: response.data.companyName,
                        email: response.data.signerEmail,
                        signerEmail: response.data.signerEmail,
                        asyncFormId: response.data._id,
                        page: response.data.page,
                        isAsync: true,
                    };
                    const postResponse = await sendRequest(asyncPageProps.submitURL, request);
                    window.location = postResponse.data.url;
                }
                setInitialLoading(false);
            } catch (e) {
                navigate('/error', { state: {title: "Invalid URL", description: ""} });
            }
        }
        fetchData();
    }, [id, navigate]);

    const pageProps= firstPage && getAsyncPageProps(firstPage.page);

    if (initialLoading) {
        return <section className="content-section">
            <div className="container">
                <div className="header-container">
                    <h1>Loading</h1>
                </div>
            </div>
        </section>;
    }

    async function handleSubmitWithoutConfirmation(event) {
        setBankingEvent(event);
        const request = createAsyncFinishRequest(firstPage, event);
        try {
            setRequesting(true);
            const response = await sendRequest('/banking/createFromPDF', request);
            setBankingCheckResult(response.data);
        } catch (error) {
            const errorPageText = handleError(error);
            navigate('/error', { state: errorPageText });
        }
    }

    let content = null, title;
    if (finalResponse) {
        return <Success
          message={"Your request was submitted successfully. We will get back to you once it's processed."}
          backButton={false}
        />;
    }
    if (bankingCheckResult) {
        if (bankingCheckResult.url) {
            window.location.href = bankingCheckResult.url;
        } else {
            content = <FormConfirmation
              firstPage={firstPage}
              bankingData={bankingEvent}
              avsValid={bankingCheckResult.avsValid}
              onFinalResponse={setFinalResponse}
              onBack={() => {
                  setBankingCheckResult(null);
                  setRequesting(false);
              }}
            />;
            title = `${pageProps.pageTitle} - Confirmation`;
        }
    } else {
        title = `${pageProps.pageTitle} - Account Validation`;
        content = firstPage && (
          <BankingForm
            text={{buttonName: "Next"}}
            onSubmit={handleSubmitWithoutConfirmation}
            onCancel={() => {
            }}
            submitted={requesting}
            firstPage={firstPage}
            backButton={false}
            individual={pageProps.individualAccountTypePicker}
            accountTypePicker={pageProps.accountTypePicker}
          />
        );
    }

    if (!firstPage || pageProps.noBankingForm) {
        title = null;
        content = null;
    }
    return (
        <section className="content-section">
            <div className="container">
                <div className="header-container">
                    <h1>{title}</h1>
                </div>
                {content}
            </div>
        </section>
    );
}

export default ResumeForm;
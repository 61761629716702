import {useForm} from "react-hook-form";
import React, {useState} from "react";
import {ErrorMessage} from "@hookform/error-message";
import {handleError, sendRequest} from "../api/apiHelper";
import {useNavigate} from "react-router-dom";
import Success from "../pages/Success";
import {getAsyncPageProps} from "../pages/AsyncPageStart";
import {createAsyncFinishRequest} from "../pages/ResumeForm";
import {getAttachmentName, readFileAsBase64, validateAttachment} from "../utils/fileUpload";

/**
 * @param {string} line
 * @return {string}
 */
function stripLabel(line) {
    const colonIdx = line.indexOf(":");
    return line.substring(colonIdx + 2);
}

/**
 * Skip first line (address type). Remove the labels for the US addresses
 *
 * @param {string} address
 * return {string}
 */
function reformatAddress(address) {
    const inLines = address.split('\n');
    const cityIdx = inLines.findIndex(l => l.startsWith("City"));
    const outLines = [inLines[1]];
    if (cityIdx > 2) {
        outLines.push(inLines[2]);
    }
    if (inLines[0].indexOf("USA") !== -1) {
        const city = stripLabel(inLines[cityIdx]);
        const state = stripLabel(inLines[cityIdx + 1]);
        const zipCode = stripLabel(inLines[cityIdx + 2]);
        outLines.push(`${city}, ${state}, ${zipCode}`);
    } else {
        for (let idx = cityIdx; idx < inLines.length; idx++) {
            outLines.push(inLines[idx]);
        }
    }
    return outLines.join("\n");
}

function FormConfirmation({firstPage, bankingData, avsValid, onFinalResponse, onBack}) {
    let navigate = useNavigate();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();
    const [pageResponse] = useState();
    const [submitted, setSubmitted] = useState();

    const fileUploader =  !avsValid && <>
        <label htmlFor={"bankingFile"} style={{marginBottom: '10px'}}>Please attach supporting documentation that proves bank account ownership.
            <ErrorMessage
                errors={errors}
                name="file"
                as={<span className={"ml-1 error-label"} />}
                />
        </label>
        <input id={"bankingFile"} type="file" accept={'application/pdf,.png,.jpeg,.jpg'}
               {...register('file', {validate: validateAttachment})} />
    </>;

    const pageProps = firstPage && getAsyncPageProps(firstPage.page);

    async function submitForm(event) {
        let request = createAsyncFinishRequest(firstPage, bankingData);
        if (event.file) {
            const b64 = await readFileAsBase64(event.file[0]);
            request.attachment = {
                dataBase64: b64,
                fileName: getAttachmentName(event.file[0].type),
                contentType: event.file[0].type,
            }
        }
        try {
            setSubmitted(true);
            const response = await sendRequest('/ach/asyncFinish', request)
            console.log('Get final response', response.data);
            onFinalResponse(response.data);
        } catch (err) {
            const errorPageText = handleError(err);
            navigate('/error', { state: errorPageText });
        }
    }
    if (pageResponse) {
        return <Success message={"We received your request and we will start processing it."} />
    } else {
        const reformattedAddress = firstPage.address && reformatAddress(firstPage.address);
        const bankAccountOwner = bankingData.accountType === 'business' ? bankingData.accountOwnerBusinessName :
            `${bankingData.accountOwnerFirstName} ${bankingData.accountOwnerLastName}`;
        return <div className="form-holder center-form" style={{height: 'unset'}}>
            <form onSubmit={handleSubmit(submitForm)}>
                <label htmlFor={"firstName"} className={"no-asterisk-label"}>First Name</label>
                <input disabled id="firstName" value={firstPage.firstName}/>
                <label htmlFor={"lastName"} className={"no-asterisk-label"}>Last Name</label>
                <input disabled id="lastName" value={firstPage.lastName}/>
                {pageProps.includeReferenceId &&
                    <>
                        <label htmlFor={"referenceId"} className={"no-asterisk-label"}>Reference ID</label>
                        <input disabled id="referenceId" value={firstPage.referenceId}/>
                    </>
                }
                {pageProps.includePayer &&
                    <>
                        <label htmlFor={"payer"} className={"no-asterisk-label"}>Finexio Customer's Name</label>
                        <input disabled id="payer" value={firstPage.payer}/>
                    </>
                }
                {pageProps.includeCompanyName &&
                    <>
                        <label htmlFor={"company"} className={"no-asterisk-label"}>{pageProps.companyLabel || 'Company Name'}</label>
                        <input disabled id="company" value={firstPage.companyName}/>
                    </>
                }
                <label htmlFor={"email"} className={"no-asterisk-label"}>{pageProps.emailLabel || 'Email'}</label>
                <input disabled id="email" value={firstPage.signerEmail}/>
                {pageProps.includeAddress &&
                    <>
                        <label htmlFor={"address"} className={"no-asterisk-label"}>Mailing Address</label>
                        <textarea id={"address"} disabled rows={5} value={firstPage.address} />
                    </>
                }
                <label htmlFor={"accountNumber"} className={"no-asterisk-label"}>Account Number</label>
                <input disabled id="accountNumber" value={bankingData.accountNumber}/>
                <label htmlFor={"routingNumber"} className={"no-asterisk-label"}>Routing Number</label>
                <input disabled id="routingNumber" value={bankingData.routingNumber}/>
                <label htmlFor={"bankAccountOwner"} className={"no-asterisk-label"}>Bank Account Owner</label>
                <input disabled id={"bankAccountOwner"} value={bankAccountOwner} />
                {pageProps.includeNewAddressForm &&
                  <>
                      <label htmlFor={"address"} className={"no-asterisk-label"}>Mailing Address</label>
                      <textarea id={"address"} disabled rows={reformattedAddress.split("\n").length} style={{width: '100%'}} value={reformattedAddress} />
                  </>
                }
                {fileUploader}
                <div className="button-container">
                    <button className="black-button" type="submit" disabled={submitted}>
                        {submitted ? "Sending..." : "Submit"}
                    </button>
                    <button
                      type={"button"}
                      className="grey-button"
                      disabled={submitted}
                      onClick={onBack}
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    }
}

export default FormConfirmation;
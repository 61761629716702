import classNames from "classnames";
import {ReactComponent as Checkmark} from '../assets/images/white-checkmark.svg'
import {Fragment} from "react";

/**
 * @param {Number} currentStep
 * @param {string[]} stepLabels
 */
function CirclesProgress({currentStep, stepLabels}) {
  const TopStep = ({stepNr}) => <div
    className={classNames("circles--circle", {"circles--circle__checked": currentStep >= stepNr})}>
    {currentStep > stepNr ? <Checkmark alt={"checkmark"}/> : <span>{stepNr}</span>}
  </div>;
  const LineTo = ({destination}) => <div
    className={classNames("circles--line", {"circles--line__checked": currentStep >= destination})}
  >
    <hr/>
  </div>;
  const BottomStep = ({idx, label}) =>
    <p className={classNames({'circles--label__checked': currentStep >= idx})}
    >
      {label}
    </p>;

  return <>
    <div className={"circles--container"}>
      <TopStep stepNr={1}/>
      {stepLabels.slice(1).map((el, idx) => <Fragment key={idx}>
        <LineTo destination={idx + 2}/>
        <TopStep stepNr={idx + 2}/>
      </Fragment>)}
    </div>
    <div className={"circles--container_under"}>
      {stepLabels.map((label, idx) => <BottomStep key={idx} idx={idx + 1} label={label}/>)}
    </div>
  </>;
}

export default CirclesProgress;
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import {ErrorMessage} from "@hookform/error-message";
import {sendRequest} from "../api/apiHelper";

function InternalBankingCheck({ isIndividual }) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState();

  async function onSubmit(event) {
    console.log('Form submit ', event);
    try {
      setSubmitted(true);
      const request = {
        ...event,
        accountType: isIndividual ? 'individual' : 'business'
      }
      const response = await sendRequest('/check/avs', request);
      setResult(response.data);
      setSubmitted(false);
    } catch (err) {
      setSubmitted(false);
      setResult({authentication: "Request failed", verification: "Request failed"});
      console.log('InternalBankingCheck failure', err);
    }
  }
  const ErrorMessageContainer = ({ children }) => (
    <div className="error" style={{flexBasis: "auto"}}>{children}</div>
  );
  const individualFields = isIndividual && <>
    <div className="form-text-container" style={{justifyContent: "space-between", display: "flex"}}>
      <label style={{flexBasis: "auto"}}>Bank Account Owner First Name</label>
      <ErrorMessage
        errors={errors}
        name="accountOwnerFirstName"
        as={<ErrorMessageContainer/>}
      />
    </div>
    <input
      {...register("accountOwnerFirstName", {required: {value: true, message: "This field is required."}})}
    />
    <div className="form-text-container" style={{justifyContent: "space-between", display: "flex"}}>
      <label style={{flexBasis: "auto"}}>Bank Account Owner Last Name</label>
      <ErrorMessage
        errors={errors}
        style={{flexBasis: "auto"}}
        name="accountOwnerLastName"
        as={<ErrorMessageContainer/>}
      />
    </div>
    <input
      {...register("accountOwnerLastName", {required: {value: true, message: "This field is required."}})}
    />
  </>;
  const businessFields = !isIndividual && <>
    <div className="form-text-container" style={{justifyContent: "space-between", display: "flex"}}>
      <label style={{flexBasis: "auto"}}>Bank Account Owner Business Name</label>
      <ErrorMessage
        errors={errors}
        style={{flexBasis: "auto"}}
        name="accountOwnerBusinessName"
        as={<ErrorMessageContainer/>}
      />
    </div>
    <input
      {...register("accountOwnerBusinessName")}
    />
  </>;
  // return
  return <form onSubmit={handleSubmit(onSubmit)}>
    <div className="form-text-container" style={{justifyContent: "space-between"}}>
      <label>Account Number</label>
      <ErrorMessage
        errors={errors}
        name="accountNumber"
        as={<ErrorMessageContainer />}
      />
    </div>
    <input
      type={"number"}
      className={"no-spinners"}
      {...register('accountNumber', {
        required: {
          value: true,
          message: "This field is required.",
        },
      })}
    />
    <div className="form-text-container" style={{justifyContent: "space-between"}}>
      <label>New Routing Number</label>
      <ErrorMessage
        errors={errors}
        name="routingNumber"
        as={<ErrorMessageContainer/>}
      />
    </div>
    <input
      type={"number"}
      className={"no-spinners"}
      {...register('routingNumber', {
        required: {
          value: true,
          message: "This field is required.",
        },
        minLength: {
          value: 9,
          message: "A 9 digit number is required."
        },
        maxLength: {
          value: 9,
          message: "A 9 digit number is required."
        },
      })}
      style={{marginBottom: "10px"}}
    />
    {individualFields}
    {businessFields}
    <div className="button-container">
      <button className={"black-button"} type={"submit"} disabled={submitted}>Submit</button>
      <button className={"grey-button"} type={"button"} disabled={submitted} onClick={() => {
        setResult(null);
        reset();
      }}>
        Reset
      </button>
    </div>
    {result && <>
      <div className={"form-text-container"} style={{marginTop: "1rem"}}>
        <label className={"no-asterisk-label"}>Verification Result</label>
      </div>
      <input disabled value={result.verification} />
      <div className={"form-text-container"} >
        <label className={"no-asterisk-label"}>Authentication Result</label>
      </div>
      <input disabled value={result.authentication} />
    </>}
  </form>;
}

export default InternalBankingCheck;
import {Flatfile} from "@flatfile/api";

/**
 * @type {Flatfile.DocumentConfig}
 */
export const document = {
  title: "My document title",
  body: "Instructions ... lorem ipsum",
};

const errorsSheet = {
  name: "Data Errors",
  slug: "errors",
  fields: [
    {
      key: "lineNumber",
      type: "number",
      label: "Line Number",
      description: "Line number where the error occurred",
    },
    {
      key: "column",
      type: "string",
      label: "Column Name",
      description: "Column Name where the error occurred",
    },
    {
      key: "fieldValue",
      type: "string",
      label: "Field Value",
      description: "Value of the field that produced the error",
    },
    {
      key: "errorDescription",
      type: "string",
      label: "Error Description",
      description: "Description of the error",
    },
  ],
};

const dataSheet = {
  name: "Supplier Data",
  slug: "sheet",
  fields: [
    {
      key: "buyerName",
      type: "string",
      label: "Buyer Name",
      description: "Name of the buyer (property or business unit) sending the payment",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "buyerId",
      type: "string",
      description: "A string that uniquely identifies the buyer",
      label: "Buyer Id",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "buyerAccountNumber",
      type: "string",
      label: "Buyer Account Number",
      description: "The unique account number assigned to the buyer (property or business unit) by the supplier (i.e. Customer Account # assigned by the supplier)",
    },
    {
      key: "supplierName",
      type: "string",
      label: "Supplier Name",
      description: "Supplier name",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "supplierId",
      type: "string",
      label: "Supplier Id",
      description: "Unique Value provided by buyer (property or business unit) used to identify the supplier from their ERP/Back Office System. Please reference allowable characters in the User Guide.",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "supplierAddress1",
      type: "string",
      label: "Supplier Corporate Address Line 1",
      description: "Primary address for the Supplier. If Address 1 exceeds 40 characters or is ATTN line, continue to Address 2",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "supplierAddress2",
      type: "string",
      label: "Supplier Corporate Address Line 2",
      description: "Use if Address 1 is ATTN line or if Address 1 exceeds 40 characters",
    },
    {
      key: "supplierRemitCity",
      type: "string",
      label: "Supplier Corporate City",
      description: "Supplier Remit City",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "supplierRemitState",
      type: "string",
      label: "Supplier Remittance State",
      description: "Supplier Remit State (XX)",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "supplierRemitZip",
      type: "string",
      label: "Supplier Remittance Zip",
      description: "Supplier Remit Zip (##### or #####-####)",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "supplierRemitCountry",
      type: "string",
      label: "Supplier Remittance Country",
      description: "Supplier Remit Country Code Abbreviation (XXX)",
      constraints: [{
        type: "required",
      }],
    },
    {
      key: "supplierRemitEmail",
      type: "string",
      label: "Supplier Remittance Email Address",
      description: "If payment method is ACH, email address the remittance is sent to for the supplier. If Canadian supplier, remittance email is needed.",
    },
    {
      key: "paymentMethod",
      type: "enum",
      label: "Payment Method",
      description: "How the buyer (property or business unit) is currently transmitting payment to the supplier (Check, ACH, Wire, Card)",
      constraints: [{
        type: "required",
      }],
      config: {
        options: [
          {value: "Check", label: "Check"},
          {value: "ACH", label: "ACH"},
          {value: "Wire", label: "Wire"},
          {value: "Card", label: "Card"},
        ]
      }
    },
    {
      key: "disbursementAccountNumber",
      type: "string",
      label: "Disbursement Account Number",
      description: "If payment method is ACH, the Supplier's current account number",
    },
    {
      key: "disbursementRoutingNumber",
      type: "string",
      label: "Disbursement Routing Number",
      description: "If payment method is ACH, the Supplier's current routing number",
    },
    {
      key: "paymentNotes",
      type: "string",
      label: "Payment Notes",
      description: "Any additional information needed on the Supplier (ie portal login information if paid via portal, etc)",
    },
    {
      key: "twelveMonthDollarSpend",
      type: "number",
      label: "12 Month Dollar Spend",
      description: "Total spend amount the buyer (property or business unit) has paid to this entity within the last 12 months",
      config: {
        decimal_places: 2,
      },
    },
    {
      key: "twelveMonthPaymentCount",
      type: "number",
      label: "12 Month Payment Count",
      description: "Number of times the buyer (property or business unit) sent a payment to this entity within the last 12 months",
      config: {
        decimal_places: 0,
      },
    },
    {
      key: "clientReportedPaymentTerms",
      type: "string",
      label: "Client Reported Payment Terms",
      description: "Agreed upon timing of the payment with the supplier. This includes all payment terms & discounts with the Supplier or supplier*",
    },
    {
      key: "supplierContactName",
      type: "string",
      label: "Supplier Contact Name",
      description: "Point of contact name for the supplier",
    },
    {
      key: "supplierPhone",
      type: "string",
      label: "Supplier Phone",
      description: "Phone Number of the point of contact for the supplier - no hyphens (0000000000)",
    },
    {
      key: "supplierEmail",
      type: "string",
      label: "Supplier Email Address",
      description: "Email address of the point of contact for the Supplier",
    },
    {
      key: "supplierTaxId",
      type: "string",
      label: "Supplier Tax ID",
      description: "Business Tax ID Number",
    },
    {
      key: "paymentCategory",
      type: "enum",
      label: "Payment Category",
      constraints: [{
        type: "required",
      }],
      config: {
        options: [
          {value: "Individual", label: "Individual"},
          {value: "Employee", label: "Employee"},
          {value: "Internal Transfer", label: "Internal Transfer"},
          {value: "Business", label: "Business"},
          {value: "Banks/Finance Companies", label: "Banks/Finance Companies"},
          {value: "Student", label: "Student"},
        ]
      }
    },
  ],
};


export const workbook = {
  name: "Supplier Data",
  sheets: [dataSheet, errorsSheet],
  actions: [
    {
      label: "Upload",
      operation: "submitActionFg",
      mode: "foreground",
      primary: true,
      description: "Please confirm the data is correct",
      constraints: [
        { type: 'hasData' },
      ],
      confirm: true,
    },
  ]
};
/**
 * @param {File} file
 * @return {Promise<string>} Base64 encoded contents of the file
 */
export function readFileAsBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result.split(',')[1])
    }
    reader.onerror = () => {
      reject(reader.error)
    }

    reader.readAsDataURL(file)
  });
}

/**
 * Generate a file name for an attachment based on the fileType
 * @param {string} fileType
 * @param {string} suffix (optional argument)
 * @return {string}
 */
export function getAttachmentName(fileType, suffix = "") {
  const extensions = {"image/png": "png", "image/jpeg": "jpeg", "application/pdf": "pdf"};
  return `Attachment${suffix}.${extensions[fileType]}`;
}

/**
 * Validator to be used in forms. Allows one file of type png/pdf/jpeg less than 10Mb in size
 * @param {FileList} fileList object sent by useForm for validation
 * @return {string|boolean} true if input is valid, error message string otherwise
 */
export function validateAttachment(fileList) {
  if (fileList.length === 0) {
    return "Required";
  }
  if (fileList.length > 1) {
    return "Only 1 file allowed";
  }
  const firstFile = fileList[0];
  if (!['image/png', 'image/jpeg', 'application/pdf'].includes(firstFile.type)) {
    return 'Only png/jpeg/pdf files allowed'
  }
  if (firstFile.size > 6 * 1024 * 1024) {
    return 'File size larger than 6MB'
  }
  return true;
}
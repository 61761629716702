import {ErrorMessage} from "@hookform/error-message";
import ErrorMessageContainer from "./ErrorMessageContainer";
import classNames from "classnames";

function FormLabel({label, errors, fieldId, asterisk = true}) {
  return <div className="form-text-container">
    <label className={classNames({"no-asterisk-label": !asterisk})}>
      {label}
    </label>
    <ErrorMessage
      errors={errors}
      name={fieldId}
      as={<ErrorMessageContainer/>}
    />
  </div>;
}

export default FormLabel;
import React, {useState} from "react";
import Form from "../components/Form";
import {useNavigate} from 'react-router-dom';

import {handleError, sendRequest} from "../api/apiHelper";
import BankingForm from "../components/BankingForm";

function ProfileAndBankingEnrollment({text, formText, userFlowText}) {
    let navigate = useNavigate();
    const [agree, setAgree] = useState();
    const isAvs = true;
    const avatarUrl = process.env.REACT_APP_SERVER_ROOT + '/assets/img/default_avatar.png';
    const [formEvent, setFormEvent] = useState();
    const [requesting, setRequesting] = useState(false);
    const [docusignResponse, setDocusignResponse] = useState();

    async function handleSubmit(event) {
        setRequesting(true);
        const body = {
            signerName: event.firstName + ' ' + event.lastName,
            signerEmail: event.signerEmail,
            companyName: event.companyName,
        };
        // Send request to server
        try {
            const response = await sendRequest('/enrollment/profileAndBanking', body);

            // Received URL for embedded signing, redirect user
            if (response.status === 200) {
                window.location = response.data.url;
            }
        } catch (error) {
            console.log(error);
            const errorPageText = handleError(error);
            navigate('/error', { state: errorPageText });
        }
    }
    async function handleSubmitWithAvs(event) {
        setRequesting(true);
        // Make request body
        const body = {
            signerName: formEvent.firstName + ' ' + formEvent.lastName,
            signerEmail: formEvent.signerEmail,
            companyName: formEvent.companyName,
            firstName: formEvent.firstName,
            lastName: formEvent.lastName,
            checkAvs: true,
            accountNumber: event.accountNumber,
            routingNumber: event.routingNumber,
            accountType: event.accountType,
            accountOwnerFirstName: event.accountOwnerFirstName,
            accountOwnerLastName: event.accountOwnerLastName,
            accountOwnerBusinessName: event.accountOwnerBusinessName,
        };

        // Send request to server
        try {
            const response = await sendRequest('/enrollment/profileAndBanking', body);

            // Received URL for embedded signing, redirect user
            setDocusignResponse(response.data);
            // if (response.status === 200) {
            //   window.location = response.data;
            // }
        } catch (error) {
            console.log(error);
            const errorPageText = handleError(error);
            navigate('/error', { state: errorPageText });
        }
    }

    const avsURL = process.env.REACT_APP_SERVER_ROOT + '/assets/img/finexio_avs.png';
    const avsImage = (
        <>
            <div style={{height: "60px"}}></div>
            <img
                src={avsURL}
                alt={"Finexio validation services powered by JP Morgan"}
                className={"avs-stamp"}
            />
        </>
    );
    const warning = (
        <div className={"form-holder center-form"} style={{fontSize: "1rem", position: "relative", height: "unset"}}>
            <p>Before proceeding, please make sure you have the following documents available:</p>
            <ul style={{fontSize: '1rem'}}>
                <li>Driver's License or Passport for Identity Verification. Knowledge Based Authentication (KBA) may be available depending on your location.</li>
                <li>W-9 for Business Identification</li>
                <li>Invoice for proving business relationship</li>
                <li>Bank statement, voided check, or letter from the bank, needed for proving bank account ownership</li>
            </ul>
            {isAvs && (<p><span style={{fontWeight: '700', textDecoration:'underline'}}>Warning:</span> For security and compliance reasons the company name provided must match the name provided by your financial institution.</p>)}
            <div className={"button-container"} style={{marginTop: "1rem"}}>
                <button className={"black-button"} onClick={() => setAgree(true)}>
                    Continue
                </button>
                <button className="grey-button" onClick={() => navigate("/index")}>Cancel</button>
            </div>
            {isAvs && avsImage}
        </div>);


    let form = <Form
        avatarUrl={avatarUrl}
        userRoleName={text.names.userRoleName}
        text={{...formText, ...(isAvs ? {buttonName: "Next"} : {})}}
        userFlowText={userFlowText}
        onSubmit={isAvs ? setFormEvent : handleSubmit}
        submitted={requesting}
        includeMoreInfo={false}
        includeCompanyName={true}
        includeUserProfile={false}
    />;
    let contents;

    const bankingForm = (
        <BankingForm
            text={text}
            onSubmit={handleSubmitWithAvs}
            onCancel={() => setFormEvent(null)}
            submitted={requesting}
            firstPage={formEvent}
        />
    );

    let title = "Profile and Banking Enrollment";
    if (docusignResponse) {
        // contents = (<BankingValid url={docusignResponse.url} avsFailed={docusignResponse.avsFailed}/>);
        window.location.href = docusignResponse.url;
        title = "Loading";
    } else {
        contents = agree ? (formEvent ? bankingForm : form) : warning;
    }

    return (
        <section className="content-section">
            <div className="container">
                <div className="header-container">
                    <h1>{title}</h1>
                </div>
                {contents}
            </div>
        </section>
    );
}

export default ProfileAndBankingEnrollment;
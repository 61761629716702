import {useForm} from "react-hook-form";
import {validateAttachment} from "../utils/fileUpload";
import FormLabel from "./FormLabel";

function BuyerOnboardingBankingPopup({onCancel, onSubmit, sending, fundingIsInvalid, refundingIsInvalid}) {
  const {register, handleSubmit, formState: {errors}} = useForm();
  const accountsInvalid = [];
  if (fundingIsInvalid) {
    accountsInvalid.push("funding");
  }
  if (refundingIsInvalid) {
    accountsInvalid.push("refund");
  }

  return <div className="popup">
    <div className="box" style={{padding: "2.5rem"}}>
      <h2 className={"mt-0 color-primary"}>Bank Account Verification</h2>
      <p style={{margin: "0 0 1rem 0"}}>
        Our system was unable to automatically verify your {accountsInvalid.join(' and ')}  {accountsInvalid.length > 1 ? 'accounts' : 'account'}. Please
        upload a voided check or a letter from your bank{accountsInvalid.length > 1 ? ' for each of the accounts' : ''}.
      </p>
      <p style={{margin: "0 0 1rem 0"}}>
        <strong>Note:</strong> The bank {accountsInvalid.length > 1 ? 'letters' : 'letter'} must be on your bank’s letterhead and contain the account information and
        email address of your financial institution.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        {fundingIsInvalid &&
          <>
            <FormLabel label={"Funding Account"} errors={errors} fieldId={"fundingDoc"} />
            <input type={"file"} className={"fs-2 mb-1"} {...register("fundingDoc", {validate: validateAttachment})}
                   accept={'application/pdf,.png,.jpeg,.jpg'}/>
          </>
        }
        {refundingIsInvalid &&
          <>
            <FormLabel label={"Refund Account"} errors={errors} fieldId={"refundingDoc"} />
            <input type={"file"} className={"fs-2"} {...register("refundingDoc", {validate: validateAttachment})}
                   accept={'application/pdf,.png,.jpeg,.jpg'}/>
          </>
        }
        <div className={"mt-2"}>
          <button
              className={"button--base button__primary"}
              style={{width: "30%"}}
              disabled={sending}
          >
            Send
          </button>
          <button
              onClick={onCancel}
              className={"button--base button__secondary ml-2"}
              style={{width: "30%"}}
              disabled={sending}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  </div>
    ;
}

export default BuyerOnboardingBankingPopup;
import {useForm} from "react-hook-form";
import {useState} from "react";
import {ErrorMessage} from "@hookform/error-message";
import {sendRequest} from "../api/apiHelper";
import FormattedField from "./FormattedField";

function SendMagicLink() {
  const {
    control,
    register,
    handleSubmit,
    formState: {errors},
    reset,
    watch,
  } = useForm({defaultValues: {
    destinationPage: "business-ach-enrollment",
    percentage: "1.50%"
  }});
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState();

  async function onSubmit(event) {
    try {
      setSubmitted(true);
      await sendRequest('/internalTools/sendBusinessAchEmail', {
        email: event.email,
        name: event.clientName,
        destinationPage: event.destinationPage,
        percentage: event.percentage.replaceAll(' ', ''),
      });
      setResult('Sent at ' + new Date().toLocaleString('en-US', {timeZone: 'America/New_York'}));
      setSubmitted(false);
    } catch (e) {
      console.error('failed e');
    }
  }
  return <form onSubmit={handleSubmit(onSubmit)}>
    <fieldset>
      <legend>Who are we mailing the enrollment to?</legend>
      <div className={"form-text-container"} style={{justifyContent: "space-between", display: "flex"}}>
        <label style={{flexBasis: "auto"}}>Counterparty Name:</label>
        <ErrorMessage
          name={"clientName"}
          errors={errors}
          as={<span className={"error-label"} />}
        />
      </div>
      <input {...register("clientName", {required: {value: true, message: "Required"}})}/>
      <div className={"form-text-container"} style={{justifyContent: "space-between", display: "flex"}}>
        <label style={{flexBasis: "auto"}}>Counterparty Email:</label>
        <ErrorMessage
          name={"email"}
          errors={errors}
          as={<span className={"error-label"} />}
        />
      </div>
      <input {...register("email", {required: {value: true, message: "Required"}})} type={"email"}/>
    </fieldset>
    {watch('destinationPage') === 'finexio-express' &&     <FormattedField
      control={control}
      fieldId={"percentage"}
      format={"# . ## %"}
      errors={errors}
      label={"Fee Percentage"}
    />}
    <div className={"form-text-container"}>
      <label htmlFor={"destinationPage"}>Destination page</label>
    </div>
    <select id={"destinationPage"} {...register("destinationPage")}>
      <option value={"business-ach-enrollment"}>Business ACH Enrollment</option>
      <option value={"finexio-express"}>Express Form</option>
    </select>
    <div className={"button-container"}>
      <button className={"black-button"} type={"submit"} disabled={submitted}>Submit</button>
      <button className={"grey-button"} disabled={submitted} onClick={() => {reset(); setResult();}}>Reset</button>
    </div>
    {result && <>
      <div style={{marginTop: "1rem"}}>
        <span>{result}</span>
      </div>
    </>
    }
  </form>;
}

export default SendMagicLink;

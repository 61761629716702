import SingleFormField from "./SingleFormField";
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import * as EmailValidator from "email-validator";
import {ReactComponent as LockIcon} from '../assets/images/lock.svg';
import {ReactComponent as QuestionIcon} from '../assets/images/question-icon.svg';
import {registerTooltip} from "../utils/tooltips";
import FormLabel from "./FormLabel";
import InfoTooltip from "./InfoTooltip";

function BuyerOnboardingBankingForm({onFinish, requesting, companyName, isExistingClient}) {
  const [sameOwner, setSameOwner] = useState(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    reset,
    watch,
  } = useForm({defaultValues: {fundingMethod: "ach", refundingAccount: "refundingSame"}});

  function checkboxClick() {
    setSameOwner(v => {
      if (!v) {
        setValue("accountOwnerBusinessName", companyName);
      }
      return !v;
    });
  }

  const questionIcon =
    <QuestionIcon style={{marginLeft: "0.25rem", color: "black", width: "1.25rem", height: "auto"}}/>;
  const yourDataIsSecure = <div className={"your-data-is-secure"}>
    <LockIcon alt={"lock icon"}/>
    <a href={"https://portal.finexio.com/#"}>Your data is secure</a>
  </div>;

  return <div
    className={"buyer-onboarding"}>
    <h2 className={"mt-0"}>{isExistingClient ? 'Update your banking information' : 'Connect your bank'}</h2>
    <form className={"plain-form"} onSubmit={handleSubmit(onFinish)}>
      <fieldset style={{marginBottom: "1rem"}}>
        <legend>Funding Method</legend>
        <div className={"two-cols--dynamic"}>
          <div className={"ml-1"}>
            <div className={"centered-flex"}>
              <input type={"radio"}
                     className={"reset-width"}
                     value={"ach"}
                     id={"methodAch"}
                     {...register("fundingMethod")}
              />
              <label className={"no-asterisk-label ml-1"} htmlFor={"methodAch"}>ACH</label>
            </div>
            <div className={"mt-1"}>
              <input type={"radio"}
                     value={"wire"}
                     className={"reset-width"}
                     id={"methodWire"}
                     {...register("fundingMethod")}
              />
              <label className={"no-asterisk-label ml-1"} htmlFor={"methodWire"}>Wire</label>
            </div>
          </div>
          <div className={"info-box"}>
            <p>
              {watch("fundingMethod") === "ach" ?
                "Payments are initiated when funds are settled, which may take up to two business days."
                : "Payments are initiated upon receipt of funds."}
            </p>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend className={"display-flex align-center"}>
          Funding Account
          <a
            className={"display-block"}
            {...registerTooltip("fundingTooltip", "Specify the bank account you are authorizing Finexio to use to withdraw funds and execute payments.")} >
            {questionIcon}
          </a>
          <InfoTooltip id={"fundingTooltip"}/>
        </legend>
        <div className={"two-cols--dynamic"}>
          <div>
            <SingleFormField
              fieldId={"accountNumber"}
              label={"Account Number"}
              register={register}
              errors={errors}
              inputType={"number"}
            />
          </div>
          <div>
            <SingleFormField
              fieldId={"routingNumber"}
              label={"Routing Number"}
              register={register}
              errors={errors}
              inputType={"number"}
              validate={(v) => (v && v.length === 9) || "9 digits needed"}
            />
          </div>
        </div>
        <div className={"two-cols--dynamic"}>
          <div>
            <FormLabel label={"Account Holder Name"} errors={errors} fieldId={"accountOwnerBusinessName"}/>
            <input id={"accountOwnerBusinessName"}
                   {...register("accountOwnerBusinessName", {required: "Required"})}
                   {...registerTooltip("fundingAccountHolderTtip", "Enter the name as it appears on the bank account you are using for funding. This should align exactly with the account holder name registered with your bank.")}
                   disabled={sameOwner}
            />
            <InfoTooltip id={"fundingAccountHolderTtip"}/>
          </div>
          <div>
            <FormLabel label={"Email Notifications"} asterisk={false} errors={errors}
                       fieldId={"fundingEmailNotifications"}/>
            <input id={"fundingEmailNotifications"}
                   {...register("fundingEmailNotifications", {validate: (v) => (!v || EmailValidator.validate(v)) || 'Invalid Email'})}
                   {...registerTooltip("emailNotifsTtip", "Opt to receive email notifications about activities and updates related to the funding of your payments.")}
            />
            <InfoTooltip id={"emailNotifsTtip"}/>
          </div>
        </div>
        <div className={"mb-2 centered-flex"}>
          <input type={"checkbox"} className={"reset-width"} checked={sameOwner}
                 onChange={checkboxClick}>
          </input>
          <label style={{marginLeft: "0.5rem", flexBasis: "auto"}} className={"no-asterisk-label"}>
            Same owner as previously entered
          </label>
        </div>
      </fieldset>
      <fieldset>
        <legend className={"display-flex align-center"}>
          Refund Account
          <a
            className={"display-block"}
            {...registerTooltip("refundingTooltip", "Specify the bank account where refunds should be directed to when Finexio is unable to execute a payment.")} >
            {questionIcon}
          </a>
          <InfoTooltip id={"refundingTooltip"}/>
        </legend>
        <div className={"two-cols mb-2"}>
          <div className={"centered-flex"}>
            <input type={"radio"}
                   value={"refundingSame"}
                   className={"reset-width"}
                   id={"refundSame"}
                   {...register("refundingAccount")}
            />
            <label className={"no-asterisk-label ml-1"} htmlFor={"refundingSame"}>Same as funding account</label>
          </div>
          <div className={"centered-flex"}>
            <input type={"radio"}
                   value={"refundingDifferent"}
                   className={"reset-width"}
                   id={"refundDifferent"}
                   style={{color: "red"}}
                   {...register("refundingAccount")}
            />
            <label className={"no-asterisk-label ml-1"} htmlFor={"refundingDifferent"}>Different account</label>
          </div>
        </div>
        {watch("refundingAccount") === "refundingDifferent" && <>
          <div className={"two-cols--dynamic"}>
            <div>
              <SingleFormField
                fieldId={"refundingAccountNumber"}
                label={"Account Number"}
                register={register}
                errors={errors}
                inputType={"number"}
              />
            </div>
            <div>
              <SingleFormField
                fieldId={"refundingRoutingNumber"}
                label={"Routing Number"}
                register={register}
                errors={errors}
                inputType={"number"}
                validate={(v) => (v && v.length === 9) || "9 digits needed"}
              />
            </div>
          </div>
          <div className={"two-cols--dynamic"} style={{marginBottom: "1rem"}}>
            <div>
              <SingleFormField
                fieldId={"refundingAccountOwnerBusinessName"}
                label={"Account Holder Name"}
                register={register}
                errors={errors}
              />
            </div>
            <div>
              <SingleFormField
                fieldId={"refundingEmailNotifications"}
                label={"Email Notifications"}
                register={register}
                errors={errors}
                required={false}
                validate={(v) => (!v || EmailValidator.validate(v)) || 'Invalid Email'}
              />
            </div>
          </div>
        </>
        }
      </fieldset>
      <div className={"two-cols"}>
        <button className={"button--base button__primary"} disabled={requesting} style={{width: "70%"}}>
          Finish
        </button>
        <button className={"button--base button__secondary"} disabled={requesting} onClick={() => reset()}
                style={{width: "70%"}}>
          Reset
        </button>
      </div>
      {yourDataIsSecure}
    </form>
  </div>;
}

export default BuyerOnboardingBankingForm;
import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {ErrorMessage} from '@hookform/error-message';
import {useNavigate} from 'react-router-dom';
import Popup from './Popup';
import UserProfile from './UserProfile';
import {Tooltip} from "react-tooltip";
import {useWindowWidth} from "@react-hook/window-size";
import ErrorMessageContainer from "./ErrorMessageContainer";
import FormLabel from "./FormLabel";
import FormattedField from "./FormattedField";
import {getPercentage} from "../utils/secretKey";

function Form({
                avatarUrl,
                userRoleName,
                includeMoreInfo = true,
                text,
                userFlowText,
                includePhone,
                includeCompanyName = false,
                onSubmit,
                placeholderName,
                includeUserProfile = true,
                includeCancelButton = true,
                nameLabel,
                submitted = false,
                companyLabel = 'Company Name:',
                emailLabel = null,
                includeAddress = false,
                includeTIN = false,
                onCancelOverride = null,
                includePayer = false,
                includeNewAddressForm = false,
                companyTooltipText = null,
                emailTooltipText = null,
                includeReferenceId = false,
                referenceTooltipText = null,
                includePercentage = false,
                removeName = false,
                secretKey = "",
                includeTOS = false,
              }) {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const defaultPercentage = (secretKey && getPercentage(secretKey)) || '1.5%';
  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
    control,
  } = useForm({
    defaultValues: {
      addressType: "USA",
      percentage: defaultPercentage,
    }
  });
  const isDesktop = useWindowWidth() > 1100;
  const watchAddressType = watch("addressType");

  const newAddressForm = includeNewAddressForm &&
    <>
      <div className="form-text-container">
        <label htmlFor={"addressType"} className={"no-asterisk-label"}>Mailing Address Type:</label>
      </div>
      <select id={"addressType"} {...register("addressType")} >
        <option value={"USA"}>United States</option>
        <option value={"International"}>International</option>
      </select>
      <div className="form-text-container">
        <label>Street Line 1:</label>
        <ErrorMessage
          errors={errors}
          name="newAddressLine1"
          as={<ErrorMessageContainer/>}
        />
      </div>
      <input
        type="text"
        {...register('newAddressLine1', {
          required: {
            value: true,
            message: text.requiredFieldError,
          },
        })}
      />
      <div className="form-text-container">
        <label className={"no-asterisk-label"}>Street Line 2:</label>
        <ErrorMessage
          errors={errors}
          name="newAddressLine2"
          as={<ErrorMessageContainer/>}
        />
      </div>
      <input
        type="text"
        {...register('newAddressLine2')}
      />
      <div className="form-text-container">
        <label>{watchAddressType === "USA" ? "City:" : "City/Town/Village"}</label>
        <ErrorMessage
          errors={errors}
          name="newAddressCity"
          as={<ErrorMessageContainer/>}
        />
      </div>
      <input
        type="text"
        {...register('newAddressCity', {
          required: {
            value: true,
            message: text.requiredFieldError,
          },
        })}
      />
      <div className="form-text-container">
        <label>{watchAddressType === "USA" ? "State:" : "State/Province/Region"}</label>
        <ErrorMessage
          errors={errors}
          name="newAddressState"
          as={<ErrorMessageContainer/>}
        />
      </div>
      <input
        type="text"
        {...register('newAddressState', {
          required: {
            value: true,
            message: text.requiredFieldError,
          },
        })}
      />
      <div className="form-text-container">
        <label>{watchAddressType === "USA" ? "ZIP Code:" : "Postal Code:"}</label>
        <ErrorMessage
          errors={errors}
          name="newAddressZip"
          as={<ErrorMessageContainer/>}
        />
      </div>
      <input
        type="text"
        {...register('newAddressZip', {
          required: {
            value: true,
            message: text.requiredFieldError,
          },
        })}
      />
      {watchAddressType === "International" &&
        <>
          <div className="form-text-container">
            <label>Country:</label>
            <ErrorMessage
              errors={errors}
              name="newAddressCountry"
              as={<ErrorMessageContainer/>}
            />
          </div>
          <input
            type="text"
            {...register('newAddressCountry', {
              required: {
                value: true,
                message: text.requiredFieldError,
              },
            })}
          />
          <div className="form-text-container">
            <label htmlFor={"newAddressAdditional"} className={"no-asterisk-label"}>
              Additional Information:
            </label>
            <ErrorMessage
              name={"newAddressAdditional"}
              errors={errors}
              as={<ErrorMessageContainer/>}/>
          </div>
          <textarea id={"address"} rows={3} style={{width: '100%'}} {...register("newAddressAdditional")} />
        </>}
    </>;
  const referenceId = includeReferenceId && <>
    <div className="form-text-container">
      <label htmlFor={"referenceId"} className={"no-asterisk-label"}>
        Reference ID (if applicable):
      </label>
      <ErrorMessage
        name={"referenceId"}
        errors={errors}
        as={<ErrorMessageContainer/>}/>
    </div>
    <Tooltip id={"referenceIdTooltip"} style={{backgroundColor: '#169ee3'}}/>
    <input
      id={"referenceId"}
      {...register("referenceId")}
      {...{
        'data-tooltip-id': 'referenceIdTooltip',
        'data-tooltip-content': referenceTooltipText || 'Your reference ID from the company issuing you payments',
        'data-tooltip-place': (isDesktop ? "right" : "top"),
        'data-tooltip-variant': 'info',
      }}
    />
  </>;

  const tin = includeTIN && <>
    <div className="form-text-container">
      <label htmlFor={"tin"}>
        Company TIN:
      </label>
      <ErrorMessage
        name={"tin"}
        errors={errors}
        as={<ErrorMessageContainer/>}/>
    </div>
    <Tooltip id={"tinTooltip"} style={{backgroundColor: '#169ee3'}}/>
    <input
      type={"number"}
      id={"tin"}
      {...{
        'data-tooltip-id': 'tinTooltip',
        'data-tooltip-content': 'Your company\'s Tax Identification Number',
        'data-tooltip-place': (isDesktop ? "right" : "top"),
        'data-tooltip-variant': 'info',
      }}
      className={'no-spinners'}
      {...register("tin", {
        required: {
          value: true,
          message: text.requiredFieldError,
        },
        minLength: {
          value: 9,
          message: "A 9 digit number is required."
        },
        maxLength: {
          value: 9,
          message: "A 9 digit number is required."
        },
      })}
    />
  </>;
  const address = includeAddress && <>
    <div className="form-text-container">
      <label htmlFor={"address"}>
        Mailing Address:
      </label>
      <ErrorMessage
        name={"address"}
        errors={errors}
        as={<ErrorMessageContainer/>}/>
    </div>
    <textarea id={"address"} rows={5} style={{width: '100%'}} {...register("address", {
      required: {
        value: true,
        message: text.requiredFieldError,
      }
    })}/>
  </>;
  const percentage = includePercentage && <>
    <FormattedField
      control={control}
      fieldId={"percentage"}
      format={"# . ## %"}
      errors={errors}
      label={"Fee Percentage"}
      disabled={true}
    />
  </>;
  const payer = includePayer && (
    <>
      <div className="form-text-container">
        <label>Finexio Customer's Name:</label>
        <ErrorMessage
          errors={errors}
          name="payer"
          as={<ErrorMessageContainer/>}
        />
      </div>
      <Tooltip id={"payerTooltip"} style={{backgroundColor: '#169ee3'}}/>
      <input
        type="text"
        {...{
          'data-tooltip-id': 'payerTooltip',
          'data-tooltip-content': "Who you are receiving payments from",
          'data-tooltip-place': (isDesktop ? "right" : "top"),
          'data-tooltip-variant': 'info',
        }}
        {...register('payer', {
          required: {
            value: true,
            message: text.requiredFieldError,
          },
          maxLength: {value: 30, message: text.inputTooLongError},
        })}
      />

    </>
  );
  const name = !removeName && <>
    <FormLabel
      label={text.firstName}
      errors={errors}
      fieldId={"firstName"}
    />
    <input
      type="text"
      {...register('firstName', {
        required: {
          value: true,
          message: text.requiredFieldError,
        },
        maxLength: {value: 30, message: text.inputTooLongError},
      })}
    />
    <FormLabel
      label={text.lastName}
      errors={errors}
      fieldId={"lastName"}
    />
    <input
      type="text"
      {...register('lastName', {
        required: {
          value: true,
          message: text.requiredFieldError,
        },
        maxLength: {value: 50, message: text.inputTooLongError},
      })}
    />
  </>;
  return (
    <>
      <div className="form-holder center-form" style={{height: "unset"}}>
        {includeUserProfile && (
          <UserProfile
            buttonName={text.moreInfoName}
            avatarUrl={avatarUrl}
            userRoleName={userRoleName}
            includeMoreInfo={includeMoreInfo}
            popUpOpen={isOpen}
            setPopUpOpen={setIsOpen}
          />)}

        <form onSubmit={handleSubmit(onSubmit)}>
          {name}
          {referenceId}
          {payer}
          {newAddressForm}
          {includeCompanyName ? (
            <>
              <FormLabel
                label={companyLabel}
                errors={errors}
                fieldId={"companyName"}
              />
              {companyTooltipText && <Tooltip id={"companyTooltip"} style={{backgroundColor: '#169ee3'}}/>}
              <input
                type="text"
                {...(companyTooltipText && {
                  'data-tooltip-id': 'companyTooltip',
                  'data-tooltip-content': companyTooltipText,
                  'data-tooltip-place': (isDesktop ? "right" : "top"),
                  'data-tooltip-variant': 'info',
                })}
                {...register('companyName', {
                  required: {
                    value: true,
                    message: text.requiredFieldError,
                  },
                  maxLength: {value: 50, message: text.inputTooLongError},
                  pattern: {
                    value: /^[a-zA-Z0-9&\- ]+$/,
                    message: "Allowed characters A-Z,a-z,0-9,&,-"
                  },
                })}
              />
            </>
          ) : null}


          {/* If includePhone is true, then display the phone field only. Otherwise, display the
         email field. */}
          {includePhone ? (
            <>
              <div className="form-text-container">
                <label>{text.countryCode}</label>
                <ErrorMessage
                  errors={errors}
                  name="countryCode"
                  as={<ErrorMessageContainer/>}
                />
              </div>
              <input
                type="text"
                placeholder="1"
                {...register('countryCode', {
                  required: {
                    value: true,
                    message: text.requiredFieldError,
                  },
                  // Use regex string to validate country code
                  pattern: {
                    value: /^([+]?\d+)$/,
                    message: text.invalidCountryCodeError,
                  },
                })}
              />
              <div className="form-text-container">
                <label>{text.phoneNumber}</label>
                <ErrorMessage
                  errors={errors}
                  name="phoneNumber"
                  as={<ErrorMessageContainer/>}
                />
              </div>
              <input
                type="tel"
                placeholder={text.phonePlaceholder}
                {...register('phoneNumber', {
                  required: {
                    value: true,
                    message: text.requiredFieldError,
                  },
                  // Use regex string to validate phone number
                  pattern: {
                    value: /^(\d+-?)+\d+$/,
                    message: text.invalidPhoneNumberError,
                  },
                  maxLength: {
                    value: 11,
                    message: text.invalidPhoneNumberError,
                  },
                  minLength: {
                    value: 8,
                    message: text.invalidPhoneNumberError,
                  },
                })}
              />
            </>
          ) : (
            <>
              <FormLabel
                label={emailLabel || text.email}
                errors={errors}
                fieldId={"signerEmail"}
              />
              {emailTooltipText && <Tooltip id={"emailTooltip"} style={{backgroundColor: '#169ee3'}}/>}
              <input
                type="text"
                {...(emailTooltipText && {
                  'data-tooltip-id': 'emailTooltip',
                  'data-tooltip-content': emailTooltipText,
                  'data-tooltip-place': isDesktop ? 'right' : 'top',
                  'data-tooltip-variant': 'info',
                })}
                {...register('signerEmail', {
                  required: {
                    value: true,
                    message: text.requiredFieldError,
                  },
                  // Use regex string to validate email
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: text.invalidEmailFormatError,
                  },
                })}
              />
            </>
          )}
          {tin}
          {address}
          {percentage}
          <div className="button-container">
            <button className="black-button" type="submit" disabled={submitted}>
              {submitted ? text.submittedButtonName : text.buttonName}
            </button>
            {includeCancelButton && (<button
              type={"button"}
              className="grey-button"
              disabled={submitted}
              onClick={
                onCancelOverride || (() => {
                  navigate('/index')
                })
              }
            >
              {text.cancelButtonName}
            </button>)}
          </div>
          {includeTOS && <div className={"mt-2"}>
            <a target="_blank" href={"https://www.finexio.com/terms-of-service"}>General Terms of Service</a>
          </div>}
        </form>
      </div>
      {isOpen && (
        <Popup
          text={userFlowText}
          handleClose={() => {
            setIsOpen(!isOpen);
          }}
        />
      )}
    </>
  );
}

export default Form;

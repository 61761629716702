import {useForm} from "react-hook-form";
import React, {useState} from "react";
import SingleFormField from "./SingleFormField";

function BankingForm({text, onSubmit, onCancel, submitted, firstPage, backButton = true, individual = false, accountTypePicker = true}) {
  const [sameOwner, setSameOwner] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      accountType: individual ? 'individual' : 'business'
    }
  });

  function checkboxClick() {
    if (!sameOwner) {
        setValue('accountOwnerBusinessName', firstPage.companyName, {shouldValidate: true});
        setValue("accountOwnerFirstName", firstPage.firstName, {shouldValidate: true});
        setValue("accountOwnerLastName", firstPage.lastName, {shouldValidate: true});
    }
    setSameOwner(v => !v);
  }

  return (
    <>
      <p className={"form-note"} style={{gridRow: "2", paddingBottom: "1rem"}}>
        <span className={"font-bold"}>Please Note:</span> Please ensure the company or individual name you provide
        aligns with the one given by your bank. This step, mandated by our risk and compliance program and financial
        institutions, streamlines the process. Non-matching names may result in a manual review, potentially delaying
        your request.
      </p>
      <div className="form-holder center-form" style={{gridRow: "3", height: "unset", justifyContent: 'flex-start'}}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset>
            <legend>Account Information</legend>
            <SingleFormField
              fieldId={"accountNumber"}
              label={"New Account Number"}
              register={register}
              errors={errors}
              inputType={"number"}
            />
            <SingleFormField
              fieldId={"routingNumber"}
              label={"New Routing Number"}
              register={register}
              errors={errors}
              inputType={"number"}
              validate={(v) => (v && v.length === 9) || "9 digits required"}
            />
          </fieldset>

          {accountTypePicker &&
          <fieldset>
            <legend>Account Type</legend>
            <div className={"two-cols mb-2"}>
              <div className={"centered-flex"}>
                <input type={"radio"}
                       value={"business"}
                       className={"reset-width"}
                       id={"accountTypeBusiness"}
                       {...register("accountType")}
                />
                <label htmlFor={'accountTypeBusiness'}
                       className={"no-asterisk-label ml-1"}
                 >
                  Business
                </label>
              </div>
              <div className={"centered-flex"}>
                <input type={"radio"}
                       value={"individual"}
                       className={"reset-width"}
                       id={"accountTypeIndividual"}
                       {...register("accountType")}
                />
                <label htmlFor={'accountTypeIndividual'}
                       className={"no-asterisk-label ml-1"}
                >
                  Individual
                </label>
              </div>
            </div>
          </fieldset>
          }
          <fieldset>
            <legend>Account Owner</legend>
            {watch("accountType") === "individual" && <>
              <SingleFormField
                fieldId={"accountOwnerFirstName"}
                label={"First Name"}
                register={register}
                errors={errors}
                disabled={sameOwner}
              />
              <SingleFormField
                fieldId={"accountOwnerLastName"}
                label={"Last Name"}
                register={register}
                errors={errors}
                disabled={sameOwner}
              />
              </>
            }
            {watch("accountType") === "business" && <>
              <SingleFormField
                fieldId={"accountOwnerBusinessName"}
                label={"Business Name"}
                register={register}
                errors={errors}
                disabled={sameOwner}
              />
            </>}
           </fieldset>

          <div style={{display: "flex", alignItems: "center", marginBottom: "25px"}}>
            <input type={"checkbox"} style={{width: "auto", margin: ".5rem 0"}} checked={sameOwner}
                   onChange={checkboxClick}>
            </input>
            <label style={{marginLeft: "0.5rem", flexBasis: "auto"}} className={"no-asterisk-label"}>
              Same as previously entered
            </label>
          </div>

          <div className="button-container">
            <button className="black-button" type="submit" disabled={submitted}>
              {submitted ? "Sending..." : ((text && text.buttonName) || "Submit")}
            </button>
            {backButton && (<button className="grey-button" onClick={onCancel}>Back</button>)}
          </div>
        </form>
      </div>
      </>);
}

export default BankingForm;
import {Outlet, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";

function MagicLinkProtectedPage() {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios.post('/formAuthorized', {key: searchParams.get('key')});
        setLoading(false);
      } catch (e) {
        navigate('/error', {state: e.response.data});
      }
    }
    fetchData();
  }, [navigate, searchParams, setLoading]);

  return !loading && <Outlet />;
}

export default MagicLinkProtectedPage;
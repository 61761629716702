import {useState} from "react";
import axios from "axios";
import {useForm} from "react-hook-form";

function EmailSearch() {
  const [loading, setLoading] = useState();
  const [response, setResponse] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const {register, handleSubmit} = useForm();
  const PAGE_SIZE = 5;

  async function searchEmails(event) {
    setResponse(null);
    if (event.recipient) {
      setLoading(true);
      try {
        const apiResponse = await axios.get('/emails/search', {params: {
          to: event.recipient
        }});

        setLoading(false);
        setResponse(apiResponse.data);
      } catch (e) {
        console.log('searchEmail error', e);
      }
    }
  }
  const startIdx = (currentPage - 1) * PAGE_SIZE;
  const numPages = response && Math.ceil(response.messages.length / PAGE_SIZE);
  const responsesElements = response && (
      response.messages.slice(startIdx, startIdx + PAGE_SIZE).map((el, idx) => <div key={idx} className={"search-email-result"}>
        <p style={{fontSize: '1rem', fontWeight: '700', marginBlockEnd: '0.5em'}}>{idx + startIdx + 1}. {el.subject}</p>
        <span>
          <strong style={{textTransform: 'capitalize'}}>{el.status}</strong> at {new Date(el.last_event_time).toLocaleString('en-US', {timeZone: 'America/New_York'})},
          <strong>{el.opens_count > 0 ? 'Opened' : 'Not Opened'}</strong>
        </span>
      </div>)
  );

  return <section className={"content-section"}>
    <div className={"container"}>
      <div className={"header-container"}>
        <h1>Email Search</h1>
        <div className={"form-width-container"}>
          <form onSubmit={handleSubmit(searchEmails)}>
            <label htmlFor={"recipient"} className={'standalone-input-label'}>Recipient Email:</label>
            <div style={{display: 'flex'}}>
              <input id="recipient" type={'email'} {...register('recipient')} className={'standalone-input'} style={{height: '45px', display: 'block', flexGrow: '1'}}/>
              <button className={"black-button"} disabled={loading} style={{marginLeft: '1rem', display: 'block', width: '100px'}}>
                {loading ? "Loading" : "Search"}
              </button>
            </div>

          </form>
        </div>
        {response &&
          <>
            <div className={"form-holder"} style={{height: "unset"}}>
              {responsesElements}
            </div>
            <div className={"form-width-container"} style={{display: "flex", alignItems: 'center', justifyContent: 'space-between'}}>
              <button onClick={() => setCurrentPage(page => page - 1)} disabled={currentPage === 1} className={"black-button"} style={{display: 'block'}}>Previous</button>
              <div style={{margin: "0 2rem"}}>
                <strong style={{display: "block"}}>Page<br/>{currentPage}/{numPages}</strong>
              </div>
              <button disabled={currentPage === numPages} className={"black-button"} style={{display: 'block'}} onClick={() => setCurrentPage(page => page + 1)}>Next</button>
            </div>
          </>
        }
      </div>
    </div>
  </section>;
}

export default EmailSearch;
function DueDiligencePopup({onClose}) {
  return <div className={"popup"}>
    <div className={"box"} style={{padding: "2rem 3rem"}}>
      <h2 className={"color-primary"} style={{margin: '0'}}>Finexio Client Due Diligence</h2>
      <p>Finexio is required by government rules and regulations, and by its sponsor banks, to complete due diligence on
        its clients. For U.S programs, the bank must comply with the Bank Secrecy Act, USA PATRIOT Act, and FinCEN CDD
        Rule. For Canadian Programs, the bank must comply with the Canadian Proceeds of Crime (Money Laundering) and
        Terrorist Financing Act. These Laws were put into place to prevent money laundering and combat terrorism.</p>

      <p>For more information on these regulations and requirements please visit this <a
        href={"https://www.fincen.gov/resources/statutes-and-regulations/cdd-final-rule"}>link</a>.</p>

      <button onClick={onClose} className={"button--base button__primary"} style={{width: "40%"}}>
        Close
      </button>
    </div>
  </div>;
}

export default DueDiligencePopup;
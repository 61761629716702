import {useForm} from "react-hook-form";
import SingleFormField from "./SingleFormField";
import FormattedField from "./FormattedField";
import * as EmailValidator from "email-validator";
import {useState} from "react";

function ExpressBusinessAndBankingForm({onSubmit, onBack, sending, companyName}) {
  const [sameOwner, setSameOwner] = useState(false);

  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    setValue,
  } = useForm();
  function checkboxClick() {
    setSameOwner(v => {
      if (!v) {
        setValue("accountOwnerBusinessName", companyName);
      }
      return !v;
    });
  }
  return <form className={"plain-form"} onSubmit={handleSubmit(onSubmit)}>
    <fieldset>
      <legend>Business Relationship</legend>
      <SingleFormField
        fieldId={"businessRelPayor"}
        label={"Name of your Customer"}
        register={register}
        errors={errors}
      />
      <SingleFormField
        fieldId={"businessRelContact"}
        label={"Contact Name"}
        register={register}
        errors={errors}
      />
      <div className={"two-cols--dynamic"}>
        <div>
          <SingleFormField
            fieldId={"businessRelEmail"}
            label={"Email"}
            register={register}
            errors={errors}
            validate={(v) => EmailValidator.validate(v) || 'Invalid email address' }
          />
        </div>
        <div>
          <FormattedField
            control={control}
            fieldId={"businessRelPhoneNumber"}
            format={"+1 (###)-###-####"}
            errors={errors}
            label={"Phone Number"}
          />
        </div>
      </div>
    </fieldset>
    <fieldset>
      <legend>Banking Details</legend>
      <div className={"two-cols--dynamic"}>
        <div>
          <SingleFormField
            fieldId={"accountNumber"}
            label={"Account Number"}
            register={register}
            errors={errors}
            inputType={"number"}
          />
        </div>
        <div>
          <SingleFormField
            fieldId={"routingNumber"}
            label={"Routing Number"}
            register={register}
            errors={errors}
            inputType={"number"}
            validate={(v) => (v && v.length === 9) || "9 digits needed"}
          />
        </div>
      </div>
      <div className={"two-cols--dynamic"} style={{marginBottom: "1rem"}}>
        <div>
          <SingleFormField
            fieldId={"accountOwnerBusinessName"}
            label={"Account Holder Name"}
            register={register}
            errors={errors}
            disabled={sameOwner}
          />
        </div>
        <div>
          <SingleFormField
            fieldId={"emailNotifications"}
            label={"Email Notifications"}
            register={register}
            errors={errors}
            required={false}
            validate={(v) => (!v || EmailValidator.validate(v)) || 'Invalid Email'}
          />
        </div>
      </div>
      <div className={"mb-2 centered-flex"}>
        <input type={"checkbox"} className={"reset-width"} checked={sameOwner}
               onChange={checkboxClick}>
        </input>
        <label style={{marginLeft: "0.5rem", flexBasis: "auto"}} className={"no-asterisk-label"}>
          Same holder as previously entered
        </label>
      </div>
    </fieldset>
    <div className={"two-cols"}>
      <button className={"button--base button__primary"} disabled={sending} type={'submit'} style={{width: "75%"}}>
        {sending ? 'Sending...' : 'Send'}
      </button>
      <button className={"button--base button__secondary"} disabled={sending} type={"button"} onClick={onBack}
              style={{width: "75%"}}>
        Back
      </button>
    </div>
  </form>;
}

export default ExpressBusinessAndBankingForm;
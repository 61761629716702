import {useForm} from "react-hook-form";
import SingleFormField from "./SingleFormField";
import {useState} from "react";
import {sendRequest} from "../api/apiHelper";
import ResultFormField from "./ResultFormField";

function USMailingAddressCheck() {
  const [requesting, setRequesting] = useState();
  const [requestError, setRequestError] = useState();
  const [response, setResponse] = useState();
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();

  const submitForm = async (data) => {
    try {
      setRequesting(true);
      const apiResponse = await sendRequest("internalTools/checkUSAMailingAddress", data);
      setResponse(apiResponse.data);
      setRequesting(false);
    } catch (e) {
      setRequestError(true);
      setRequesting();
      console.error("Request failed", e);
    }
  };

  let resultContent;
  if (requestError) {
    resultContent = <p className={"error-label"}>Request Failed. See console for errors.</p>;
  } else if (response) {
    if (response.valid_address) {
      resultContent = <>
        <p>Address is valid</p>
        <ResultFormField fieldId={"outAddress1"} fieldValue={response.primary_line} label={"Primary Line"} />
        <ResultFormField fieldId={"outAddress2"} fieldValue={response.secondary_line} label={"Secondary Line"} />
        <ResultFormField fieldId={"outAddress3"} fieldValue={response.last_line} label={"Last Line"} />
      </>;
    } else {
      resultContent = <p className={"error-label"}>Address is not valid</p>;
    }
  }

  return <>
    <form className={"plain-form"} onSubmit={handleSubmit(submitForm)}>
      <SingleFormField fieldId={"address1"} label={"Address 1"} errors={errors} register={register}/>
      <SingleFormField fieldId={"address2"} label={"Address 2"} errors={errors} register={register} required={false}/>
      <SingleFormField fieldId={"city"} label={"City"} errors={errors} register={register}/>
      <SingleFormField fieldId={"state"} label={"State"} errors={errors} register={register}/>
      <SingleFormField fieldId={"zipCode"} label={"Zip Code"} errors={errors} register={register}/>
      <div className={"button-container"}>
        <button
          className={"black-button"}
          type={"submit"}
          disabled={requesting}
        >
          Submit
        </button>
        <button
          type={"button"}
          className={"grey-button"}
          onClick={() => {
            setRequesting(false);
            setRequestError();
            setResponse();
            reset();
          }}
        >
          Reset
        </button>
      </div>
    </form>
    {resultContent && <div style={{marginTop: "2rem"}}>{resultContent}</div>}
  </>;
}

export default USMailingAddressCheck;
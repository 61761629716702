import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import BuyerOnboardingBankingForm from "../components/BuyerOnboardingBankingForm";
import axios from "axios";
import {handleError, sendRequest} from "../api/apiHelper";
import {getAttachmentName, readFileAsBase64} from "../utils/fileUpload";
import BuyerOnboardingBankingPopup from "../components/BuyerOnboardingBankingPopup";
import CirclesProgress from "../components/CirclesProgress";
import {AsyncPageType} from "./AsyncPageStart";

function ResumeBuyerOnboarding() {
  const {id} = useParams();
  const [formData, setFormData] = useState();
  let navigate = useNavigate();
  const [initialLoading, setInitialLoading] = useState(true);
  const [requesting, setRequesting] = useState();
  const [docusignResponse, setDocusignResponse] = useState();
  const [pendingRequest, setPendingRequest] = useState();
  const [popupSending, setPopupSending] = useState();
  const [accountsInvalid, setAccountsInvalid] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/asyncForm/resume/${id}`);
        console.log('formData', response.data);
        setFormData(response.data);
        setInitialLoading(false);
      } catch (e) {
        navigate('/error', {state: {title: "Invalid URL", description: ""}});
      }
    }
    fetchData();
  }, [id, navigate]);

  async function handleSubmit(data) {
    try {
      const body = {
        accountNumber: data.accountNumber,
        routingNumber: data.routingNumber,
        accountOwnerBusinessName: data.accountOwnerBusinessName,
        fundingEmailNotifications: data.fundingEmailNotifications,
        accountType: 'business',
        asyncFormId: id,
        signerEmail: formData.signerEmail,
        company: formData.companyName,
        financialInstitution: data.financialInstitution,
        page: formData.page,
        fundingMethod: data.fundingMethod,
        refundingAccount: data.refundingAccount,
        refundingAccountNumber: data.refundingAccountNumber,
        refundingRoutingNumber: data.refundingRoutingNumber,
        refundingAccountOwnerBusinessName: data.refundingAccountOwnerBusinessName,
        refundingEmailNotifications: data.refundingEmailNotifications,
      };
      setRequesting(true);
      const accountChecksFutures = [
        sendRequest('/check/avs', {
          accountNumber: data.accountNumber,
          routingNumber: data.routingNumber,
          accountType: 'business',
          accountOwnerBusinessName: data.accountOwnerBusinessName,
        })
      ];
      if (data.refundingAccount === 'refundingDifferent') {
        console.log('checking refunding account');
        accountChecksFutures.push(
          sendRequest('/check/avs', {
            accountNumber: data.refundingAccountNumber,
            routingNumber: data.refundingRoutingNumber,
            accountType: 'business',
            accountOwnerBusinessName: data.refundingAccountOwnerBusinessName,
          })
        );
      }
      const accountChecksResponses = await Promise.all(accountChecksFutures);
      const fundingIsInvalid = !accountChecksResponses[0].data.isValid;
      const refundingIsInvalid = accountChecksResponses.length > 1 && !accountChecksResponses[1].data.isValid;
      console.log('fundingIsInvalid %s refundingIsInvalid %s', fundingIsInvalid, refundingIsInvalid);

      if (fundingIsInvalid || refundingIsInvalid) {
        setRequesting(false);
        setAccountsInvalid({
          fundingIsInvalid,
          refundingIsInvalid,
        });
        setPendingRequest(body);
      } else {
        const apiResponse = await sendRequest('/banking/createFromPDF', body);
        setDocusignResponse(apiResponse.data);
      }
    } catch (error) {
      const errorPageText = handleError(error);
      navigate('/error', {state: errorPageText});
    }
  }

  async function sendFromPopup(data) {
    try {
      setPopupSending(true);
      const attachments = [];
      if (accountsInvalid.fundingIsInvalid) {
        attachments.push({
          dataB64: await readFileAsBase64(data.fundingDoc[0]),
          fileName: getAttachmentName(data.fundingDoc[0].type, ' - Funding Acct'),
        })
      }
      if (accountsInvalid.refundingIsInvalid) {
        attachments.push({
          dataB64: await readFileAsBase64(data.refundingDoc[0]),
          fileName: getAttachmentName(data.refundingDoc[0].type, ' - Refunding Acct'),
        })
      }
      const apiResponse = await sendRequest('/banking/createFromPDF', {
        ...pendingRequest,
        attachments,
      });
      setDocusignResponse(apiResponse.data);
    } catch (error) {
      const errorPageText = handleError(error);
      navigate('/error', {state: errorPageText});
    }
  }

  if (!formData) {
    return <h1 style={{textAlign: "center"}}>Loading</h1>;
  }
  if (docusignResponse) {
    window.location.href = docusignResponse.url;
    return <h1 style={{textAlign: "center"}}>Loading</h1>;
  }
  const whitelistSection = <div className={"p-2 fs-2"} style={{lineHeight: "1.5"}}>
    <p className={"m-0 font-bold"}>Please Note</p>
    <p style={{margin: "0.5rem 0"}}>
      Finexio will need to be whitelisted with your bank to be able to process your payments.
    </p>
    <p className={"m-0"}>
      Learn more on whitelisting Finexio <a
      href={"https://info.finexio.com/hubfs/Finexio%20Whitelisting%20Guide.docx.pdf"}
      target="_blank"
    >
      here
    </a>
    </p>
  </div>;
  const isBuyerOnboardingV2 = formData?.page === AsyncPageType.BUYER_ONBOARDING_V2;
  return (
    <section className="content-section">
      <div className="container--block" style={{maxWidth: "800px"}}>
        {formData &&
          <h1
            className={"fs-7 text-center mb-6"}>{formData.page === AsyncPageType.BUYER_ONBOARDING_EXISTING_CLIENT ? 'Finexio Existing Client Due Diligence' : 'Finexio New Client Due Diligence'}</h1>}
        <CirclesProgress currentStep={isBuyerOnboardingV2 ? 4 : 3} stepLabels={[
          "Organization",
          "Authorized Signer",
          ...(isBuyerOnboardingV2 ? ['Point(s) of Contact'] : []),
          "Banking"]}/>
        <div className={"shadowbox"}>
          {initialLoading ? <h3>Loading</h3> :
            <BuyerOnboardingBankingForm
              onFinish={handleSubmit}
              requesting={requesting}
              companyName={formData.companyName}
              isExistingClient={formData.page === AsyncPageType.BUYER_ONBOARDING_EXISTING_CLIENT}
            />
          }
        </div>
        <div className={"info-box"} style={{marginTop: "2rem"}}>
          {whitelistSection}
        </div>
      </div>
      {(accountsInvalid.fundingIsInvalid || accountsInvalid.refundingIsInvalid) && <BuyerOnboardingBankingPopup
        onCancel={() => setAccountsInvalid({})}
        onSubmit={sendFromPopup}
        sending={popupSending}
        {...accountsInvalid}
      />}
    </section>
  );
}

export default ResumeBuyerOnboarding;
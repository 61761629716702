import {Link} from "react-router-dom";

function InternalChecks() {
  return <section className={"content-section"}>
    <div className={"container"}>
      <div className={"header-container"}>
        <h1>Internal Checks</h1>
      </div>
      <div className={"form-holder"} style={{height: "unset"}}>
        <p>The Internal Checks have moved to the Internal Tools page</p>
        <p>Going forward, please use the page linked below</p>
        <Link to={"/internal-tools"}>/internal-tools</Link>
      </div>
    </div>
  </section>
}

export default InternalChecks;
import {Controller} from "react-hook-form";
import {PatternFormat} from "react-number-format";
import classNames from "classnames";
import {ErrorMessage} from "@hookform/error-message";

function FormattedField({control, fieldId, format, errors, label, required = true, disabled = false}) {
  const ErrorMessageContainer = ({ children }) => (
    <span className="error-label"> {children}</span>
  );

  return <>
    <div className={"form-text-container"}>
      <label htmlFor={fieldId} className={classNames({"no-asterisk-label": !required})}>{label}</label>
      <ErrorMessage
        errors={errors}
        name={fieldId}
        as={<ErrorMessageContainer/>}
      />
    </div>
    <Controller
      control={control}
      rules={{
        validate: (v) => (v && !v.includes("_")) || "Required"
      }}
      render={({field}) =>
        <PatternFormat disabled={disabled} format={format} allowEmptyFormatting={true} mask={"_"} {...field} ref={null} />
      }
      name={fieldId}
    />
  </>;
}

export default FormattedField;
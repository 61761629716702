import {FlatfileProvider, useFlatfile, useListener, Workbook} from "@flatfile/react";
import api from "@flatfile/api";
import { workbook } from "../configs/flatfile";
import {recordHook} from "@flatfile/plugin-record-hook";

function WorkbookConfig() {
  const { openPortal } = useFlatfile();
  useListener((listener) => {
    listener.on("job:ready", async (event) => {
      console.log("event", event);
      const { context } = event;
      const {jobId, workbookId} = context;
      const { data: workbookSheets } = await api.sheets.list({ workbookId });
      console.log("workbookSheets", workbookSheets);
      const sheetId = workbookSheets[0].id;
      console.log('sheetId', sheetId);
      await api.records.insert(sheetId, [{
        buyerName: {value: "b-1"},
      }]);
      await api.jobs.fail(jobId, {
        outcome: {
          acknowledge: true,
          message: "Please fix the validation errors and resubmit. You can find a downloadable list of errors in the \"Data Errors\" sheet",
          heading: "Failed",
        },
      });
    })
    listener.use(
      recordHook("sheet", (record) => {
        const allowedCharacters = /^[a-zA-Z0-9 .\-_]+$/;
        record.validate(
          "buyerId",
          (value) => typeof(value) === "string" && allowedCharacters.test(value),
          "Allowed characters: letters, digits, space, -, _, .",
        );
      })
    )
  });

  return <>
    <button onClick={openPortal}>Open Portal</button>
    <Workbook
      config={workbook}
    />
  </>;
}

export default function TestSupplier() {
  return <FlatfileProvider publishableKey={process.env.REACT_APP_FLATFILE_PUBLISHABLE_KEY}>
    <WorkbookConfig />
  </FlatfileProvider>;
}
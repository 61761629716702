import {ReactComponent as Checkmark} from '../assets/images/check.svg'
import {useNavigate} from "react-router-dom";

function BuyerOnboardingSuccess({ existingClient }) {
  let navigate = useNavigate();

  const newForm = () => {
    navigate(existingClient ? '/buyer-onboarding-existing-client' : '/buyer-onboarding');
  };
  return <section className={"content-section"}>
    <div className={"container--block"}>
      <div className={"shadowbox success--container__buyer-onboarding"} >
        <div className={"checkmark"}>
          <Checkmark  alt={"checkmark"}/>
        </div>
        <h1 style={{textAlign: "center"}}>Thank You!</h1>
        <p>We have received your due diligence form. We will review it and contact you if we require any further
          information.</p>
        <p>If you have any questions please contact your account manager or visit us <a href={"https://support.finexio.com"}>here</a></p>
        <p>- Team Finexio</p>
        <p className={"mt-5 text-center"}>Need to submit another form?</p>
        <div className={"display-flex justify-center"}>
          <button onClick={newForm} className={"button--base button__primary"} style={{width: "40%"}}>Click Here</button>
        </div>
      </div>
    </div>
  </section>;
}

export default BuyerOnboardingSuccess;
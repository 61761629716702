import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {ReactComponent as Logo} from '../assets/images/logo.svg'

function Header() {
  let location = useLocation();

  return (
    <header className="header" role="banner">
      <nav className="navbar">
        {location.pathname !== '/' ? (
          <>
            <Link className="nav-logo" to="/index">
              <Logo alt="logo" />
            </Link>
          </>
        ) : (
          <div className="nav-logo">
            <Logo alt="logo" />
          </div>
        )}
      </nav>
    </header>
  );
}

export default Header;

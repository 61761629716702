import {ErrorMessage} from "@hookform/error-message";
import React from "react";
import classNames from "classnames";

function SingleFormField({fieldId, label, register, errors, required = true, inputType = "text", disabled = false, validate = () => true}) {
  const ErrorMessageContainer = ({ children }) => (
    <span className="error-label"> {children}</span>
  );

  return <>
    <div className={"form-text-container"} style={{justifyContent: "space-between"}}>
      <label htmlFor={fieldId} className={classNames({"no-asterisk-label": !required})}>{label}</label>
      <ErrorMessage
        errors={errors}
        name={fieldId}
        as={<ErrorMessageContainer />}
      />
    </div>
    <input
        type={inputType}
        disabled={disabled}
        id={fieldId}
        className={classNames({"no-spinners": inputType === "number"})}
        {...register(fieldId, {
          required: {value: required, message: "Required"},
          validate,
        })}
    />
  </>;
}

export default SingleFormField;
import PersonalInfoFields from "./PersonalInfoFields";
import {useForm} from "react-hook-form";
import {Fragment, useState} from "react";
import YesNoRequiredSelect from "../YesNoRequiredSelect";

function PointsOfContact({onBack, authorizedSignerData, onNext, requesting}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    unregister,
    setValue,
  } = useForm();
  const [additional, setAdditional] = useState(0);
  const [sameAsSigner, setSameAsSigner] = useState(false);
  const checkboxClick = () => {
    setSameAsSigner((v) => {
      if (!v) {
        setValue('main.firstName', authorizedSignerData.firstName);
        setValue('main.lastName', authorizedSignerData.lastName);
        setValue('main.email', authorizedSignerData.email);
        setValue('main.phoneNumber', authorizedSignerData.phoneNumber);
        setValue('main.receiveFundingNotifs', authorizedSignerData.fundingNotifs);
        setValue('main.receiveRefundNotifs', authorizedSignerData.refundNotifs);
      }
      return !v;
    });
  };

  const addContact = () => {
    if (additional < 10) {
      setAdditional(prev => prev + 1);
    }
  };
  const removeLast = () => {
    if (additional > 0) {
      unregister(`additional[${additional - 1}]`);
      setAdditional(prev => prev - 1);
    }
  };

  return <div className={"buyer-onboarding"}>
    <h2 className={"mt-0"}>Point(s) of Contact</h2>
    <p>Provide information for the point of contact that will need to receive funding and/or refund notifications.</p>
    <form className={"plain-form"} onSubmit={handleSubmit(onNext)}>
      <PersonalInfoFields errors={errors} register={register} control={control} prefix={'main.'}
                          disabled={sameAsSigner}/>
      <YesNoRequiredSelect disabled={sameAsSigner} label={"Receive funding notifications?"} fieldId={"main.receiveFundingNotifs"}
                           register={register} errors={errors}/>
      <YesNoRequiredSelect disabled={sameAsSigner} label={"Receive refund notifications?"} fieldId={"main.receiveRefundNotifs"}
                           register={register} errors={errors}/>
      <div className={"mb-1 centered-flex"}>
        <input type={"checkbox"} className={"reset-width"} checked={sameAsSigner}
               onChange={checkboxClick}>
        </input>
        <label style={{marginLeft: "0.5rem", flexBasis: "auto"}} className={"no-asterisk-label"}>
          Same as authorized signer
        </label>
      </div>
      {[...Array(additional).keys()].map((key) => <Fragment key={key}>
          <hr/>
          <PersonalInfoFields errors={errors} register={register} control={control} prefix={`additional[${key}].`}/>
          <YesNoRequiredSelect label={"Receive funding notifications?"}
                               fieldId={`additional[${key}].receiveFundingNotifs`} register={register} errors={errors}/>
          <YesNoRequiredSelect label={"Receive refund notifications?"} fieldId={`additional[${key}].receiveRefundNotifs`}
                               register={register} errors={errors}/>
        </Fragment>
      )}
      <div className={"display-flex align-center"} style={{marginBottom: '2.5rem'}}>
        <div className={"flex-grow pr-2"}>
          <hr/>
        </div>
        <button className={"btn-outline px-2"} type={"button"} onClick={addContact}>Add Contact</button>
        {additional > 0 &&
          <>
            <div className={"flex-grow px-2"}>
              <hr/>
            </div>
            <button className={"btn-outline px-2"} type={"button"} onClick={removeLast}>Remove Last</button>
          </>
        }
        <div className={"flex-grow pl-2"}>
          <hr/>
        </div>
      </div>
      <div className={"two-cols"}>
        <button className={"button--base button__primary"} disabled={requesting} type={"submit"}>Next</button>
        <button className={"button--base button__secondary"} type={"button"} disabled={requesting}
                onClick={onBack}>Back
        </button>
      </div>
    </form>
  </div>
}

export default PointsOfContact;
import axios from "axios";
import  {useState} from "react";
import useInterval from "./useInterval";

function TinStatusPoll({requestId, onFinished}) {
  const [delay, setDelay] = useState(3000);
  const [pollsLeft, setPollsLeft] = useState(5);
  const [tinResponse, setTinResponse] = useState();

  useInterval(async () => {
    try {
      const response = await axios.get('/check/tinCheckStatus/' + requestId);
      if (response.data.finished) {
        setDelay(null);
        onFinished();
        setTinResponse(response.data);
      }
      if (pollsLeft === 1) {
        setDelay(null);
        onFinished();
        setPollsLeft(0);
      } else {
        setPollsLeft(l => l - 1);
      }
    } catch (e) {
      console.log('TinStatusPoll error', e);
    }
  }, delay);

  return <div style={{marginTop: "1rem"}}>
      {tinResponse ?
        <>
          <label htmlFor={"result"} className={"no-asterisk-label"} style={{marginTop: '1rem'}}>Result</label>
          <textarea
            rows={16}
            disabled
            id={"result"}
            value={JSON.stringify(tinResponse.fullResponse, null, 2)}
            style={{resize: "none"}}
          />
        </> :
        (pollsLeft > 0 ? <p>Waiting on response from the API</p> : <p>Time Out</p>)}
  </div>;
}

export default TinStatusPoll;
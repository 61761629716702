import {useForm} from "react-hook-form";
import FormLabel from "../components/FormLabel";
import * as Papa from "papaparse"
import {useCallback, useEffect, useMemo, useState} from "react";
import SingleFormField from "../components/SingleFormField";
import { CSVLink } from "react-csv";
import {readFileAsBase64} from "../utils/fileUpload";
import axios from "axios";
import Success from "./Success";
import {useNavigate} from "react-router-dom";

const expectedHeaders = [
  'Buyer Name',
  'Buyer Id',
  'Buyer Account Number',
  'Supplier Name',
  'Supplier Id',
  'Supplier Address 1',
  'Supplier Address 2',
  'Supplier Remit City',
  'Supplier Remit State',
  'Supplier Remit Zip',
  'Supplier Remit Country',
  'Supplier Remit Email',
  'Payment Method',
  'Disbursement Account Number',
  'Disbursement Routing Number',
  'Payment Notes',
  'Twelve Month Dollar Spend',
  'Twelve Month Payment Count',
  'Client Reported Payment Terms',
  'Supplier Contact Name',
  'Supplier Phone',
  'Supplier Email',
  'Supplier Tax Id',
  'Payment Category',
];

const allowedCharacters = /^[a-zA-Z0-9 .\-_]+$/;
const stateCodeRegexp = /^[A-Z]{2}$/;
const zipCodeRegexp = /^[0-9]{5}(-[0-9]{4})?$/;
const countryRegexp= /^[A-Z]{3}$/;
const emailRegexp = /^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+(\.[a-zA-Z]{2,4}){1,2}$/;
const accountNumberRegexp = /^[0-9]{5,17}$/;
const routingNumberRegexp = /^[0-9]{9}$/;
const phoneRegexp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const tinRegexp = /^[0-9]{2}-?[0-9]{7}$/;

function dataErrorFrequencies(dataErrors) {
  const frequencies = {};
  for (const error of dataErrors) {
    const description = `${error.column} - ${error.errorDescription}`;
    frequencies[description] = (frequencies[description] || 0) + 1;
  }
  const ret = Object.entries(frequencies);
  ret.sort((a, b) => b[1] - a[1]);
  return ret;
}

function validateSupplierData(parsedCsv) {
  const ret = {
    rows: parsedCsv.data.length,
    errors: [],
    optionalErrors: [],
  };
  let i;
  for (i = 0; i < expectedHeaders.length; i++) {
    const col = parsedCsv.data[0][i] || '';
    if (col.trim().length === 0) {
      ret.errors.push({lineNumber: 1, column: expectedHeaders[i], fieldValue: "", errorDescription: `Missing header ${expectedHeaders[i]}`});
    } else if (col !== expectedHeaders[i]) {
      ret.errors.push({lineNumber: 1, column: expectedHeaders[i], fieldValue: "", errorDescription: `Unexpected header ${col}`});
    }
  }
  if (ret.errors.length > 0) {
    return ret;
  }
  let line;
  for (line = 1; line < parsedCsv.data.length; line++) {
    if (!parsedCsv.data[line].find(f => f.trim().length > 0)) {
      continue;
    }

    const checkField = (colIdx, fieldName, validateFn, message, isOptional) => {
      const fieldVal = parsedCsv.data[line][colIdx] || '';
      if (!validateFn(fieldVal)) {
        const error = {
          lineNumber: line + 1,
          column: fieldName,
          fieldValue: fieldVal,
          errorDescription: message,
          isOptional,
        };
        ret.errors.push(error);
        return false;
      } else {
        return true;
      }
    };
    const checkFieldIsSet = (colIdx, fieldName, isOptional = false) => checkField(colIdx, fieldName, (f) => f.trim().length > 0, (isOptional ? "Recommended" : "Required") + " field is not set", isOptional);
    const checkFieldRegexp = (colIdx, fieldName, regexp, message, isOptional = false) => checkField(colIdx, fieldName, (f) => regexp.test(f), message, isOptional);
    const checkFieldLength = (colIdx, fieldName, maxLength, isOptional) => checkField(colIdx, fieldName, (f) => f.length <= maxLength, `Field exceeds the maximum length of ${maxLength} characters`, isOptional);
    const checkEnumField = (colIdx, fieldName, enumOptions) => checkField(colIdx, fieldName, (f) => enumOptions.findIndex(eo => eo.toLowerCase() === f.toLowerCase()) !== -1, `Invalid enum field option`, false);
    if (checkFieldIsSet(0, "Buyer Name")) {
      checkFieldLength(0, "Buyer Name", 40);
    }
    if (checkFieldIsSet(1, "Buyer Id")) {
      checkFieldLength(1, "Buyer Id", 100);
      checkFieldRegexp(1, "Buyer Id", allowedCharacters, "Invalid character. Allowed characters: letters, digits, space, -, _, .");
    }
    if (checkFieldIsSet(2, "Buyer Account Number", true)) {
      checkFieldLength(2, "Buyer Account Number", 100, true);
    }
    if (checkFieldIsSet(3, "Supplier Name")) {
      checkFieldLength(3, "Supplier Name", 40);
    }
    if (checkFieldIsSet(4, "Supplier Id")) {
      checkFieldLength(4, "Supplier Id", 100);
    }
    for (let j = 1; j < line; j++) {
      if (parsedCsv.data[line][4] === parsedCsv.data[j][4] && parsedCsv.data[line][3] !== parsedCsv.data[j][3]) {
        ret.errors.push({
          lineNumber: line + 1,
          column: 'Supplier Id',
          fieldValue: parsedCsv.data[line][4],
          errorDescription: `A supplier with the same id but different name was previously entered at row ${j}`,
          isOptional: false,
        });
        break;
      }
    }
    if (checkFieldIsSet(5, "Supplier Corporate Address Line 1")) {
      checkFieldLength(5, "Supplier Corporate Address Line 1", 40);
    }
    if (parsedCsv.data[line][6]) {
      checkFieldLength(6, "Supplier Corporate Address Line 2", 40);
    }
    if (checkFieldIsSet(7, "Supplier Corporate City")) {
      checkFieldLength(7, "Supplier Corporate City", 40);
    }
    if (checkFieldIsSet(8, "Supplier Remittance State")) {
      checkFieldRegexp(8, "Supplier Remittance State", stateCodeRegexp, "2 letter state code needed");
    }
    if (checkFieldIsSet(9, "Supplier Remittance Zip")) {
      checkFieldRegexp(9, "Supplier Remittance Zip", zipCodeRegexp, "expected format: ##### or #####-####");
    }
    if (checkFieldIsSet(10, "Supplier Remittance Country")) {
      checkFieldRegexp(10, "Supplier Remittance Country", countryRegexp, "3 letter country code needed");
    }
    (checkFieldIsSet(12, "Payment Method") &&
      checkEnumField(12, "Payment Method", ["Check", "ACH", "Wire", "Card"]));
    const paymentMethod = parsedCsv.data[line][12];
    const supplierRemitCountry = parsedCsv.data[line][10];
    if (paymentMethod === 'ACH' && supplierRemitCountry === "CAN") {
      (checkFieldIsSet(11, "Supplier Remittance Email Address") &&
        checkFieldLength(11, "Supplier Remittance Email Address", 50) &&
        checkFieldRegexp(11, "Supplier Remittance Email Address", emailRegexp, "A valid email address is needed"));
    }
    if (paymentMethod === 'ACH') {
      (checkFieldIsSet(13, "Disbursement Account Number") &&
       checkFieldRegexp(13, "Disbursement Account Number", accountNumberRegexp, "A 5-17 digit number is needed"));
      (checkFieldIsSet(14, "Disbursement Routing Number") &&
        checkFieldRegexp(14, "Disbursement Routing Number", routingNumberRegexp, "A 9 digit number is needed"));
    }
    checkFieldIsSet(16, "12 Month Dollar Spend", true);
    checkFieldIsSet(17, "12 Month Payment Count", true);
    (checkFieldIsSet(18, "Client Reported Payment Terms", true) &&
      checkFieldLength(18, "Client Reported Payment Terms", 50, true));
    (checkFieldIsSet(19, "Supplier Contact Name", true) &&
      checkFieldLength(19, "Supplier Contact Name", 40, true));
    (checkFieldIsSet(20, "Supplier Phone", true) &&
      checkFieldRegexp(20, "Supplier Phone", phoneRegexp, "A valid phone number is needed", true));
    (checkFieldIsSet(21, "Supplier Email Address", true) &&
      checkFieldLength(21, "Supplier Email Address", 50, true) &&
      checkFieldRegexp(21, "Supplier Email Address", emailRegexp, "A valid email address is needed", true));
    if (parsedCsv.data[line][22]) {
      checkFieldRegexp(22, "Supplier Tax ID", tinRegexp, "A valid email address is needed", true);
    }
    (checkFieldIsSet(23, "Payment Category") &&
      checkEnumField(23, "Payment Category", ["Individual", "Employee", "Internal Transfer", "Business", "Banks/Finance Companies", "Student"]));
  }
  // console.log('validation results', ret);
  return ret;
}

function FirstPage({ onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  return <form className={"plain-form"} onSubmit={handleSubmit(onSubmit)}>
    <h2 className={"mt-0 color-primary mb-2"}>Instructions</h2>
    <ul>
      <li>Please upload a CSV file containing your supplier data.</li>
      <li>Download the <a href={"./supplier data starter.csv"} download>Starter CSV</a> and append your data to the
        document while keeping the header row.
      </li>
      <li>For field specifications, please refer to the <a href={"./Supplier Validation Fields.pdf"} download>Supplier
        Validation Fields</a> document
      </li>
    </ul>
    <h3 className={"mb-1"}>Data</h3>
    <div className={"two-cols--dynamic"}>
      <div>
        <SingleFormField
          fieldId={"firstName"}
          label={"First Name"}
          register={register}
          errors={errors}
        />
      </div>
      <div>
        <SingleFormField
          fieldId={"lastName"}
          label={"Last Name"}
          register={register}
          errors={errors}
        />
      </div>
    </div>
    <SingleFormField
      fieldId={"orgName"}
      label={"Organization Legal Name"}
      register={register}
      errors={errors}
    />
    <FormLabel label={"Supplier Data"} errors={errors} fieldId={"supplierCsv"}/>
    <input type={"file"} {...register("supplierCsv", {validate: (fl) => fl.length > 0 || 'Required'})}
           accept={'text/csv'}/>
    <div className={"two-cols"}>
      <button className={"button--base button__primary"} style={{width: "75%"}} type={"submit"}>
        Validate
      </button>
      <button className={"button--base button__secondary"} type={"reset"}
              style={{width: "75%"}}>
        Back
      </button>
    </div>
  </form>;
}

function SecondPage({firstPage, onBack}) {
  const [validationResult, setValidationResult] = useState();
  const [sending, setSending] = useState();
  const [outcome, setOutcome] = useState();
  let navigate = useNavigate();


  useEffect(() => {
    Papa.parse(firstPage.supplierCsv[0], {
      complete: (parsed) => {
        setValidationResult(validateSupplierData(parsed));
      }
    })
  }, [firstPage]);
  const csvData = useMemo(() => validationResult && [
      ["Line Number", "Column", "Field Value", "Error Description"],
      ...(validationResult.errors.map(e => [e.lineNumber, e.column, e.fieldValue, e.errorDescription]))
  ], [validationResult]);

  const handleSubmit = useCallback(async () => {
    try {
      setSending(true);
      const supplierData = await readFileAsBase64(firstPage.supplierCsv[0]);
      const body = {
        firstName: firstPage.firstName,
        lastName: firstPage.lastName,
        orgName: firstPage.orgName,
        errors: csvData,
        supplierDataB64: supplierData,
        errorSummary: dataErrorFrequencies(validationResult.errors.filter(e => e.isOptional)),
      };
      await axios.post('/internalTools/supplierValidationMVPEmail', body);
      setOutcome("success");
    } catch (e) {
      navigate('/error', {state: e.response.data});
    }
  }, [firstPage, validationResult, csvData, navigate]);

  if (!validationResult) {
    return <p>Loading ...</p>;
  }
  if (outcome === "success") {
    navigate("/success");
    return null;
  }
  const fatalFreqs = dataErrorFrequencies(validationResult.errors.filter(e => !e.isOptional));
  const optionalFreqs = dataErrorFrequencies(validationResult.errors.filter(e => e.isOptional));
  const hasFatal = !!validationResult.errors.find(e => !e.isOptional);
  const hasOptional = !!validationResult.errors.find(e => e.isOptional);

  return <>
    <h2 className={"mt-0 color-primary"}>{hasFatal ? "Invalid Data" : "Data is Valid"}</h2>
    {!hasFatal && !hasOptional && <p>The data you uploaded is valid. You can proceed with sending it.</p>}
    {!hasFatal && hasOptional && <p>The data you uploaded is valid. However, there were some errors that we strongly recommend correcting. You can find a summary below and you can also download a complete list. Please address them and resubmit.</p>}
    {hasFatal && <p>There are errors on required fields that need to be corrected before submission. You can see a summary of the errors below and also download a complete list of errors.</p>}
    {fatalFreqs.length > 0 && <>
      <h4 className={"mt-1 mb-1"}>Errors Summary</h4>
      <ul>
        {fatalFreqs.slice(0, 4).map((fr, idx) => <li key={idx}>
          {fr[0]} ({fr[1] === 1 ? "once" : `${fr[1]} times`})</li>)}
      </ul>
      {fatalFreqs.length > 4 && <p className={"mt-0 mb-2"}>And {fatalFreqs.length - 4} more errors ...</p>}
    </>}
    {optionalFreqs.length > 0 && <>
      <h4 className={"my-1"}>Recommended Errors Summary</h4>
      <ul>
        {optionalFreqs.slice(0, 4).map((fr, idx) => <li key={idx}>
          {fr[0]} ({fr[1] === 1 ? "once" : `${fr[1]} times`})</li> )}
      </ul>
      {optionalFreqs.length > 4 && <p className={"mt-1 mb-1"}>And {optionalFreqs.length - 4} more errors ...</p>}

    </>}
    {(hasFatal || hasOptional) && <>
      <h4 className={"mb-1 mt-1"}>Download Error List</h4>
      <CSVLink filename={"data-errors.csv"} data={csvData}>Download</CSVLink>
    </>}
    <div className={"two-cols mt-2"}>
      {!hasFatal &&
      <button className={"button--base button__primary"} style={{width: "75%"}} type={"submit"} onClick={handleSubmit}>
        Send
      </button>}
      <button className={"button--base button__secondary"} type={"reset"} onClick={onBack}
              style={{width: "75%"}}>
        Back
      </button>
    </div>
  </>;
}

function SupplierValidationMVP() {
  const [firstPageData, setFirstPageData] = useState();

  let content;

  if (firstPageData) {
    content = <SecondPage firstPage={firstPageData} onBack={() => setFirstPageData()}/>;
  } else {
    content = <FirstPage onSubmit={setFirstPageData} />;
  }

  return <section className={"content-section"}>
    <div className={"container--block"}>
      <h1>Supplier Validation</h1>
      <div className={"shadowbox"}>
        {content}
      </div>
    </div>
  </section>;
}

export default SupplierValidationMVP;
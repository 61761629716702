import {useForm} from "react-hook-form";
import SingleFormField from "./SingleFormField";
import FormattedField from "./FormattedField";
import WideAddressForm from "./WideAddressForm";

function ExpressPOCForm({onNext, requesting, companyName}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
    control,
    watch,
  } = useForm({defaultValues: {
    usState: "---",
    caState: '---',
    country: "USA",
    businessDBAName: companyName,
  }});
  return  <form className={"plain-form"} onSubmit={handleSubmit(onNext)}>
    <div className={"two-cols--dynamic"}>
      <div>
        <SingleFormField
          fieldId={"firstName"}
          label={"First Name"}
          register={register}
          errors={errors}
        />
      </div>
      <div>
        <SingleFormField
          fieldId={"lastName"}
          label={"Last Name"}
          register={register}
          errors={errors}
        />
      </div>
    </div>
    <SingleFormField
      fieldId={"businessDBAName"}
      label={"Business DBA Name (if applicable)"}
      register={register}
      errors={errors}
      required={false}
    />
    <div className={"two-cols--dynamic"}>
      <div>
        {watch("country") === 'USA' ?
          <FormattedField
            control={control}
            fieldId={"usTaxIdNumber"}
            format={"##-#######"}
            errors={errors}
            label={"Tax ID Number"}
          /> :
          <SingleFormField
            fieldId={"caTaxIdNumber"}
            label={"Tax ID Number"}
            register={register}
            errors={errors}
          />
        }
      </div>
      <div>
        <FormattedField
          control={control}
          fieldId={"phoneNumber"}
          format={"+1 (###)-###-####"}
          errors={errors}
          label={"Phone Number"}
        />
      </div>
    </div>
    <SingleFormField
      fieldId={"website"}
      label={"Website"}
      register={register}
      errors={errors}
      required={false}
    />
    <WideAddressForm register={register} errors={errors} watch={watch} control={control}/>
    <div className={"two-cols"}>
      <button disabled={requesting} className={"button--base button__primary"} type={'submit'} style={{width: "75%"}}>
        {requesting ? "Sending..." : "Next"}
      </button>
      <button disabled={requesting} className={"button--base button__secondary"} type={"button"} onClick={() => reset()}
              style={{width: "75%"}}>
        Reset
      </button>
    </div>
  </form>;
}

export default ExpressPOCForm;
import {useForm} from "react-hook-form";
import {useState} from "react";
import {ErrorMessage} from "@hookform/error-message";
import {sendRequest} from "../api/apiHelper";

function EmailValidation() {
  const {register, handleSubmit, formState: {errors}, reset} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState();
  const ErrorMessageContainer = ({ children }) => (
    <div className="error" style={{flexBasis: "auto"}}>{children}</div>
  );
  async function onSubmit(event) {
    try {
      setSubmitted(true);
      const response = await sendRequest('/check/sendgrid', {email: event.email});
      setResult(response.data);
      setSubmitted(false);
    } catch (e) {
      console.error('failed e');
    }
    console.log(event);
  }
  return <form onSubmit={handleSubmit(onSubmit)}>
    <div className={"form-text-container"} style={{justifyContent: "space-between", display: "flex"}}>
      <label style={{flexBasis: "auto"}}>Email:</label>
      <ErrorMessage
        name={"email"}
        errors={errors}
        as={<ErrorMessageContainer />}
      />
    </div>
    <input {...register("email", {required: {value: true, message: "Required"}})} type={"email"}/>

    <div className={"button-container"}>
      <button className={"black-button"} type={"submit"} disabled={submitted}>Submit</button>
      <button className={"grey-button"} disabled={submitted} onClick={() => {reset(); setResult();}}>Reset</button>
    </div>
    {result && <table className={'checks-table'} style={{marginTop: '1.5rem'}}>
      <thead>
        <td>Field</td>
        <td>Value</td>
      </thead>
      <tr>
        <td>Email</td>
        <td>{result.email}</td>
      </tr>
      <tr>
        <td>Verdict</td>
        <td>{result.verdict}</td>
      </tr>
      <tr>
        <td>Score</td>
        <td>{result.score}</td>
      </tr>
      <tr>
        <td>Domain has valid address syntax</td>
        <td>{result.checks.domain.has_valid_address_syntax.toString()}</td>
      </tr>
      <tr>
        <td>Domain has MX or A record</td>
        <td>{result.checks.domain.has_mx_or_a_record.toString()}</td>
      </tr>
      <tr>
        <td>Domain is suspected disposable address</td>
        <td>{result.checks.domain.has_mx_or_a_record.toString()}</td>
      </tr>
      <tr>
        <td>Local part is suspected role address</td>
        <td>{result.checks.local_part.is_suspected_role_address.toString()}</td>
      </tr>
      <tr>
        <td>Has known bounces</td>
        <td>{result.checks.additional.has_known_bounces.toString()}</td>
      </tr>
      <tr>
        <td>Has suspected bounces</td>
        <td>{result.checks.additional.has_suspected_bounces.toString()}</td>
      </tr>
    </table>
    }
  </form>;
}

export default EmailValidation;
import SingleFormField from "../SingleFormField";
import * as EmailValidator from "email-validator";
import FormattedField from "../FormattedField";

function PersonalInfoFields({register, errors, control, prefix = "", disabled = false}) {
  return <>
    <div className={"two-cols--dynamic"}>
      <div>
        <SingleFormField
          fieldId={`${prefix}firstName`}
          label={"First Name"}
          register={register}
          errors={errors}
          disabled={disabled}
        />
      </div>
      <div>
        <SingleFormField
          fieldId={`${prefix}lastName`}
          label={"Last Name"}
          register={register}
          errors={errors}
          disabled={disabled}
        />
      </div>
    </div>
    <SingleFormField
      fieldId={`${prefix}email`}
      label={"Email"}
      register={register}
      errors={errors}
      inputType={"email"}
      disabled={disabled}
      validate={(v) => EmailValidator.validate(v) || 'Invalid email address'}
    />
    <FormattedField
      control={control}
      fieldId={`${prefix}phoneNumber`}
      format={"+1 (###)-###-####"}
      disabled={disabled}
      errors={errors}
      label={"Phone Number"}
    />
  </>;
}

export default PersonalInfoFields;
import {ErrorMessage} from "@hookform/error-message";
import ErrorMessageContainer from "./ErrorMessageContainer";

/**
 * @typedef {Object} ListSelection
 * @property {string} label
 * @property {string} selectionValue
 *
 * @param fieldId
 * @param {string} label
 * @param {ListSelection[]} selections
 * @param register
 * @param errors the errors object from useForm
 * @param validate validation function to be passed to useForm register
 */
function SelectInput({fieldId, label, selections, register, errors, validate = () => true}) {
  return <>
    <div className={"form-text-container"}>
      <label htmlFor={fieldId}>{label}</label>
      <ErrorMessage
        errors={errors}
        name={fieldId}
        as={<ErrorMessageContainer/>}
      />
    </div>
    <select
      id={fieldId}
      style={{padding: "6px 7px"}}
      {...register(fieldId, {
        validate: (v) => (v && v !== "---") || 'Required',
      })}
    >
      {selections.map((sel) =>
        <option key={sel.selectionValue} value={sel.selectionValue}>
          {sel.label}
        </option>)}
    </select>
  </>;
}

export default SelectInput;
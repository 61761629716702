import SingleFormField from "./SingleFormField";
import StateSelectorUSA from "./StateSelectorUSA";
import StateSelectorCA from "./StateSelectorCA";
import FormattedField from "./FormattedField";

/**
 * Make sure you set the form defaults to {defaultValues: {usState: "---", caState: '---', country: "USA"}}
 */
function WideAddressForm({register, errors, watch, control}) {
  const stateInput = watch("country") === 'USA' ?
    <StateSelectorUSA
      register={register}
      errors={errors}
    /> : <StateSelectorCA register={register} errors={errors}/>;

  return <fieldset>
    <legend>Corporate Address</legend>
    <div className={"form-text-container"}>
      <label htmlFor={"country"}>Country</label>
    </div>
    <select id={"country"} {...register("country")} >
      <option value={"Canada"}>Canada</option>
      <option value={"USA"}>United States</option>
    </select>
    <SingleFormField
      fieldId={"address1"}
      label={"Address 1"}
      register={register}
      errors={errors}
    />
    <SingleFormField
      fieldId={"address2"}
      label={"Address 2"}
      register={register}
      errors={errors}
      required={false}
    />
    <SingleFormField
      fieldId={"city"}
      label={"City"}
      register={register}
      errors={errors}
    />
    <div className={"two-cols--dynamic"}>
      <div>
        {stateInput}
      </div>
      <div>
        {watch("country") === 'USA' ?
          <FormattedField
            control={control}
            fieldId={"usZipCode"}
            format={"#####"}
            errors={errors}
            label={"Zip Code"}
          />
          :
          <SingleFormField
            fieldId={"caZipCode"}
            label={"Zip Code"}
            register={register}
            errors={errors}
          />
        }
      </div>
    </div>
  </fieldset>;
}

export default WideAddressForm;


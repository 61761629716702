export function formatWideAddressFormData(formData) {
  const isUsa = formData.country === 'USA';
  const zipCode = isUsa ? formData.usZipCode : formData.caZipCode;
  const state = isUsa ? formData.usState : formData.caState;

  return [
    `${formData.address1}\n`,
    formData.address2 ? `${formData.address2}\n` : '',
    `${formData.city}, ${state} ${zipCode}`
  ].join('');
}
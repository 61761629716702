import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";
import {handleError, sendRequest} from "../api/apiHelper";
import {formatWideAddressFormData} from "../utils/address";
import CirclesProgress from "../components/CirclesProgress";
import ExpressPOCForm from "../components/ExpressPOCForm";
import ExpressBusinessAndBankingForm from "../components/ExpressBusinessAndBankingForm";
import {getAttachmentName, readFileAsBase64} from "../utils/fileUpload";
import ExpressFormAdditionalDocs from "../components/ExpressFormAdditionalDocs";

function ResumeExpressForm() {
  const {id} = useParams();
  let navigate = useNavigate();
  const [initialData, setInitialData] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [initialLoading, setInitialLoading] = useState(true);
  const [requesting, setRequesting] = useState();
  const [docusignResponse, setDocusignResponse] = useState();
  const [firstPage, setFirstPage] = useState();
  const [secondPage, setSecondPage] = useState();
  const [tinCheckResponse, setTinCheckResponse] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/asyncForm/resume/${id}`);
        setInitialData(response.data);
        setInitialLoading(false);
      } catch (e) {
        navigate('/error', {state: {title: "Invalid URL", description: ""}});
      }
    }
    fetchData();
  }, [id, navigate]);

  const moveToPage2 = async(formData) => {
    try {
      setRequesting(true);
      setFirstPage(formData);
      const response = await sendRequest(
        '/check/tin',
        {
          tin: formData.country === 'USA' ? formData.usTaxIdNumber : formData.caTaxIdNumber,
          companyName: initialData.companyName,
        });
      setTinCheckResponse(response.data);
      setRequesting(false);
      setCurrentStep(2);
    } catch (error) {
      const errorPageText = handleError(error);
      navigate('/error', {state: errorPageText});
    }
  }

  const sendForm = async (additionalDocs) => {
    try {
      const body = {
        asyncFormId: id,
        signerEmail: initialData.signerEmail,
        company: initialData.companyName,
        page: initialData.page,
        firstName: firstPage.firstName,
        lastName: firstPage.lastName,
        businessDBAName: firstPage.businessDBAName,
        tin: firstPage.country === 'USA' ? firstPage.usTaxIdNumber : firstPage.caTaxIdNumber,
        phoneNumber: firstPage.phoneNumber,
        address: formatWideAddressFormData(firstPage),
        website: firstPage.website,
        businessRelPayor: secondPage.businessRelPayor,
        businessRelContact: secondPage.businessRelContact,
        businessRelEmail: secondPage.businessRelEmail,
        businessRelPhoneNumber: secondPage.businessRelPhoneNumber,
        accountType: 'business',
        accountNumber: secondPage.accountNumber,
        routingNumber: secondPage.routingNumber,
        accountOwnerBusinessName: secondPage.accountOwnerBusinessName,
        bankingEmailNotifs: secondPage.emailNotifications,
      };
      let attachments = [];
      if (additionalDocs.bankingDoc) {
        attachments.push({
          dataB64: await readFileAsBase64(additionalDocs.bankingDoc[0]),
          fileName: getAttachmentName(additionalDocs.bankingDoc[0].type," - Banking"),
        });
      }
      if (additionalDocs.businessIDDoc) {
        attachments.push({
          dataB64: await readFileAsBase64(additionalDocs.businessIDDoc[0]),
          fileName: getAttachmentName(additionalDocs.businessIDDoc[0].type," - Business Identification"),
        });
      }
      if (additionalDocs.businessRelDoc) {
        attachments.push({
          dataB64: await readFileAsBase64(additionalDocs.businessRelDoc[0]),
          fileName: getAttachmentName(additionalDocs.businessRelDoc[0].type," - Business Relationship"),
        });
      }
      body.attachments = attachments;
      setRequesting(true);
      const apiResponse = await sendRequest('/banking/createFromPDF', body);
      setDocusignResponse(apiResponse.data);
    } catch (error) {
      const errorPageText = handleError(error);
      navigate('/error', {state: errorPageText});
    }
  };

  if (docusignResponse) {
    window.location.href = docusignResponse.url;
    return <h1 style={{textAlign: "center"}}>Loading</h1>;
  }
  let content;
  switch (currentStep) {
    case 1:
      content = <ExpressPOCForm onNext={moveToPage2} requesting={requesting} companyName={initialData && initialData.companyName}/>;
      break;
    case 2:
      content = <ExpressBusinessAndBankingForm
        onSubmit={(data) => {setSecondPage(data); setCurrentStep(3);}}
        sending={requesting}
        onBack={() => setCurrentStep(1)}
        companyName={initialData && initialData.companyName}
      />;
      break;
    case 3:
      content = <ExpressFormAdditionalDocs
        email={initialData.signerEmail}
        bankingData={secondPage}
        sending={requesting}
        onBack={() => setCurrentStep(2)}
        onSubmit={sendForm}
        tinCheckResponse={tinCheckResponse}
      />;
      break;
    default:
      break;
  }

  return <section className="content-section">
    <div className="container--block">
      <CirclesProgress currentStep={currentStep}
                       stepLabels={["Point of Contact", "Business & Banking", "Additional Documents"]}/>
      <div className={"shadowbox"}>
        {initialLoading ? <h3>Loading</h3> : <>
          {content}
          <div className={"mt-2"}>
            <a target="_blank" href={"https://www.finexio.com/terms-of-service"}>General Terms of Service</a>
          </div>
        </>}
      </div>
    </div>
  </section>
}

export default ResumeExpressForm;
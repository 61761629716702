import React from 'react';
import {ReactComponent as CheckMark} from '../assets/images/check.svg'
import {useSearchParams} from 'react-router-dom';

function Success({
  text,
  title,
  avatarUrl,
  userRoleName,
  removeTitle = false,
  removeCheckmark = false,
  message = "",
  backButton = true,
}) {
  const [searchParams] = useSearchParams();
  const ach = searchParams.has("ach");
  const landingPage = ach ? "https://www.finexio.com/ach-enrollment" : "https://www.finexio.com/address-banking-change-request-form-docusign"
  const backLinkName = 'Back to Home';

  return (
    <section className="content-section">
      <div className="success-container">
        {avatarUrl === undefined && userRoleName === undefined && !removeCheckmark &&  (
          <CheckMark
            className="success-check"
            alt="success checkmark"
          />
        )}
        {!removeTitle && <div className="success-header-container">
          {text ? <h1>{text.title}</h1> : <h1>{title}</h1>}
        </div>
        }

        <div className="success-content">
          <div className="fs-3">
            {message && <p style={{ textAlign: 'center'}}>{message}</p>}
          </div>
          {backButton &&
              (<a href={landingPage}>
                <button className="grey-button" style={{width: "14rem"}}>
                  {backLinkName}
                </button>
              </a>)
          }
        </div>
      </div>
    </section>
  );
}

export default Success;

import {ErrorMessage} from "@hookform/error-message";
import {useForm} from "react-hook-form";
import {useState} from "react";
import {handleError, sendRequest} from "../api/apiHelper";
import {useNavigate} from 'react-router-dom';

function AvsTest({ text, formText, btsText, userFlowText }) {
    let navigate = useNavigate();
    const [accountType, setAccountType] = useState('business');
    const [submitted, setSubmitted] = useState(false);
    const [testResult, setTestResult] = useState();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            accountType: "business"
        }
    });
    function radioChange(event) {
        setAccountType(event.target.value);
        setValue('accountType', event.target.value);
    }

    async function onSubmit(event) {
        try {
            setSubmitted(true);
            const response = await sendRequest('/check/avs', event);

            // Received URL for embedded signing, redirect user
            setTestResult(response.data);
        } catch (error) {
            console.log(error);
            const errorPageText = handleError(error);
            navigate('/error', { state: errorPageText });
        }
        console.log("onSubmit ", event)
    }

    const ErrorMessageContainer = ({ children }) => (
        <div className="error" style={{flexBasis: "auto"}}>{children}</div>
    );

    let individualOwner = (<>
        <div className="form-text-container" style={{justifyContent: "space-between", display: accountType === "business" ? "none": "block"}}>
            <label style={{flexBasis: "auto"}}>Bank Account Owner First Name</label>
            <ErrorMessage
                errors={errors}
                name="accountOwnerFirstName"
                as={<ErrorMessageContainer/>}
            />
        </div>
        <input
            {...register("accountOwnerFirstName")}
            style={{
                display: accountType === "business" ? "none": "block"
            }}
        />
        <div className="form-text-container" style={{justifyContent: "space-between", display: accountType === "business" ? "none": "block"}}>
            <label style={{flexBasis: "auto"}}>Bank Account Owner Last Name</label>
            <ErrorMessage
                errors={errors}
                style={{flexBasis: "auto"}}
                name="accountOwnerLastName"
                as={<ErrorMessageContainer/>}
            />
        </div>
        <input
            {...register("accountOwnerLastName")}
            style={{
                display: accountType === "business" ? "none": "block"
            }}
        />
    </>);
    const businessOwner = (
        <>
            <div className="form-text-container" style={{justifyContent: "space-between", display: accountType === "business" ? "block": "none"}}>
                <label style={{flexBasis: "auto"}}>Bank Account Owner Business Name</label>
                <ErrorMessage
                    errors={errors}
                    style={{flexBasis: "auto"}}
                    name="accountOwnerBusinessName"
                    as={<ErrorMessageContainer/>}
                />
            </div>
            <input
                {...register("accountOwnerBusinessName")}
                style={{
                    display: accountType === "business" ? "block": "none"
                }}
            />
        </>
    );
    let form = <div className="form-holder center-form" style={{height: "unset", justifyContent: 'flex-start'}}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-text-container" style={{justifyContent: "space-between"}}>
                <label>New Account Number</label>
                <ErrorMessage
                    errors={errors}
                    name="accountNumber"
                    as={<ErrorMessageContainer/>}
                />
            </div>
            <input

                {...register('accountNumber', {
                    required: {
                        value: true,
                        message: text.requiredFieldError,
                    },
                })}
            />
            <div className="form-text-container" style={{justifyContent: "space-between"}}>
                <label>New Routing Number</label>
                <ErrorMessage
                    errors={errors}
                    name="routingNumber"
                    as={<ErrorMessageContainer/>}
                />
            </div>
            <input

                {...register('routingNumber', {
                    required: {
                        value: true,
                        message: text.requiredFieldError,
                    },
                })}
                style={{marginBottom: "10px"}}
            />
            <p style={{fontWeight: "700"}}>Account Type</p>
            <div style={{display: "flex"}}>
                <label htmlFor={'account-personal'} className={"no-asterisk-label"}
                       style={{display: "flex", flexBasis: "1"}}>
                    <input type={"radio"}
                           value={"business"}
                           id={"account-individual"}
                           style={{width: "unset", marginLeft: "0", marginRight: "8px"}}
                           onChange={radioChange}
                           checked={accountType === "business"}
                    />
                    Business
                </label>
                <label htmlFor={'account-personal'} className={"no-asterisk-label"}
                       style={{display: "flex", flexBasis: "1"}}>
                    <input type={"radio"}
                           value={"individual"}
                           id={"account-individual"}
                           style={{width: "unset"}}
                           onChange={radioChange}
                           checked={accountType === "individual"}/>
                    Individual
                </label>
            </div>
            {individualOwner}
            {businessOwner}


            <div className="button-container">
                <button className="black-button" type="submit" disabled={submitted}>
                    {submitted ? "Sending..." : "Submit"}
                </button>
            </div>
        </form>
    </div>;

    function resetForm() {
        // navigate("/avs-test")
        setTestResult();
        setSubmitted(false);
        reset();
        setAccountType("business")
    }

    const result = testResult && (
        <div className="form-holder center-form" style={{height: "unset", justifyContent: 'flex-start'}}>
            <p style={{fontWeight: "700"}}>Verification Result</p>
            <input value={testResult.verification}/>
            <p style={{fontWeight: "700"}}>Authentication Result</p>
            <input value={testResult.authentication}/>
            <button className="black-button" onClick={resetForm}>
                New Test
            </button>
        </div>
    )
    const content = testResult ? result : form;
    return (
        <section className="content-section">
            <div className="container">
                <div className="header-container">
                    <h1>Avs Banking Test</h1>
                </div>
                {content}
            </div>
        </section>
    )
}

export default AvsTest;
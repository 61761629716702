import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

function Card({ cardType, buttonType, iconUrls, title, featureList, linkTo }) {
  const buttonName = 'Get Started';
  return (
    <div className={cardType}>
      <div>
        <div className="image-holder">
            {iconUrls.map((url) => {
                    return <img key={url} src={process.env.REACT_APP_SERVER_ROOT + url} width="100px" height="auto" alt="card-icon"/>
                }
            )}
        </div>
        <h3 className="card-title">{title}</h3>
        <div className="card-features">
          <ul>{parse(featureList)}</ul>
        </div>
      </div>
      <div className="card-btn-container">
        <Link to={linkTo} rel="noopener noreferrer">
          <button className={buttonType} type="button">
            {buttonName}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Card;

import {ErrorMessage} from "@hookform/error-message";
import {useForm} from "react-hook-form";
import {useState} from "react";
import {sendRequest} from "../api/apiHelper";
import TinStatusPoll from "./TinStatusPoll";

function TinCheck() {
  const ErrorMessageContainer = ({children}) => (
    <span className="error" style={{color: '#cc0f0f'}}> {children}</span>
  );
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();
  const [requesting, setRequesting] = useState(false);
  const [tinResponse, setTinResponse] = useState();

  const uiResult = tinResponse && (!tinResponse.finished ?
    <TinStatusPoll requestId={tinResponse.id} onFinished={() => setRequesting(false)}/> :
    <div style={{marginTop: "1rem"}}>
      <label htmlFor={"result"} className={"no-asterisk-label"} style={{marginTop: '1rem'}}>Result</label>
      <textarea
        rows={16}
        disabled
        id={"result"}
        value={JSON.stringify(tinResponse.fullResponse, null, 2)}
        style={{resize: "none"}}
      />
    </div>);

  async function onSubmit(event) {
    try {
      setRequesting(true);
      setTinResponse(null);
      const response = await sendRequest(
        '/check/tin',
        {
          tin: event.tin,
          companyName: event.companyName,
        });
      setTinResponse(response.data);
      if (response.data.finished) {
        setRequesting(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  console.log('errors', errors);
  return (

    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-text-container">
        <label>Company Name:</label>
        <ErrorMessage
          errors={errors}
          name="companyName"
          as={<ErrorMessageContainer/>}
        />
      </div>
      <input type={"text"}
             disabled={requesting}
             {...register("companyName", {
               required: {
                 value: true,
                 message: "Required",
               }
             })}
      />
      <div className="form-text-container">
        <label htmlFor={"tin"}>
          Company TIN:
        </label>
        <ErrorMessage
          name={"tin"}
          errors={errors}
          as={<ErrorMessageContainer/>}/>
      </div>
      <input
        type={"number"}
        disabled={requesting}
        className={"no-spinners"}
        id={"tin"}
        {...register("tin", {
          required: {
            value: true,
            message: "Required"
          },
          minLength: {
            value: 9,
            message: "A 9 digit number is required."
          },
          maxLength: {
            value: 9,
            message: "A 9 digit number is required."
          },
        })}
      />
      <div className={"button-container"}>
        <button
          className={"black-button"}
          type={"submit"}
          disabled={requesting}
        >
          Submit
        </button>
        <button
          type={"button"}
          className={"grey-button"}
          onClick={() => {
            setRequesting(false);
            setTinResponse(null);
            reset();
          }}
        >
          Reset
        </button>
      </div>
      {uiResult}
    </form>

  )
}

export default TinCheck;
import {useState} from "react";
import classNames from "classnames";
import {useNavigate} from "react-router-dom";

function Onboarding() {
  const [userHasAccount, setUserHasAccount] = useState();
  const [existingAccountType, setExistingAccountType] = useState();
  const navigate = useNavigate();

  const GenericButton = ({selectionValue, getSelection, setSelection, children, hasIcon = false}) =>
    <button
      className={classNames('onboarding-button', {'onboarding-button-selected': getSelection === selectionValue, 'onboarding-button-with-icon': hasIcon})}
      onClick={() => setSelection(selectionValue)}
      type={"button"}
    >
      {children}
    </button>;

  const businessButtonContents = <div>
    <svg height="50" viewBox="0 0 52 47" fill="none" xmlns="http://www.w3.org/2000/svg" style={{fill: "red"}}>
      <path d="M23.125 32.125V26.375H28.875V32.125H23.125ZM20.25 9.125H31.75V5.14312C31.75 4.70229 31.566 4.29692 31.198 3.927C30.8281 3.559 30.4227 3.375 29.9819 3.375H22.0181C21.5773 3.375 21.1719 3.559 20.802 3.927C20.434 4.29692 20.25 4.70229 20.25 5.14312V9.125ZM4.76812 46.5C3.44562 46.5 2.34163 46.0573 1.45613 45.1717C0.568708 44.2843 0.125 43.1794 0.125 41.8569V30.6875H20.25V32.677C20.25 33.344 20.4704 33.8979 20.9112 34.3388C21.3521 34.7796 21.906 35 22.573 35H29.427C30.094 35 30.6479 34.7796 31.0888 34.3388C31.5296 33.8979 31.75 33.344 31.75 32.677V30.6875H51.875V41.8569C51.875 43.1794 51.4323 44.2834 50.5467 45.1689C49.6593 46.0563 48.5544 46.5 47.2319 46.5H4.76812ZM0.125 27.8125V13.7681C0.125 12.4456 0.568708 11.3416 1.45613 10.4561C2.34163 9.56871 3.44562 9.125 4.76812 9.125H17.375V5.14312C17.375 3.82062 17.8187 2.71662 18.7061 1.83112C19.5916 0.943708 20.6947 0.5 22.0153 0.5H29.979C31.3015 0.5 32.4065 0.943708 33.2939 1.83112C34.1794 2.71662 34.6221 3.82062 34.6221 5.14312V9.125H47.229C48.5515 9.125 49.6555 9.56871 50.541 10.4561C51.4284 11.3416 51.8721 12.4456 51.8721 13.7681V27.8125H31.7471V25.823C31.7471 25.156 31.5267 24.6021 31.0859 24.1612C30.645 23.7204 30.0911 23.5 29.4241 23.5H22.5701C21.9031 23.5 21.3492 23.7204 20.9084 24.1612C20.4675 24.6021 20.2471 25.156 20.2471 25.823V27.8125H0.125Z" fill="#043886"/>
    </svg>
    <span>Business</span>
  </div>;
  const individualButtonContents = <div>
    <svg height="50" viewBox="0 0 48 45" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.5 0.166672C26.5058 0.166672 29.3885 1.34316 31.5139 3.43731C33.6393 5.53147 34.8334 8.37175 34.8334 11.3333C34.8334 14.2949 33.6393 17.1352 31.5139 19.2294C29.3885 21.3235 26.5058 22.5 23.5 22.5C20.4943 22.5 17.6116 21.3235 15.4862 19.2294C13.3608 17.1352 12.1667 14.2949 12.1667 11.3333C12.1667 8.37175 13.3608 5.53147 15.4862 3.43731C17.6116 1.34316 20.4943 0.166672 23.5 0.166672ZM23.5 28.0833C36.0234 28.0833 46.1667 33.0804 46.1667 39.25V44.8333H0.833374V39.25C0.833374 33.0804 10.9767 28.0833 23.5 28.0833Z"
        fill="#043886"/>
    </svg>
    <span>Individual</span>
  </div>;
  const higherEdButtonContents = <div>
    <svg height="50" viewBox="0 0 60 55" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M32 0.199997L0.5 14.9L8.9 18.68V25.82C6.38 26.66 4.7 29.18 4.7 31.7C4.7 34.22 6.38 36.74 8.9 37.58V38L5.12 46.82C3.86 50.6 4.7 54.8 11 54.8C17.3 54.8 18.14 50.6 16.88 46.82L13.1 38C15.62 36.74 17.3 34.64 17.3 31.7C17.3 28.76 15.62 26.66 13.1 25.82V20.78L32 29.6L63.5 14.9L32 0.199997ZM50.48 27.5L31.58 35.9L21.5 31.28V31.7C21.5 34.64 20.24 37.16 18.14 39.26L20.66 45.14V45.56C21.08 47.24 21.5 48.92 21.08 50.6C24.02 51.86 27.38 52.7 31.58 52.7C45.44 52.7 50.48 44.3 50.48 40.1V27.5Z"
        fill="#043886"/>
    </svg>
    Higher Education
  </div>;
  const userHasAccountContent = <>
    <h2 style={{textAlign: 'center', marginTop: "2rem"}}>What is the type of account?</h2>
    <div className={"onboarding-options-row"}>
      <GenericButton selectionValue={'business'} getSelection={existingAccountType}
                     setSelection={setExistingAccountType} hasIcon={true}>
        {businessButtonContents}
      </GenericButton>
      <GenericButton selectionValue={'individual'} getSelection={existingAccountType}
                     setSelection={setExistingAccountType} hasIcon={true}>
        {individualButtonContents}
      </GenericButton>
      <GenericButton selectionValue={'higher-ed'} getSelection={existingAccountType}
                     setSelection={setExistingAccountType} hasIcon={true}>
        {higherEdButtonContents}
      </GenericButton>
    </div>
  </>;

  const userDoesNotHaveAccountContent = <>
    <h2 style={{textAlign: 'center', marginTop: "2rem"}}>What type of account would you like to open?</h2>
    <div className={"onboarding-options-row"}>
      <button
        className={classNames('onboarding-button', 'onboarding-button-with-icon')}
        onClick={() => navigate("/business-ach-enrollment")}
        type={"button"}
      >
        {businessButtonContents}
      </button>
      <button
        className={classNames('onboarding-button', 'onboarding-button-with-icon')}
        onClick={() => navigate("/individual-ach-async")}
        type={"button"}
      >
        {individualButtonContents}
      </button>
      <button
        className={classNames('onboarding-button', 'onboarding-button-with-icon')}
        onClick={() => navigate("/higher-education-ach-enrollment")}
        type={"button"}
      >
        {higherEdButtonContents}
      </button>

    </div>
    </>;

  const SupplierManagementRow = ({profileLink, bankingLink, bothLink}) =>
    <>
      <h2 style={{textAlign: 'center', marginTop: "2rem"}}>What would you like to update?</h2>
      <div className={'onboarding-options-row'}>
        <button
          className={classNames('onboarding-button')}
          onClick={() => navigate(profileLink)}
          type={"button"}
        >
          Profile
        </button>
        <button
          className={classNames('onboarding-button')}
          onClick={() => navigate(bankingLink)}
          type={"button"}
        >
          Banking
        </button>
        <button
          className={classNames('onboarding-button')}
          onClick={() => navigate(bothLink)}
          type={"button"}
        >
          Both
        </button>
      </div>
    </>;

  let thirdRow = null;
  switch (existingAccountType) {
    case 'business':
      thirdRow = <SupplierManagementRow
        profileLink={"/profile-update-async"}
        bankingLink={"/banking-update-async"}
        bothLink={"/profile-and-banking-update-async"}
      />
      break;
    case 'individual':
      thirdRow = <SupplierManagementRow
        profileLink={"/individual-profile-update-async"}
        bankingLink={"/individual-banking-update-async"}
        bothLink={"/individual-profile-and-banking-update-async"}
      />
      break;
    case 'higher-ed':
      thirdRow = <h2 style={{textAlign: 'center', marginTop: '2rem'}}>
        No updates are available for this type of account.
      </h2>
      break;
    default:
      break;
  }

  return <section className={"content-section"}>
      <div className={"header-container"} style={{gridColumn: '2/3', textAlign: 'center'}}>
        <h1>Welcome!</h1>
      </div>
      <div className={"container--block shadowbox"} >
        <h2 style={{textAlign: 'center'}}>Do you have a Finexio account?</h2>
        <div className={'onboarding-options-row'}>
          <GenericButton selectionValue={"yes"} getSelection={userHasAccount} setSelection={setUserHasAccount}>
            Yes
          </GenericButton>
          <GenericButton selectionValue={"no"} getSelection={userHasAccount} setSelection={setUserHasAccount}>
            No
          </GenericButton>
        </div>
        {userHasAccount === 'yes' && userHasAccountContent}
        {userHasAccount === 'no' && userDoesNotHaveAccountContent}
        {thirdRow}
      </div>
  </section>;
}

export default Onboarding;
import SelectInput from "./SelectInput";

function StateSelectorCA({register, errors}) {
  const states = [
    ["---", '---'],
    ["Alberta", "AB"],
    ["British Columbia", "BC"],
    ["Manitoba", "MB"],
    ["New Brunswick", "NB"],
    ["Newfoundland and Labrador", "NL"],
    ["Northwest Territories", "NT"],
    ["Nova Scotia", "NS"],
    ["Nunavut", " NU"],
    ["Ontario", "ON"],
    ["Prince Edward Island", "PE"],
    ["Quebec", "QC"],
    ["Saskatchewan", "SK"],
    ["Yukon", "YT"],
  ];
  return <SelectInput
    fieldId={"caState"}
    label={"State"}
    selections={states.map(([label, selectionValue]) => { return {label, selectionValue}})}
    register={register}
    errors={errors}
    validate={(v) => (v && v !== "---") || 'Required'}
  />
}

export default StateSelectorCA;
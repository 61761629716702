import {validateAttachment} from "../utils/fileUpload";
import {useForm} from "react-hook-form";
import FormLabel from "./FormLabel";
import {useEffect, useState} from "react";
import useInterval from "./useInterval";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {sendRequest} from "../api/apiHelper";

function ExpressFormAdditionalDocs({email, bankingData, sending, onBack, onSubmit, tinCheckResponse}) {
  const {register, handleSubmit, formState: {errors}} = useForm();
  let navigate = useNavigate();
  const [tinFinalResponse, setTinFinalResponse] = useState(tinCheckResponse.finished ? tinCheckResponse : null);
  const [delay, setDelay] = useState(tinCheckResponse.finished ? null : 2000);
  const [pollsLeft, setPollsLeft] = useState(4);
  const [emailCheckResponse, setEmailCheckResponse] = useState();
  const [bankingCheckResponse, setBankingCheckResponse] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Promise.all([
          sendRequest('/check/avs', {
            accountNumber: bankingData.accountNumber,
            routingNumber: bankingData.routingNumber,
            accountType: "business",
            accountOwnerBusinessName: bankingData.accountOwnerBusinessName,
          }),
          sendRequest('/check/emailInNetwork', { email })
          ]);
        setBankingCheckResponse(response[0].data);
        setEmailCheckResponse(response[1].data);
      } catch (e) {
        navigate('/error', {state: {title: "Failed", description: ""}});
      }
    };
    fetchData();
  }, [navigate]);

  useInterval(async () => {
    try {
      console.log('polling TIN status');
      const response = await axios.get('/check/tinCheckStatus/' + tinCheckResponse.id);
      if (response.data.finished) {
        setDelay(null);
        setTinFinalResponse(response.data);
      }
      if (pollsLeft === 1) {
        setDelay(null);
        setPollsLeft(0);
      } else {
        setPollsLeft(l => l - 1);
      }
    } catch (e) {
      navigate('/error', {state: {title: "Failed", description: ""}});
    }
  }, delay);

  const tinIsValid = tinFinalResponse && tinFinalResponse.status === "TIN Matched";
  const bankingIsValid = bankingCheckResponse && bankingCheckResponse.isValid;
  const emailInNetwork = emailCheckResponse && emailCheckResponse.result;
  const bankingRequired = !bankingIsValid;
  const businessIDRequired = !tinIsValid;
  const businessRelRequired = !(tinIsValid && bankingIsValid && emailInNetwork);

  let content = !(delay || !emailCheckResponse || !bankingCheckResponse) && <>
    {(bankingRequired || businessRelRequired || businessIDRequired) ?
      <h2 className={"text-primary-color"}>Additional Documents Required</h2> :
      <>
        <h2 className={"text-primary-color"}>No Additional Documents Required</h2>
        <p>Our system was able to automatically verify your information. No additional documents are needed at this moment. You may submit the form.</p>
      </>}
    {bankingRequired &&
      <fieldset>
        <legend style={{margin: "0"}}>Bank Account Ownership</legend>
        <p><span className={"text-underline"}>Please Upload</span> a voided check,
          bank statement, or a letter from
          your bank.</p>
        <p><strong>Note:</strong> the bank letter
          must be on your bank’s
          letterhead and contain the
          updated account information
          and email address of your
          financial institution's contact</p>
        <FormLabel label={"Attachment"} errors={errors} fieldId={"bankingDoc"} />
        <input type={"file"} {...register("bankingDoc", {validate: validateAttachment})}
               accept={'application/pdf,.png,.jpeg,.jpg'}/>
      </fieldset>}
    {businessIDRequired &&
      <fieldset>
        <legend style={{margin: "0"}}>Business Identification</legend>
        <p><span className={"text-underline"}>Please Upload</span> W9. If a W9 can’t
          be provided, SoS documents in
          the business entities filing state;
          or documents showing articles
          of incorporation.</p>
        <FormLabel label={"Attachment"} errors={errors} fieldId={"businessIDDoc"} />
        <input type={"file"} {...register("businessIDDoc", {validate: validateAttachment})}
               accept={'application/pdf,.png,.jpeg,.jpg'}/>
      </fieldset>}
    {businessRelRequired &&
      <fieldset>
        <legend style={{margin: "0"}}>Business Relationship (Invoice)</legend>
        <p><span className={"text-underline"}>Please Upload</span> a recent invoice
          demonstrating the relationship
          between Buyer and Supplier:</p>
        <FormLabel label={"Attachment"} errors={errors} fieldId={"businessRelDoc"} />
        <input type={"file"} {...register("businessRelDoc", {validate: validateAttachment})}
               accept={'application/pdf,.png,.jpeg,.jpg'}/>
      </fieldset>}
    <div className={"two-cols"}>
      <button className={"button--base button__primary"} disabled={sending} type={'submit'} style={{width: "75%"}}>
        {sending ? 'Sending...' : 'Submit'}
      </button>
      <button className={"button--base button__secondary"} disabled={sending} type={"button"} onClick={onBack}
              style={{width: "75%"}}>
        Back
      </button>
    </div>
  </>;

  return <form className={"plain-form"} onSubmit={handleSubmit(onSubmit, (err) => console.log(err))}>
    {(delay || !emailCheckResponse || !bankingCheckResponse) ? <h2 className={"text-primary-color"}>Performing checks ... </h2> : content}
  </form>
}

export default ExpressFormAdditionalDocs;
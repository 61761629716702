import {useForm} from "react-hook-form";
import React from "react";
import SingleFormField from "./SingleFormField";
import FormattedField from "./FormattedField";
import WideAddressForm from "./WideAddressForm";
import FormLabel from "./FormLabel";
import {validateAttachment} from "../utils/fileUpload";
import {ReactComponent as QuestionIcon} from '../assets/images/question-icon.svg';
import {registerTooltip} from "../utils/tooltips";
import InfoTooltip from "./InfoTooltip";

function BuyerOnboardingOrgForm({onNext, onBack, existingClient}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
    watch,
  } = useForm({defaultValues: {usState: "---", caState: '---', country: "USA"}});
  const questionIcon =
    <QuestionIcon style={{marginLeft: "0.25rem", color: "black", width: "1.25rem", height: "auto"}}/>;

  return <div className={"buyer-onboarding"}>
    <h2 className={"mt-0"}>Organization</h2>
    {existingClient ?
        <p>Provide information about your organization below, as we currently have it listed in our system</p> :
        <p>Provide primary information about your organization below</p>}
    <form className={"plain-form"} onSubmit={handleSubmit(onNext, (e) => console.log(e))}>
      <SingleFormField
        fieldId={"orgName"}
        label={"Organization Legal Name"}
        register={register}
        errors={errors}
      />
      <div className="form-text-container" style={{justifyContent: "flex-start"}}>
        <label className={"no-asterisk-label"}>
          Business DBA Name (if applicable)
        </label>
        <a
          className={"display-flex ml-1"}
          {...registerTooltip("businessDBATooltip", "If your business operates under a name that is different from your legal name, please enter that name here")} >
          {questionIcon}
        </a>
        <InfoTooltip id={"businessDBATooltip"}/>
      </div>
      <input
        id={"businessDBAName"}
        {...register("businessDBAName")}
      />
      <SingleFormField
        fieldId={"buyerId"}
        label={"Buyer ID"}
        register={register}
        errors={errors}
        required={false}
      />
      <WideAddressForm register={register} errors={errors} watch={watch} control={control}/>
      {watch("country") === 'USA' ?
        <FormattedField
          control={control}
          fieldId={"usTaxIdNumber"}
          format={"##-#######"}
          errors={errors}
          label={"Tax ID Number"}
        /> :
        <SingleFormField
          fieldId={"caTaxIdNumber"}
          label={"Tax ID Number"}
          register={register}
          errors={errors}
        />
      }
      <FormLabel label={"W-9"} errors={errors} fieldId={"w9"}/>
      <input type={"file"} {...register("w9", {validate: validateAttachment})}
             accept={'application/pdf,.png,.jpeg,.jpg'}/>
      <div className={"two-cols"}>
        <button className={"button--base button__primary"} style={{width: "75%"}}>
          Next
        </button>
        <button className={"button--base button__secondary"} type={"button"} onClick={() => onBack()}
                style={{width: "75%"}}>
          Back
        </button>
      </div>
    </form>
  </div>
}

export default BuyerOnboardingOrgForm;
import './App.css';
import React, {useEffect, useRef, useState} from 'react';
import Home from './pages/Home';
import Login from './pages/Login';
import ErrorPage from './pages/ErrorPage';
import {BrowserRouter as Router, Navigate, Route, Routes,} from 'react-router-dom';
import Success from './pages/Success';
import UseCaseIndex from './pages/UseCaseIndex';
import PageNotFound from './pages/PageNotFound';
import SecureAchEnrollment from './pages/SecureAchEnrollment';
import axios from 'axios';
import RequireAuth from './components/RequireAuth';
import './assets/scss/main.scss';
import Header from './components/Header';
import {handleError} from './api/apiHelper';
import ProfileAndBankingEnrollment from "./pages/ProfileAndBankingEnrollment";
import AvsTest from "./pages/AvsTest";
import ResumeForm from "./pages/ResumeForm";
import AsyncPageStart, {AsyncPageType} from "./pages/AsyncPageStart";
import EmailSearch from "./pages/EmailSearch";
import InternalTools from "./pages/InternalTools";
import Onboarding from "./pages/Onboarding";
import BuyerOnboardingStart from "./pages/BuyerOnboardingStart";
import InternalChecks from "./pages/InternalChecks";
import ResumeBuyerOnboarding from "./pages/ResumeBuyerOnboarding";
import BuyerOnboardingSuccess from "./pages/BuyerOnboardingSuccess";
import ResumeExpressForm from "./pages/ResumeExpressForm";
import MagicLinkProtectedPage from "./components/MagicLinkProtectedPage";
import {CookieConsent} from "react-cookie-consent";
import BuyerOnboardingStartV2 from "./pages/BuyerOnboardingStartV2";
import SupplierValidation from "./pages/SupplierValidation";
import TestSupplier from "./pages/TestSupplier";
import SupplierValidationMVP from "./pages/SupplierValidationMVP";

function App() {
  let mountedRef = useRef(true);
  const [textContent, setTextContent] = useState('');

  useEffect(() => {
    // Load in the text content on page load.
    getTextContent();

    // Clean up
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // GETs the static text content from the server that will be used to
  // populate the app.
  async function getTextContent() {
    try {
      let response = await axios.get('/assets/text.json');

      // Only set states if the component is mounted, otherwise return null.
      if (!mountedRef.current) return null;

      setTextContent(response.data);
    } catch (error) {
      console.log('Error getting static text asset.');
      console.log(error);
      const errorPageText = handleError(error);
      <Navigate to="/error" state={errorPageText}/>;
    }
  }

  return (
    <>
      {textContent ? (
        <Router>
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <Login
                  text={textContent.login}
                  githubText={textContent.header}
                  btsText={textContent.behindTheScenes.login}
                />
              }
            />

            <Route element={<RequireAuth/>}>
              <Route
                path="/index"
                element={
                  <Home
                    text={textContent.home}
                    footerText={textContent.footer}
                  />
                }
              />
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="/resume/:id"
                element={
                  <ResumeForm/>
                }
              />
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="/resume-buyer-onboarding/:id"
                element={
                  <ResumeBuyerOnboarding/>
                }
              />
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="/resume-express/:id"
                element={
                  <ResumeExpressForm/>
                }
              />
            </Route>
            <Route
              path={"/email-search"}
              element={
                <EmailSearch/>
              }
            />
            <Route
              path={"/buyer-onboarding-success"}
              element={<BuyerOnboardingSuccess/>}
            />
            <Route
              path={"/existing-buyer-onboarding-success"}
              element={<BuyerOnboardingSuccess existingClient />}
            />
            <Route element={<RequireAuth/>}>
              <Route
                path="ach-enrollment"
                element={
                  <SecureAchEnrollment
                    text={textContent.passport}
                    formText={textContent.formLabels}
                  />
                }
              />
            </Route>
            <Route
              path={"buyer-onboarding-old"}
              element={<BuyerOnboardingStart/>}
            />
            <Route
              path={"buyer-onboarding"}
              element={<BuyerOnboardingStartV2 />}
            />
            <Route
              path={"buyer-onboarding-existing-client"}
              element={<BuyerOnboardingStart existingClient />}
            />
            <Route
              path={"supplier-validation"}
              element={<SupplierValidation />}
            />
            <Route
              path={"supplier-validation-mvp"}
              element={<SupplierValidationMVP />}
            />
            <Route
              path={"test-supplier"}
              element={<TestSupplier />}
            />
            <Route element={<RequireAuth/>}>
              <Route path="higher-education-ach-enrollment" element={<UseCaseIndex/>}>
                <Route
                  path=""
                  element={
                    <AsyncPageStart
                      text={textContent.trafficTicket}
                      formText={textContent.formLabels}
                      userFlowText={textContent.userFlowInfo.trafficTicket}
                      type={AsyncPageType.UNIVERSITY_ACH_ENROLLMENT}
                    />
                  }
                />
              </Route>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="banking-update"
                element={
                  <AsyncPageStart
                    text={textContent.trafficTicket}
                    formText={textContent.formLabels}
                    userFlowText={textContent.userFlowInfo.trafficTicket}
                    type={AsyncPageType.BANKING_UPDATE}
                  />
                }/>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="profile-update"
                element={
                  <AsyncPageStart
                    text={textContent.trafficTicket}
                    formText={textContent.formLabels}
                    userFlowText={textContent.userFlowInfo.trafficTicket}
                    type={AsyncPageType.PROFILE_UPDATE}
                  />
                }/>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="individual-profile-update"
                element={
                  <AsyncPageStart
                    text={textContent.trafficTicket}
                    formText={textContent.formLabels}
                    userFlowText={textContent.userFlowInfo.trafficTicket}
                    type={AsyncPageType.INDIVIDUAL_PROFILE_UPDATE}
                  />
                }/>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="profile-and-banking-update"
                element={
                  <AsyncPageStart
                    text={textContent.trafficTicket}
                    formText={textContent.formLabels}
                    userFlowText={textContent.userFlowInfo.trafficTicket}
                    type={AsyncPageType.PROFILE_AND_BANKING_UPDATE}
                  />
                }/>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="individual-profile-and-banking-update"
                element={
                  <AsyncPageStart
                    text={textContent.trafficTicket}
                    formText={textContent.formLabels}
                    userFlowText={textContent.userFlowInfo.trafficTicket}
                    type={AsyncPageType.INDIVIDUAL_PROFILE_AND_BANKING_UPDATE}
                  />
                }/>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="individual-banking-update"
                element={
                  <AsyncPageStart
                    text={textContent.trafficTicket}
                    formText={textContent.formLabels}
                    userFlowText={textContent.userFlowInfo.trafficTicket}
                    type={AsyncPageType.INDIVIDUAL_BANKING_UPDATE}
                  />
                }/>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route
                path="ach-debit-authorization"
                element={
                  <AsyncPageStart
                    text={textContent.trafficTicket}
                    formText={textContent.formLabels}
                    userFlowText={textContent.userFlowInfo.trafficTicket}
                    type={AsyncPageType.ACH_DEBIT_AUTHORIZATION}
                  />
                }/>
            </Route>
            <Route path="business-ach-enrollment" element={<MagicLinkProtectedPage/>}>
              <Route
                path=""
                element={
                  <AsyncPageStart
                    text={textContent.trafficTicket}
                    formText={textContent.formLabels}
                    userFlowText={textContent.userFlowInfo.trafficTicket}
                    type={AsyncPageType.BUSINESS_ACH_ENROLLMENT}
                  />
                }
              />
            </Route>
            <Route
              path={"internal-checks"}
              element={
                <InternalChecks/>
              }
            />
            <Route
              path={"internal-tools"}
              element={
                <InternalTools/>
              }
            />
            <Route
              path={"onboarding"}
              element={<Onboarding/>}
            />
            <Route path="individual-ach-enrollment"
                   element={
                     <AsyncPageStart
                       text={textContent.trafficTicket}
                       formText={textContent.formLabels}
                       userFlowText={textContent.userFlowInfo.trafficTicket}
                       type={AsyncPageType.INDIVIDUAL_ACH_ENROLLMENT}
                     />
                   }
            />
            <Route path="finexio-express"
                   element={<MagicLinkProtectedPage/>}
            >
              <Route path={""}
                     element={
                       <AsyncPageStart
                         text={textContent.trafficTicket}
                         formText={textContent.formLabels}
                         userFlowText={textContent.userFlowInfo.trafficTicket}
                         type={AsyncPageType.EXPRESS}
                       />
                     }
              />
            </Route>
            <Route element={<RequireAuth/>}>
              <Route path="avs-test" element={<UseCaseIndex/>}>
                <Route
                  path=""
                  element={
                    <AvsTest
                      text={textContent.trafficTicket}
                      formText={textContent.formLabels}
                      btsText={textContent.behindTheScenes}
                      userFlowText={textContent.userFlowInfo.trafficTicket}
                    />
                  }
                />
              </Route>
            </Route>
            <Route element={<RequireAuth/>}>
              <Route path="profile-and-banking-enrollment" element={<UseCaseIndex/>}>
                <Route
                  path=""
                  element={
                    <ProfileAndBankingEnrollment
                      text={textContent.trafficTicket}
                      formText={textContent.formLabels}
                      btsText={textContent.behindTheScenes}
                      userFlowText={textContent.userFlowInfo.trafficTicket}
                    />
                  }
                />
              </Route>
            </Route>

            <Route
              path="success"
              element={<Success text={textContent.success} backButton={false}/>}
            />
            <Route path="error" element={<ErrorPage/>}/>
            <Route
              path="*"
              element={<PageNotFound text={textContent.pageNotFound}/>}
            />
          </Routes>
          <footer className="copyright">{textContent.footer.copyright}</footer>
          <CookieConsent
            buttonStyle={{backgroundColor: '#043886', color: "white", fontWeight: 700, padding: "0.25rem 1rem"}}
            buttonText={"Accept"}
          >
            This website uses cookies to enhance the user experience.
          </CookieConsent>
        </Router>
      ) : (
        // Display nothing while static assets are being loaded in.
        <></>
      )}
    </>
  );
}

export default App;

import {useForm} from "react-hook-form";
import SingleFormField from "./SingleFormField";
import React from "react";
import FormattedField from "./FormattedField";
import * as EmailValidator from 'email-validator';

function BuyerOnboardingPOCForm({onNext, onBack, requesting}) {
    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
    } = useForm();

    return <div className={"buyer-onboarding"}>
        <h2 className={"mt-0"}>Main Point of Contact</h2>
        <p>Provide info for the main point of contact below.</p>
        <form onSubmit={handleSubmit(onNext)} className={"plain-form"}>
            <div className={"two-cols--dynamic"}>
                <div>
                    <SingleFormField
                        fieldId={"firstName"}
                        label={"First Name"}
                        register={register}
                        errors={errors}
                    />
                </div>
                <div>
                    <SingleFormField
                        fieldId={"lastName"}
                        label={"Last Name"}
                        register={register}
                        errors={errors}
                    />
                </div>
            </div>
            <SingleFormField
                fieldId={"email"}
                label={"Email"}
                register={register}
                errors={errors}
                inputType={"email"}
                validate={(v) => EmailValidator.validate(v) || 'Invalid email address' }
            />
            <FormattedField
              control={control}
              fieldId={"phoneNumber"}
              format={"+1 (###)-###-####"}
              errors={errors}
              label={"Phone Number"}
            />
            <div className={"button-container"}>
                <button className={"black-button"} disabled={requesting}>
                    Next
                </button>
                <button className={"grey-button"} type={"button"} onClick={onBack} disabled={requesting}>
                    Back
                </button>
            </div>
        </form>
    </div> ;
}

export default BuyerOnboardingPOCForm;
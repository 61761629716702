import {useForm} from "react-hook-form";
import PersonalInfoFields from "./PersonalInfoFields";
import YesNoRequiredSelect from "../YesNoRequiredSelect";

function AuthorizedSigner({onNext, onBack, requesting, existingClient}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    control,
  } = useForm();

  return <div className={"buyer-onboarding"}>
    <h2 className={"mt-0"}>Authorized Signer</h2>
    <p>Provide your contact details, or the contact details for the individual that will be signing on behalf of the organization authorizing Finexio to
      {existingClient ? " update the organization's banking details and initiate debits and credits as needed." : " initiate debits and credits to the organization's bank account."}</p>
    <form className={"plain-form"} onSubmit={handleSubmit(onNext)}>
      <PersonalInfoFields errors={errors} register={register} control={control} />
      {!existingClient && <>
        <YesNoRequiredSelect label={"Receive funding notifications?"} fieldId={"fundingNotifs"} register={register} errors={errors} />
        <YesNoRequiredSelect label={"Receive refund notifications?"} fieldId={"refundNotifs"} register={register} errors={errors} />
      </>}
      <div className={"two-cols"}>
        <button className={"button--base button__primary"} disabled={requesting}>Next</button>
        <button className={"button--base button__primary"} type={"button"} disabled={requesting} onClick={onBack}>Back</button>
      </div>
    </form>
  </div>;
}

export default AuthorizedSigner;
import {useState} from "react";
import TinCheck from "../components/TinCheck";
import InternalBankingCheck from "../components/InternalBankingCheck";
import SanctionsCheck from "../components/SanctionsCheck";
import EmailValidation from "../components/EmailValidation";
import EmailInNetwork from "../components/EmailInNetwork";
import SendMagicLink from "../components/SendMagicLink";
import USMailingAddressCheck from "../components/USMailingAddressCheck";
import SendBuyerOnboardingInvitation from "../components/SendBuyerOnboardingInvitation";

function InternalTools() {
  const [checkType, setCheckType] = useState("individual-avs");

  let content;
  switch (checkType) {
    case 'business-tin':
      content = <TinCheck />;
      break;
    case 'business-avs':
      content = <InternalBankingCheck isIndividual={false} />;
      break;
    case 'individual-avs':
      content = <InternalBankingCheck isIndividual={true} />;
      break;
    case 'sanctions-check':
      content = <SanctionsCheck />;
      break;
    case 'email-validation':
      content = <EmailValidation />;
      break;
    case 'email-in-network':
      content = <EmailInNetwork />;
      break;
    case 'send-magic-link':
      content = <SendMagicLink />;
      break;
    case 'us-address-check':
      content = <USMailingAddressCheck />;
      break;
    case "buyer-onboarding-invitation":
      content = <SendBuyerOnboardingInvitation />;
      break;
    default:
      break;
  }

  return <section className="content-section">
    <div className="container">
      <div className="header-container">
        <h1>Internal Tools</h1>
      </div>
      <div className={"form-holder"} style={{height: "unset"}}>
          <div className={"form-text-container"}>
            <label htmlFor={"test-type"}>Tool</label>
          </div>
        <select id={"test-type"} value={checkType} onChange={(e) => setCheckType(e.target.value)}>
          <option value={"individual-avs"}>Individual AVS Check</option>
          <option value={"business-avs"}>Business AVS Check</option>
          <option value={"business-tin"}>Business TIN Check</option>
          <option value={"sanctions-check"}>Sanctions Check</option>
          <option value={"email-validation"}>Email Validation</option>
          <option value={"email-in-network"}>Email In Network Check</option>
          <option value={"send-magic-link"}>Send Magic Link</option>
          <option value={"us-address-check"}>US Mailing Address Check</option>
          <option value={"buyer-onboarding-invitation"}>Send Buyer Onboarding Invitation</option>
        </select>
        {content}
      </div>
    </div>
  </section>;
}

export default InternalTools;
import {useForm} from "react-hook-form";
import React, {useState} from "react";
import {ErrorMessage} from "@hookform/error-message";
import {sendRequest} from "../api/apiHelper";

function SanctionsCheck() {
  const {register, handleSubmit, formState: {errors}, reset} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState();

  async function onSubmit(event) {
    try {
      setSubmitted(true);
      const response = await sendRequest('/check/sanctions', {entity: event.entity});
      setResult(response.data);
      setSubmitted(false);
    } catch (e) {
      console.error('failed e');
    }
  }
  const ErrorMessageContainer = ({ children }) => (
    <div className="error" style={{flexBasis: "auto"}}>{children}</div>
  );
  return <form onSubmit={handleSubmit(onSubmit)}>
    <div className={"form-text-container"} style={{justifyContent: "space-between", display: "flex"}}>
      <label style={{flexBasis: "auto"}}>Entity Name:</label>
      <ErrorMessage
        name={"entity"}
        errors={errors}
        as={<ErrorMessageContainer />}
      />
    </div>
    <input {...register("entity", {required: {value: true, message: "Required"}})} />
    <div className={"button-container"}>
      <button className={"black-button"} type={"submit"} disabled={submitted}>Submit</button>
      <button className={"grey-button"} disabled={submitted} onClick={() => {reset(); setResult();}}>Reset</button>
    </div>
      {result && <div style={{marginTop: "1.5rem"}}>
          <label htmlFor={"result"} className={"no-asterisk-label"} style={{marginTop: "1rem"}}>Result</label>
          <textarea id="result" rows={20} disabled style={{resize: "none"}} value={JSON.stringify(result, null, 2)} />
        </div>}
  </form>;
}

export default SanctionsCheck;
import {useForm} from "react-hook-form";
import {useState} from "react";
import {sendRequest} from "../api/apiHelper";
import {ErrorMessage} from "@hookform/error-message";

function EmailInNetwork() {
  const {register, handleSubmit, formState: {errors}, reset} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState();
  const ErrorMessageContainer = ({ children }) => (
    <div className="error" style={{flexBasis: "auto"}}>{children}</div>
  );
  async function onSubmit(event) {
    try {
      setSubmitted(true);
      const response = await sendRequest('/check/emailInNetwork', {email: event.email});
      setResult(response.data);
      setSubmitted(false);
    } catch (e) {
      console.error('failed e');
    }
  }
  return <form onSubmit={handleSubmit(onSubmit)}>
    <div className={"form-text-container"} style={{justifyContent: "space-between", display: "flex"}}>
      <label style={{flexBasis: "auto"}}>Email:</label>
      <ErrorMessage
        name={"email"}
        errors={errors}
        as={<ErrorMessageContainer />}
      />
    </div>
    <input {...register("email", {required: {value: true, message: "Required"}})} type={"email"}/>
    <div className={"button-container"}>
      <button className={"black-button"} type={"submit"} disabled={submitted}>Submit</button>
      <button className={"grey-button"} disabled={submitted} onClick={() => {reset(); setResult();}}>Reset</button>
    </div>
    {result && <>
        <div className={"form-text-container"} style={{marginTop: "1.5rem"}}>
          <label htmlFor={"result"}>Result:</label>
        </div>
        <input id={"result"} disabled value={result.result}/>
      </>
    }
  </form>;
}

export default EmailInNetwork;
import FormLabel from "./FormLabel";

export default function YesNoRequiredSelect({register, fieldId, label, errors, disabled=false}) {
  return <>
    <FormLabel label={label} errors={errors}
               fieldId={fieldId}/>
    <select disabled={disabled} id={fieldId} {...register(fieldId, { validate: (v) => (v && v !== '---') || 'Required'})} >
      <option value={"---"}>---</option>
      <option value={"yes"}>Yes</option>
      <option value={"no"}>No</option>
    </select>
  </>;
}
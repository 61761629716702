import {useForm} from "react-hook-form";
import SingleFormField from "../components/SingleFormField";
import {FlatfileProvider, useFlatfile, useListener, Workbook} from "@flatfile/react";
import api from "@flatfile/api";
import {useCallback, useState} from "react";
import {recordHook} from "@flatfile/plugin-record-hook";
import axios from "axios";
import {workbook} from "../configs/flatfile";
import Success from "./Success";

const stateCodeRegexp = /^[A-Z]{2}$/;
const zipCodeRegexp = /^[0-9]{5}(-[0-9]{4})?$/;
const countryRegexp= /^[A-Z]{3}$/;
const emailRegexp = /^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+(\.[a-zA-Z]{2,4}){1,2}$/;
const accountNumberRegexp = /^[0-9]{5,17}$/;
const routingNumberRegexp = /^[0-9]{9}$/;
const phoneRegexp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const tinRegexp = /^[0-9]{2}-?[0-9]{7}$/;

function getColumnHeaders(field) {
  return {key: field.key, header: field.label};
}

function convertRecord(columns, record) {
  let ret = {};
  columns.forEach((c) => {
    ret[c.key] = record.values[c.key].value || "";
  });
  return ret;
}

function checkStringLength(str, maxLength, isOptional = false) {
  if (isOptional && !str) {
    return true;
  }
  return str && str.length <= maxLength;
}

function validateRecordLength(record, recordName, maxLength, optional = false) {
  record.validate(
    recordName,
    (value) => checkStringLength(value, maxLength, optional),
    `The maximum length is ${maxLength} characters`,
  );
}

/**
 * @param event {FlatfileEvent}
 * @param formData
 * @param setDataWasSent
 * @return {Promise<void>}
 */
async function jobReadyHandler(event, formData, setDataWasSent){
  const { context } = event;
  const { jobId, workbookId } = context;
  await api.jobs.ack(jobId, {
    info: "Starting submit job...",
    progress: 10,
  });
  const { data: workbookSheets } = await api.sheets.list({ workbookId });
  console.log('workbookSheets data', workbookSheets);

  const sheetId = workbookSheets[0].id;
  const fields = workbookSheets[0].config.fields;
  const columnHeaders = fields.map(getColumnHeaders);
  const recordsResponse = await api.records.get(sheetId);
  const records = recordsResponse.data.records;

  console.log('records', records);
  const convertedRecords = records.map((r) => convertRecord(columnHeaders, r));
  console.log('converted records', convertedRecords);
  const zipCodeRegexp = new RegExp("^[0-9]{5}(-[0-9]{4})?$");
  const allowedCharacters = /^[a-zA-Z0-9 .\-_]+$/;
  console.log('column headers', columnHeaders);

  let dataErrors = [];
  for (const [idx, record] of convertedRecords.entries()) {
    const checkField = (fieldValue, columnName, validator, message, isOptional = false) => {
      if (!validator(fieldValue)) {
        dataErrors.push({
          row: {
            lineNumber: {value: idx + 1},
            column: {value: columnName},
            fieldValue: {value: fieldValue},
            errorDescription: {value: message},
          },
          isFatal: !isOptional,
        });
        return false;
      } else {
        return true;
      }
    };
    const checkFieldIsSet = (fieldValue, columnName, isOptional = false) => checkField(fieldValue, columnName, (f) => !!f, (isOptional ? "Recommended" : "Required") + " field is not set", isOptional);
    const checkFieldRegexp = (fieldValue, columnName, regexp, message, isOptional) => checkField(fieldValue, columnName, (f) => regexp.test(f), message, isOptional);
    const checkFieldLength = (fieldValue, columnName, maxLength, isOptional) => checkField(fieldValue, columnName, (f) => f.length <= maxLength, `Field exceeds the maximum length of ${maxLength} characters`, isOptional);

    for (let j = 0; j < idx; j++) {
      if (convertedRecords[j].supplierId === record.supplierId && convertedRecords[j].supplierName !== record.supplierName) {
        dataErrors.push({
          row: {
            lineNumber: {value: idx + 1},
            column: {value: "Supplier Id"},
            fieldValue: {value: record.supplierId},
            errorDescription: {value: `A supplier with the same id but different name was previously entered at line ${j + 1}`},
          },
          isFatal: true,
        });
      }
      break;
    }
    if (checkFieldIsSet(record.buyerName, "Buyer Name")) {
      checkFieldLength(record.buyerName, "Buyer Name", 40);
    }
    if (checkFieldIsSet(record.buyerId, "Buyer Id")) {
      checkFieldLength(record.buyerId, "Buyer Id", 100);
      checkFieldRegexp(record.buyerId, "Buyer Id", allowedCharacters, "Invalid character. Allowed characters: letters, digits, space, -, _, .");
    }
    checkFieldIsSet(record.buyerAccountNumber, "Buyer Account Number", true) &&
      checkFieldLength(record.buyerAccountNumber, "Buyer Account Number", 100, true);
    if (checkFieldIsSet(record.supplierName, "Supplier Name")) {
      checkFieldLength(record.supplierName, "Supplier Name", 40);
    }
    if (checkFieldIsSet(record.supplierId, "Supplier Id")) {
      checkFieldLength(record.supplierId, "Supplier Id", 100);
    }
    if (checkFieldIsSet(record.supplierAddress1, "Supplier Corporate Address Line 1")) {
      checkFieldLength(record.supplierAddress1, "Supplier Corporate Address Line 1", 40);
    }
    if (record.supplierAddress2) {
      checkFieldLength(record.supplierAddress2, "Supplier Corporate Address Line 2", 40);
    }
    if (checkFieldIsSet(record.supplierRemitCity, "Supplier Corporate City")) {
      checkFieldLength(record.supplierRemitCity, "Supplier Corporate City", 40);
    }
    if (checkFieldIsSet(record.supplierRemitState, "Supplier Remittance State")) {
      checkFieldRegexp(record.supplierRemitState, "Supplier Remittance State", stateCodeRegexp, "2 letter state code needed");
    }
    if (checkFieldIsSet(record.supplierRemitZip, "Supplier Remittance Zip")) {
      checkFieldRegexp(record.supplierRemitZip, "Supplier Remittance Zip", zipCodeRegexp, "expected format: ##### or #####-####");
    }
    if (checkFieldIsSet(record.supplierRemitCountry, "Supplier Remittance Country")) {
      checkFieldRegexp(record.supplierRemitCountry, "Supplier Remittance Country", countryRegexp, "3 letter country code needed");
    }
    checkFieldIsSet(record.paymentMethod, "Payment Method");
    if (record.paymentMethod === "ACH" || record.supplierRemitCountry === "CAN") {
      checkFieldIsSet(record.supplierRemitEmail, "Supplier Remittance Email Address") &&
      checkFieldLength(record.supplierRemitEmail, "Supplier Remittance Email Address", 50) &&
      checkFieldRegexp(record.supplierRemitEmail, "Supplier Remittance Email Address", emailRegexp, "A valid email address is needed");
    }
    if (record.paymentMethod === "ACH") {
      checkFieldIsSet(record.disbursementAccountNumber, "Disbursement Account Number") &&
        checkFieldRegexp(record.disbursementAccountNumber, "Disbursement Account Number", accountNumberRegexp, "A 5-17 digit number is needed");
      checkFieldIsSet(record.disbursementRoutingNumber, "Disbursement Routing Number") &&
        checkFieldRegexp(record.disbursementRoutingNumber, "Disbursement Routing Number", routingNumberRegexp, "A 9 digit number is needed");
    }
    checkFieldIsSet(record.twelveMonthDollarSpend, "12 Month Dollar Spend", true);
    checkFieldIsSet(record.twelveMonthPaymentCount, "12 Month Payment Count", true);
    checkFieldIsSet(record.clientReportedPaymentTerms, "Client Reported Payment Terms", true) &&
      checkFieldLength(record.clientReportedPaymentTerms, "Client Reported Payment Terms", 50, true)
    checkFieldIsSet(record.supplierContactName, "Supplier Contact Name", true) &&
      checkFieldLength(record.supplierContactName, "Supplier Contact Name", 40, true)
    checkFieldIsSet(record.supplierPhone, "Supplier Phone", true) &&
      checkFieldRegexp(record.supplierPhone, "Supplier Phone", phoneRegexp, "A valid phone number is needed", true);
    checkFieldIsSet(record.supplierEmail, "Supplier Email Address", true) &&
      checkFieldLength(record.supplierEmail, "Supplier Email Address", 50, true) &&
      checkFieldRegexp(record.supplierEmail, "Supplier Email Address", emailRegexp, "A valid email address is needed", true);
    if (record.supplierTaxId) {
      checkFieldRegexp(record.supplierTaxId, "Supplier Tax ID", tinRegexp, "A valid email address is needed", true);
    }
    checkFieldIsSet(record.paymentCategory, "Payment Category");
  }
  // console.log('dataErrors ', dataErrors);
  const oldErrorRecords = await api.records.get(workbookSheets[1].id);
  const errorRecordIds = oldErrorRecords.data.records.map(r => r.id);
  // console.log("old record ids", errorRecordIds);
  if (errorRecordIds.length > 0) {
    const chunkSize = 90;
    for (let start = 0; start < errorRecordIds.length; start += chunkSize) {
      const currentSlice = errorRecordIds.slice(start, start + chunkSize);
      // console.log('currentSlice ', currentSlice);
      await api.records.delete(workbookSheets[1].id, {
        ids: currentSlice
      });
    }
    await api.jobs.update(jobId, { progress: 20});
  }
  await api.records.insert(workbookSheets[1].id, dataErrors.map(r => r.row));

  if (dataErrors.find(r => r.isFatal)) {
    await api.jobs.fail(jobId, {
      outcome: {
        acknowledge: true,
        message: "There are errors on required fields that need to be corrected before submission. You can find a downloadable list of errors in the \"Data Errors\" sheet",
        heading: "Failed",
      },
    });
  } else {
    try {
      await axios.post('/internalTools/sendSupplierValidationEmail', {
        formData,
        columnHeaders,
        convertedRecords,
        dataErrors
      });
      if (dataErrors.length === 0) {
        setDataWasSent(true);
        await api.jobs.complete(jobId, {
          outcome: {
            message: `Submit job was completed succesfully.`,
          },
        });
      } else {
        await api.jobs.complete(jobId, {
          outcome: {
            acknowledge: true,
            message: `The data was submitted successfully. However, there were some errors that we strongly recommend correcting. You can see them in the downloadable errors sheet. Please address them and resubmit. If you don't have access to this information, please discuss with your Implementation Project Manager.`,
            heading: "Submitted with errors",
          },
        });
      }
    } catch (e) {
      await api.jobs.fail(jobId, {
        outcome: {
          acknowledge: true,
          message: "Internal error. Please contact support.",
          heading: "Failed",
        },
      });
    }
  }
}

function WorkbookConfig() {
  const {
    register,
    handleSubmit,
    formState: {errors},
    reset,
  } = useForm();
  const [formData, setFormData] = useState({});
  const [dataWasSent, setDataWasSent] = useState(false);
  const { openPortal, closePortal } = useFlatfile();

  useListener((listener) => {
    listener.on(
      "job:ready",
      { job: "workbook:submitActionFg" },
      async (event) => {
      await jobReadyHandler(event, formData, setDataWasSent);
    });
    listener.use(
      recordHook("sheet", (record) => {
        const validateRequired = (column, columnName) => {
          record.validate(
            column,
            (value) => !!value,
            `${columnName} is required`
          );
        };
        validateRequired("buyerName", "Buyer Name");
        const allowedCharacters = /^[a-zA-Z0-9 .\-_]+$/;
        validateRecordLength(record, "buyerName", 40);
        validateRecordLength(record, "buyerId", 100);
        record.validate(
          "buyerId",
          (value) => typeof(value) === "string" && allowedCharacters.test(value),
          "Allowed characters: letters, digits, space, -, _, .",
        );
        validateRecordLength(record, "buyerAccountNumber", 100, true);
        validateRecordLength(record, "supplierName", 100);
        validateRecordLength(record, "supplierId", 100);
        record.validate(
          "supplierId",
          (value) => typeof(value) === "string" && allowedCharacters.test(value),
          "Allowed characters: letters, digits, space, -, _, .",
        );
        validateRecordLength(record, "supplierAddress1", 40);
        validateRecordLength(record, "supplierAddress2", 40, true);
        validateRecordLength(record, "supplierRemitCity", 40, true);

        record.validate(
          "supplierRemitState",
          (value) => typeof(value) === "string" && stateCodeRegexp.test(value),
          "2 letter state code needed",
        );
        record.validate(
          "supplierRemitZip",
          (value) => typeof(value) === "string" && zipCodeRegexp.test(value),
          "expected format: ##### or #####-####",
        );
        record.validate(
          "supplierRemitCountry",
          (value) => typeof(value) === "string" && countryRegexp.test(value),
          "3 letter country code needed",
        );
        if (record.get("paymentMethod") === "ACH" || record.get("supplierRemitCountry") === "CAN") {
          record.validate(
            "supplierRemitEmail",
            (value) => value && (typeof (value) === "string" && emailRegexp.test(value)),
            "A valid email address is needed",
          );
        }
        if (record.get("paymentMethod") === "ACH") {
          record.validate(
            "disbursementAccountNumber",
            (value) => !!value,
            "Account Number is needed for ACH payments"
          );
          record.validate(
            "disbursementRoutingNumber",
            (value) => !!value,
            "Routing Number is needed for ACH payments"
          );
          record.validate(
            "disbursementAccountNumber",
            (value) => (typeof(value) === "string" && accountNumberRegexp.test(value)),
            "A 5-17 digit number is needed",
          );
          record.validate(
            "disbursementRoutingNumber",
            (value) => (typeof(value) === "string" && routingNumberRegexp.test(value)),
            "A 9 digit number is needed",
          );
        }

        validateRecordLength(record, "paymentNotes", 100, true);
        validateRecordLength(record, "clientReportedPaymentTerms", 50, true);
        validateRecordLength(record, "supplierContactName", 40, true);

        record.validate(
          "supplierPhone",
          (value) => !value || (typeof(value) === "string" && phoneRegexp.test(value)),
          "A valid phone number is needed",
        );
        record.validate(
          "supplierEmail",
          (value) => !value || (typeof(value) === "string" && emailRegexp.test(value)),
          "email address expected",
        );

        record.validate(
          "supplierTaxId",
          (value) => !value || (typeof(value) === "string" && tinRegexp.test(value)),
          "Business TIN expected (123456789 or 12-3456789)",
        );
      })
    )
  });

  const submitHandler = useCallback((data) => {
    setFormData(data);
    openPortal();
  }, [setFormData, openPortal]);

  // console.log('dataWasSent', dataWasSent);
  if (dataWasSent) {
    closePortal();
    return <Success
      title={"Success"}
      removeCheckmark={false}
      removeTitle={true}
      backButton={false}
      message={"The supplier data was sent successfully"}
    />;
  }

  return <>
    <section className="content-section">
      <div className="container">
        <div className="header-container">
          <h1>Supplier Validation</h1>
        </div>
        <div className={"form-holder"} style={{height: "unset"}}>
          <form onSubmit={handleSubmit(submitHandler)}>
            <SingleFormField
              fieldId={"firstName"}
              label={"First Name"}
              register={register}
              errors={errors}
            />
            <SingleFormField
              fieldId={"lastName"}
              label={"Last Name"}
              register={register}
              errors={errors}
            />
            <SingleFormField
              fieldId={"orgName"}
              label={"Organization Name"}
              register={register}
              errors={errors}
            />
            <div className={"two-cols"}>
              <button className={"button--base button__primary"} disabled={false} style={{width: "100%"}}>
                Open upload portal
              </button>
              <button className={"button--base button__secondary"} disabled={false} onClick={() => reset()}
                      style={{width: "100%"}}>
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
      <Workbook
        config={workbook}
      />
    </section>
  </>
}

export default function SupplierValidation() {
  return <FlatfileProvider publishableKey={process.env.REACT_APP_FLATFILE_PUBLISHABLE_KEY}>
    <WorkbookConfig />
  </FlatfileProvider>;
}
import React, {useState} from "react";
import BuyerOnboardingOrgForm from "../components/BuyerOnboardingOrgForm";
import BuyerOnboardingPOCForm from "../components/BuyerOnboardingPOCForm";
import {AsyncPageType} from "./AsyncPageStart";
import {handleError, sendRequest} from "../api/apiHelper";
import {useNavigate} from "react-router-dom";
import Success from "./Success";
import {formatWideAddressFormData} from "../utils/address";
import CirclesProgress from "../components/CirclesProgress";
import DueDiligencePopup from "../components/DueDiligencePopup";
import {getAttachmentName, readFileAsBase64} from "../utils/fileUpload";
import AuthorizedSigner from "../components/BuyerOnboarding/AuthorizedSigner";

function BuyerOnboardingStart({existingClient = false}) {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [firstPage, setFirstPage] = useState();
  const [requesting, setRequesting] = useState();
  const [success, setSuccess] = useState();
  const [showDueDiligence, setShowDueDiligence] = useState(false);

  let content;
  const submitForm = async (secondPage) => {
    try {
      const body = {
        firstName: secondPage.firstName,
        lastName: secondPage.lastName,
        signerEmail: secondPage.email,
        country: firstPage.country,
        companyName: firstPage.orgName,
        tin: firstPage.country === 'USA' ? firstPage.usTaxIdNumber : firstPage.caTaxIdNumber,
        address: formatWideAddressFormData(firstPage),
        pageType: existingClient ? AsyncPageType.BUYER_ONBOARDING_EXISTING_CLIENT : AsyncPageType.BUYER_ONBOARDING,
        phoneNumber: secondPage.phoneNumber,
        businessDBAName: firstPage.businessDBAName,
        buyerId: firstPage.buyerId,
        attachments: [
          {
            dataB64: await readFileAsBase64(firstPage.w9[0]),
            fileName: getAttachmentName(firstPage.w9[0].type, ' - W9')
          }
        ],
      };
      setRequesting(true);
      await sendRequest('/asyncForm/start', body);
      setSuccess(true);
    } catch (error) {
      console.log(error);
      const errorPageText = handleError(error);
      navigate('/error', { state: errorPageText });
    }
  };
  const existingClientInstructions = <>
    <h2 className={"mt-0"}>Instructions</h2>
    <p>In order to update your banking details as a Finexio customer, please follow the below instructions:</p>
    <ol>
      <li><strong>Prior to completing this form -</strong> please verify that Finexio is whitelisted on your new account to ensure the success of the penny test that will be performed at the end of this process. Details on whitelisting can be found <a rel="noreferrer" target="_blank" href={"https://info.finexio.com/hubfs/Finexio%20Whitelisting%20Guide.docx.pdf"}>here</a>. Please be advised you may be asked to provide proof of whitelisting if the penny test fails.</li>
      <li>If you are looking to update the banking information for multiple organizations/buyers/locations, you'll need to submit this form once for each. At the end of the process, there will be a button to return to the beginning to submit the next form</li>
      <li>Please have the following information on hand: each organization's name, address, point of contact information including access to email, and funding/refund bank account information (account and routing number). You'll also need a copy of each organization's W-9 for identification purposes, and a voided check or bank statement if we can not automatically verify your banking details.</li>
      <li>If you have any questions, please reach out to our support team directly and we will be happy to assist you.</li>
    </ol>
    <p>If you need to submit payments while this change is being processed, please discuss with our Client Operations team prior to submission to prevent any payment delays and ensure the funds are being drawn from the correct account.</p>
  </>;
  const newClientInstructions = <>
    <h2 className={"mt-0"}>Instructions</h2>
    <p>In order to update your banking details as a Finexio customer, please follow the below instructions:</p>
    <ol>
      <li>If you are onboarding multiple organizations/buyers/locations with Finexio, you'll need to submit this form once for each. At the end of the process, there will be a button to return to the beginning to submit the next form.</li>
      <li>Please have the following information on hand: each organization's name, address, point of contact information including access to email, and funding/refund bank account information (account and routing number). You'll also need a copy of each organization's W-9 for identification purposes, and a voided check or bank statement if we can not automatically verify your banking details.</li>
      <li>If you have any questions, please reach out to your onboarding project manager and we'll be happy to assist you.</li>
    </ol>
  </>;

  switch (currentStep) {
    case 0:
      content = <div className={"buyer-onboarding"}>
        {existingClient ? existingClientInstructions : newClientInstructions}
        <h2 className={"mb-2"}>Finexio Client Due Diligence</h2>
        <button onClick={() => setShowDueDiligence(true)} className={"btn--link"}>See Details</button>
        <div style={{textAlign: "center", marginTop: "3rem"}}>
          {!existingClient && <p>Welcome Aboard!</p>}
          <button className={"button--base button__primary"} onClick={() => setCurrentStep(1)} style={{width: "45%"}}>
            {existingClient ? 'Begin Update' : 'Start Registration'}
          </button>
        </div>
      </div>;
      break;
    case 1:
      content = <BuyerOnboardingOrgForm
        onNext={(data) => {
          setFirstPage(data);
          setCurrentStep(2);
          }}
        onBack={() => setCurrentStep(0)}
        existingClient={existingClient}
      />;
      break;
    case 2:
      content = <AuthorizedSigner
        onNext={submitForm}
        onBack={() => setCurrentStep(1)}
        requesting={requesting}
        existingClient={existingClient}
      />;
      break;
    default:
      content = "Not found";
      break;
  }

  if (success) {
    return <Success
      title={"Success"}
      removeCheckmark={true}
      backButton={false}
      removeTitle={true}
      message={<>A verification link has been sent to the email address of the authorized signer to proceed. If you cannot locate the email or need more assistance, please reach out to our support team by using <a
        href={"https://support.finexio.com/hc/en-us/requests/new"}>this link</a></>}
    />;
  }

  return <section className={"content-section"}>
    <div className={"container--block"}>
      <h1 className={"fs-7 text-center mb-6"} style={{padding: "0 1rem"}}>{existingClient ? 'Finexio Existing Client Due Diligence' : 'Finexio New Client Due Diligence'}</h1>
      {currentStep > 0 && <CirclesProgress currentStep={currentStep} stepLabels={["Organization", "Authorized Signer", "Banking"]} />}
      <div className={"shadowbox"}>
        {content}
      </div>
    </div>
    {showDueDiligence && <DueDiligencePopup  onClose={() => setShowDueDiligence(false)}/>}
  </section>;
}

export default BuyerOnboardingStart;
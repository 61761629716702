/**
 *
 * @param {string} tooltipId
 * @param {string} content
 * @param {string} place
 * @return {Object} fields to be added the component
 */
export function registerTooltip(tooltipId, content, place = "top") {
  return {
    'data-tooltip-id': tooltipId,
    'data-tooltip-content': content,
    'data-tooltip-place': place,
    'data-tooltip-variant': 'info',
  }
}